import React from "react";
import "./PopUp.css";

import { useState } from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
const PopUp = ({ children, style, removeItem, getRoles, heading, sub }) => {
  const [mode, setMode] = useState(true);
  const { pop, dispatch, transactionPop, filterPop, profilePop } =
    useAuthContext();
  const [classMode, setClassMode] = useState("pop-white");
  return (
    <div className="popup-container">
      <div
        onClick={() => {
          setClassMode("leave");
          setTimeout(() => {
            dispatch({ type: "POP_UP", payload: false });
            dispatch({ type: "PROFILE_POP_UP", payload: false });
            dispatch({ type: "TRANSACTION_POP_UP", payload: false });
            dispatch({ type: "FILTER_POP_UP", payload: false });
            typeof removeItem === "function" ? removeItem() : console.log("");
            typeof getRoles === "function" ? getRoles() : console.log("");
          }, 100);
        }}
        style={{
          height: "100vh",
          width: "100%",
          position: "fixed",
          display: mode ? "block" : "none",
          top: "0",
          left: "0",
          zIndex: "20000",
          backgroundColor: "rgba(0, 0, 0, .3)",
        }}
      ></div>
      {/* <Fade up duration={250} > */}
      <div
        className={
          pop || transactionPop || filterPop || profilePop ? `${classMode}` : ""
        }
        style={style}
      >
        <div
          className="border-top"
          style={{ zIndex: "2000" }}
          onTouchMove={() => {
            setClassMode("leave");
            setTimeout(() => {
              dispatch({ type: "POP_UP", payload: false });
              dispatch({ type: "PROFILE_POP_UP", payload: false });
              dispatch({ type: "TRANSACTION_POP_UP", payload: false });
              dispatch({ type: "FILTER_POP_UP", payload: false });
              typeof removeItem === "function" ? removeItem() : console.log("");
              typeof getRoles === "function" ? getRoles() : console.log("");
            }, 100);
          }}
        >
          <img
            src="/assets/rec.svg"
            alt=""
            onTouchMove={() => {
              setClassMode("leave");
              setTimeout(() => {
                dispatch({ type: "POP_UP", payload: false });
                dispatch({ type: "PROFILE_POP_UP", payload: false });
                dispatch({ type: "TRANSACTION_POP_UP", payload: false });
                dispatch({ type: "FILTER_POP_UP", payload: false });
                typeof removeItem === "function"
                  ? removeItem()
                  : console.log("");
                typeof getRoles === "function" ? getRoles() : console.log("");
              }, 100);
            }}
          />
        </div>
        <div style={{ marginTop: sub === "" ? "100px" : "130px" }}>
          <div
            className="pop-body"
            style={{
              position: "fixed",
              background: "white",
              width: "100%",
              transform: sub === "" ? "translateY(-65px)" : "translateY(-95px)",
              padding: "10px 0px 20px 0px",
              zIndex: "2000",
            }}
          >
            <h1>{heading}</h1>
            {sub !== "" && <p style={ {width: sub?.length > 45 ? '90%' : '100%'} } >{sub}</p>}
          </div>
          {children}
        </div>
      </div>
      {/* </Fade> */}
    </div>
  );
};

export default PopUp;

import { useState, useEffect, useRef } from "react";
import "./outlets.css";
import { Fade } from "react-reveal";

const Outlets = () => {
  const bank = useRef();
  const phoneNum = useRef();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkOutlets, setCheckOutlets] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);

  const filled = () => {
    if (phoneNum.current.value.length === 13) {
      setCheckOutlets(true);
    } else {
      setCheckOutlets(false);
    }
  };

  useEffect(() => {
    filled();
  });

  const showChangesLoader = () => {
    setSaveChanges(true);
    setTimeout(() => {
      setSaveChanges(false);
    }, 3000);
  };

  return (
    <Fade duration={250}>
      <div className="setting-payouts-container">
        <div className="settings-outlets-warning">
          <p>
            You can only edit your business phone number on this page. Send an
            email to support@qevolt.com to change your outlet information.
          </p>
        </div>
        <form action="" className="setting-payouts-form">
          <label>
            <div className="label">Business Name</div>
            <input
              className="settingsInputBox"
              type="text"
              name="fullName"
              value="RunTransfer"
              style={{ color: "#c4c4c4" }}
              // onChange={(e)=>setFullNamee(e.target.value)}
              // onKeyDown = {()=> filled()}
            />
          </label>
          <label>
            <div className="label">Business phone number</div>
            <input
              className="settingsInputBox"
              type="number"
              name="AccountNumber"
              ref={phoneNum}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
              }}
              maxlength={13}
              // onKeyDown = {()=> filled()}
            />
          </label>
          <div className="label">CAC Certificate</div>
          <div className="cac-container">
            {/* <img
              className="cac-dashed-image"
              src="/assets/dashed-border.svg"
              alt=""
            /> */}
            <div className="cac-doc">
              <p>Document</p>
            </div>
          </div>
        </form>
        {!saveChanges && (
          <button
            className="payouts-save-button"
            style={{
              backgroundColor: checkOutlets ? "#8F00FF " : "#f8f8f8",
              color: checkOutlets ? "white" : "#757575",
              pointerEvents: checkOutlets ? "all" : "none",
              cursor: checkOutlets ? "pointer" : "not-allowed",
            }}
            onClick={() => showChangesLoader()}
          >
            Save Changes
          </button>
        )}
        {saveChanges && (
          <button className="profile-loading-save-button">
            <img src="/assets/settingsLoader.svg" alt="" />
          </button>
        )}
      </div>
    </Fade>
  );
};

export default Outlets;

import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Sidebar = () => {
  const [logoutModal, setLogoutModal] = useState(false);

  const { token, businessData, dispatch, api } = useAuthContext();
  const navigate = useNavigate();

  const showOutModal = () => {
    dispatch({ type: "LOGOUT_MODAL", payload: true });
  };

  const showOutCover = () => {
    dispatch({ type: "LOGOUT_COVER", payload: true });
  };

  const logoutUrl = api + "/accounts/auth/logout/";
  const logout = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: "BUSINESS_DATA", payload: null });
      const response = await axios.post(logoutUrl, config);
      console.log(response);
      if (response) {
        localStorage.removeItem("user_token");
        localStorage.removeItem("verified");
        localStorage.removeItem("business");
        localStorage.removeItem("business_secret_id");
        navigate("/account");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const role = localStorage.getItem("role");

  return (
    <div className="dashboard-sidebar">
      {businessData && (
        <div className="dashboard-sidebar-heading">
          <p>{businessData.outlet.name}</p>
          <p>{businessData.public_id}</p>
        </div>
      )}
      {!businessData && (
        <div className="dashboard-sidebar-heading">
          <img src="/assets/roller.svg" alt="" />
        </div>
      )}

      <div className="dashboard-sidebar-tabs">
        <NavLink to="/dashboard/home" className="dashboard-sidebar-links">
          <img src="/assets/Home.svg" alt="" loading="lazy" />
          <p>Home</p>
          <Fade duration={500}>
            <div className="dashboard-sidebar-line"></div>
          </Fade>
        </NavLink>
        <NavLink
          to="/dashboard/transactions"
          className="dashboard-sidebar-links"
        >
          <img src="/assets/Transactions.svg" alt="" loading="lazy" />
          <p>Transactions</p>
          <Fade duration={200}>
            <div className="dashboard-sidebar-line"></div>
          </Fade>
        </NavLink>
        {/* <NavLink to='/dashboard/orgatest' className="dashboard-sidebar-links">
                <img src="/assets/Transactions.svg" alt="" loading='lazy' />
                <p>orgatest</p>
                <Fade duration={200}><div className='dashboard-sidebar-line'>
                    
                </div></Fade>
            </NavLink> */}
        {role === "BOSS" && (
          <NavLink to="/dashboard/payouts" className="dashboard-sidebar-links">
            <img src="/assets/Payouts.svg" alt="" loading="lazy" />
            <p>Payouts</p>
            <Fade duration={200}>
              <div className="dashboard-sidebar-line"></div>
            </Fade>
          </NavLink>
        )}
        {/* {role === "MAGR" && (
          <NavLink to="/dashboard/payouts" className="dashboard-sidebar-links">
            <img src="/assets/Payouts.svg" alt="" loading="lazy" />
            <p>Payouts</p>
            <Fade duration={200}>
              <div className="dashboard-sidebar-line"></div>
            </Fade>
          </NavLink>
        )} */}
        {role === "BOSS" && (
          <NavLink
            to="/dashboard/organisation"
            className="dashboard-sidebar-links"
          >
            <img src="/assets/organisation.svg" alt="" loading="lazy" />
            <p>Organisation</p>
            <Fade duration={200}>
              <div className="dashboard-sidebar-line"></div>
            </Fade>
          </NavLink>
        )}
        {role === "MAGR" && (
          <NavLink
            to="/dashboard/organisation"
            className="dashboard-sidebar-links"
          >
            <img src="/assets/organisation.svg" alt="" loading="lazy" />
            <p>Organisations</p>
            <Fade duration={200}>
              <div className="dashboard-sidebar-line"></div>
            </Fade>
          </NavLink>
        )}
        {/* <NavLink to='/dashboard/refunds' className="dashboard-sidebar-links">
                <img src="/assets/refunds.svg" alt="" loading='lazy' />
                <p>Refunds</p>
                <Fade duration={200}><div className='dashboard-sidebar-line'>
                    
                </div></Fade>
            </NavLink> */}
        <NavLink to="/dashboard/settings" className="dashboard-sidebar-links">
          <img src="/assets/Settings.svg" alt="" loading="lazy" />
          <p>Settings</p>
          <Fade duration={200}>
            <div className="dashboard-sidebar-line"></div>
          </Fade>
        </NavLink>
      </div>

      <div className="dashboard-sidebar-logout">
        <div
          onClick={showOutModal}
          style={{ display: "flex", cursor: "pointer" }}
        >
          <img src="/assets/logout.svg" alt="" loading="lazy" />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

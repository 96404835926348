import React from 'react'
import '../QevoltLabel/QevoltLabel.css'
const QevoltInput = ({style ,onClick ,  onChange , value , onKeyup , onKeyDown , name  , readonly , inputMode , type }) => {
  return (
    <input 
    type={type} 
    style={style}
    onChange={onChange}
    value={value}
    onKeyUp={onKeyup}
    onKeyDown={onKeyDown}
    name={name}
    inputMode={inputMode}
    onClick={onClick}
    className='q-input'
    readonly={readonly}
    required
    />
  )
}

export default QevoltInput
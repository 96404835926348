import { useState , useEffect } from 'react'
import './Success.css'
import AOS from "aos";
import "aos/dist/aos.css";
const Success = ({message}) => {

    const [time , setTime] = useState(true)
    const [show , setShow] =useState(true)
    const [remove,setRemove] = useState(false)
    const display = () =>{
      setShow(true)
      setTimeout(()=>{
        setRemove(true)
      }, 3000)
      setTimeout(()=>{
        setShow(false)
      }, 6000)
    }
    useEffect(() => {
      AOS.init();
      display()
    }, []);

  return (
    <>    
{show && <div className='success'  style={{ animationName : remove ? 'errorLeave' : 'errorEnter', animationDuration : remove ? '1s' : '1s', animationTimingFunction : remove ? 'linear' : ""}}>
     

        <div className="success-text">
            <h1>Success</h1>
            <p>{message}</p>
        </div>
    </div> }
    </>

  )
}

export default Success

import { useState} from "react";

import "./Settings.css";
import { Profile, Payouts, Outlets, Security } from "./states/index";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import SettingsMobile from "./SettingsMobile/SettingsMobile";

const Settings = ({images}) => {

  
  const [isProfile, setIsProfile] = useState(true);
  const [isPayouts, setIsPayouts] = useState(false);
  const [isOutlets, setIsOutlets] = useState(false);
  const [isSecurity, setIsSecurity] = useState(false);

 

  const role = localStorage.getItem("role");
  const ProfileActive = () => {
    // setSettingState(Profile)
    setIsProfile(true);
    setIsPayouts(false);
    setIsOutlets(false);
    setIsSecurity(false);
  };
  const PayoutsActive = () => {
    // setSettingState(Payouts)
    setIsProfile(false);
    setIsPayouts(true);
    setIsOutlets(false);
    setIsSecurity(false);
  };
 
  const SecurityActive = () => {
    // setSettingState(Outlets)
    setIsProfile(false);
    setIsPayouts(false);
    setIsOutlets(false);
    setIsSecurity(true);
  };


  

 



  return (
 
      <div className="dashboard-home-container"style={{height: window.innerHeight}}>
      {window.innerWidth > 900 &&   <>
        <div className="dashboard-settings-navbar">
          <div className="dashboard-settings-navbar-left">
            <p>Settings</p>
            <p>Edit your outlet settings.</p>
          </div>
        </div>
        <div className="settings-nav">
          <div className="setting-flex" style={{ marginRight: "96px" }}>
            <p
              className="settings-profile"
              onClick={ProfileActive}
              style={{ color: isProfile ? "#8F00FF" : "#C4C4C4" }}
            >
              Profile
            </p>
            {isProfile && <div className="settings-line"></div>}
          </div>
          {role !== "STAF" && (
            <div className="setting-flex">
              <p
                className="settings-payouts"
                onClick={PayoutsActive}
                style={{ color: isPayouts ? "#8F00FF" : "#C4C4C4" }}
              >
                Payouts
              </p>
              {isPayouts && <div className="settings-line"></div>}
            </div>
          )}

          <div className="setting-flex" style={{ marginLeft: "96px" }}>
            <p
              className="settings-Security"
              onClick={SecurityActive}
              style={{ color: isSecurity ? "#8F00FF" : "#C4C4C4" }}
            >
              Security
            </p>
            {isSecurity && <div className="settings-line"></div>}
          </div>
        </div>
        <div className="settings-border-bottom"></div>
        <div className="settings-state">
          {isProfile && <Profile />}
          {isPayouts && <Payouts />}
          {isOutlets && <Outlets />}
          {isSecurity && <Security />}
        </div>
        </>}
        {window.innerWidth < 900&&  <> <SettingsMobile images={images} /> </>}
      </div>
    
  );
};

export default Settings;

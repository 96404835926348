import React from "react";
import "./OnBoarding.css";
import {OnBoardingSidebar,} from "../../components/onBoarding-components";
import { Outlet } from "react-router-dom";


const OnBoarding = () => {
  return (
    <div className="OnBoarding-container">
    
        <OnBoardingSidebar />
        
        <Outlet />
    </div>
  );
};

export default OnBoarding;

import { useState } from "react";
import "./security.css";
import { Fade } from "react-reveal";
import axios from "axios";
import { useAuthContext } from "../../../../../Hooks/useAuthContext";
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage'
import Success from '../../../../Success/Success'
const Security = () => {
  const {api , token} = useAuthContext()

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };






  const [confirm_password , setConfirmPassword] = useState('')
  const [password , setPassword] = useState('')
  const [passwordPending , setPasswordPending] = useState(false)
  const [errorMessage , setErrorMessage] = useState('')
  const [successMessage , setSuccessMessage] = useState('')
  const ChangePassword = async (event) =>{
      event.preventDefault()
      setErrorMessage('')
      setSuccessMessage('')
      const form = {confirm_password , password}
      try{
         setPasswordPending(true)
          const response = await axios.post(`${api}/accounts/set_password` , form ,config)
         
          if(response){
            setConfirmPassword('')
            setPassword('')
            setErrorMessage(response.data.error)
            setSuccessMessage(response.data.success)
            setPasswordPending(false)
            console.log(response)
          }
      }
      catch(err){

      }
  }


  // const filled = () => {
  //   if (oldPassword.current.value.length < 8) {
  //     setCheckSecurity(false);
  //   } else {
  //     setCheckSecurity(true);
  //   }
  // };



  // const filled = () => {
  //   if (
  //     name.current.value !== "" &&
  //     email.current.value !== "" &&
  //     password.current.value !== "" &&
  //     !emailError &&
  //     !passwordError
  //   ) {
  //     setCheckSignUp(true);
  //   } else {
  //     setCheckSignUp(false);
  //   }
  // };

  // useEffect(() => {
  //   filled();
  // });

  return (
    <Fade duration={250}>
      <div className="setting-profile-container">

      {errorMessage !== "" && errorMessage && (
        <ErrorMessage message={errorMessage} />
      )}
      {successMessage !==  "" && successMessage && (
        <Success message={successMessage} />
      )}

        <form className="setting-profile-form" action="" onSubmit={ChangePassword}>
         
          <label>
            <div className="label">New Password</div>
            <input
              className="settingsInputBox"
              type="password"
              name="confirm_password"
            required
              // value={eemail}
              value={confirm_password}
              onChange={(e)=>setConfirmPassword(e.target.value)}
              // onChange={filled()}
            />
          </label>
          <label>
            <div className="label">Confirm Password</div>
            <input
              className="settingsInputBox"
              type="password"
              name="password"
               value={password}
              onChange={(e)=>setPassword(e.target.value)}
              required
              // value={eemail}
            
              // onChange={filled()}
            />
          </label>
        

        {/* <p style={{transform:'translateY(15px)' , color: 'green'}}> {successMessage} </p>  */}
        {/* <p style={{transform:'translateY(15px)' , color: 'red'}}> {errorMessage} </p> */}

        <br />
       

        {!passwordPending && <button className="profile-save-button" style={{cursor: 'pointer'}}>
            Save Changes
        </button>}
        {passwordPending && <button className="profile-save-button" style={{cursor: 'pointer'}}>
        <img src="/assets/spinload.svg" alt="" style={{width: '20px' , transform: ' translateY(3px)'}} />
        </button>}
        </form>
        {/* {!saveChanges && (
          <button
            className="profile-save-button"
            style={{
              backgroundColor: checkSecurity ? "#8F00FF " : "#f8f8f8",
              color: checkSecurity ? "white" : "#757575",
              pointerEvents: checkSecurity ? "all" : "none",
              cursor: checkSecurity ? "pointer" : "not-allowed",
            }}
            onClick={() => showChangesLoader()}
          >
            Save Changes
          </button>
        )} */}

        {/* {saveChanges && (
          <button className="profile-loading-save-button">
            <img src="/assets/settingsLoader.svg" alt="" />
          </button>
        )} */}

      
      </div>
    </Fade>
  );
};

export default Security;

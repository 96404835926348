import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useState, useEffect } from "react";
import { NavLink } from "react-bootstrap";
import { Fade } from "react-reveal";
import axios from "axios";
import "./Organisation.css";
import { Link, useNavigate } from "react-router-dom";
import MembersId from "../OrganisationId/MembersId/MembersId";
import OrganisationMobile from "./OrganisationMobile/OrganisationMobile";

const Organisation = () => {
  const navigate = useNavigate();
  const { token, api } = useAuthContext();
  const role = localStorage.getItem('role')
  const [data, setData] = useState(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [showInvites, setShowInvites] = useState(false);
  const [showModalInvites, setShowModalInvites] = useState(false);
  const [outlet_name, setOutletName] = useState("");
  const business_id = localStorage.getItem("business_secret_id");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const Assign = () => {
    setShowAssign(true);
    setShowModal(true);
  };
  const removeitem = () => {
    setShowAssign(false);
    setShowModal(false);
  };

  const invites = () => {
    setShowInvites(true);
    setShowModalInvites(true);
  };
  const removeInvites = () => {
    setShowInvites(false);
    setShowModalInvites(false);
  };

  const [outletPending, setOutletPending] = useState(false);
  const [outletError, setOutletError] = useState("");
  const [outletSuccess, setOutletSuccess] = useState("");
  const createOutlet = async () => {
    const business_secret_id = localStorage.getItem("business_secret_id");
    setOutletPending(true);
    try {
      const outletresponse = await axios.post(
        api + "/main/create/outlet",
        { business_secret_id, outlet_name },
        config
      );
      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: outletresponse.data.secret_id },
        config
      );

      if (virtualaccount) {
        setOutletPending(false);
        setOutletError(virtualaccount.data.Error);
        if (virtualaccount.data.Success) {
          setShowAssign(false);
          setShowModal(false);
          getData();
          setOutletName("");
        }
      }
    } catch (err) {
      setOutletPending(false);
      setOutletError("couldnt handle your request at the moment");
    }
  };

  const url = `${api}/main/all/outlet/${business_id}`;

  // const [data, setData] = useState(null);
  // const [pending, setPending] = useState(false);
  // const [error, setError] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  // const [showAssign, setShowAssign] = useState(false);
  // const [showInvites, setShowInvites] = useState(false);
  // const [showModalInvites, setShowModalInvites] = useState(false);
  // const business_id = localStorage.getItem("business_secret_id");
  // const config = {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  // const url = `https://runtransfer-test.herokuapp.com/main/all/outlet/${business_id}`;

  // const Assign = () => {
  //   setShowAssign(true);
  //   setShowModal(true);
  // };
  // const removeitem = () => {
  //   setShowAssign(false);
  //   setShowModal(false);
  // };

  // const invites = () => {
  //   setShowInvites(true);
  //   setShowModalInvites(true);
  // };
  // const removeInvites = () => {
  //   setShowInvites(false);
  //   setShowModalInvites(false);
  // };

  const navigateToOutlet = (secret, pub , access , outlet_name , outlet_public_id , outlet_accNumber , outlet_bankName) => {
    navigate(`/dashboard/organisation/${pub}`);
    localStorage.setItem("outlet_secret_id", secret);
    localStorage.setItem("access" , access )
    localStorage.setItem('outlet_name' , outlet_name)
    localStorage.setItem('outlet_public_id' , outlet_public_id)
    localStorage.setItem('outlet_accNumber' , outlet_accNumber)
    localStorage.setItem('outlet_bankName' ,outlet_bankName)
  };

  const getData = async () => {
    setPending(true);
    try {
      const response = await axios.get(url, config);
      if (response.status === 200) {
        setPending(false);
        setError(null);
        setData(response.data.results);
     
      }
    } catch (err) {
      setPending(false);
      setError(err.message);
    }
  };

  const reg = localStorage.getItem("reg");

  useEffect(() => {
    getData();
  }, []);

  return (
    
      <div className="dashboard-home-container" style={{height: window.innerHeight}}>
        {window.innerWidth > 900 && <>
        {showAssign && (
          <div id="modalodd" style={{ height: "450px" }}>
            <div className="dashboard-transaction-modal-heading">
              <p>Add an outlet</p>
            </div>
            <div className="payouts-warning" style={{marginBottom: '30px'}}>
              <p>
                You’re about to add a new outlet to your organisation. You’ll be
                able to monitor transactions, refunds, payouts and members of
                this outlet.
              </p>
            </div>

            <div className="dashboard-modal-organisation-input">
              <label>Outlet name</label>
              <input
                type="text"
                name=""
                id=""
                onChange={(e) => setOutletName(e.target.value)}
                value={outlet_name}
              />
              {/* <label htmlFor="">Manager's email address</label>
                <input type="email" name="" id="" /> */}
              <label htmlFor="">
                Custom message <span>(optional)</span>
              </label>
              <input type="text" />

              <div className="form-refunds">
                <button onClick={() => removeitem()}> Cancel</button>

                {!outletPending && outlet_name !== "" && (
                  <button
                    type="submit"
                    value="Next"
                    className="payouts-modal-submit"
                    onClick={() => createOutlet()}
                    style={{
                      backgroundColor: "#8F00FF",
                      color: "#fff",
                      cursor: "pointer",

                      cursor: "pointer",
                    }}
                  >
                    Add
                  </button>
                )}
                {!outletPending && outlet_name === "" && (
                  <button
                    type="submit"
                    className="payouts-modal-submit"
                    style={{
                      backgroundColor: "#F8F8F8",
                      color: "#757575",
                      cursor: "default",

                      cursor: "not-allowed",
                    }}
                    disabled="true"
                  >
                    Add
                  </button>
                )}

                {outletPending && (
                  <button
                    type="submit"
                    className="payouts-modal-submit"
                    style={{
                      backgroundColor: "#8F00FF",
                      color: "#fff",
                      cursor: "pointer",
                      pointerEvents: "none",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src="/assets/spinload.svg"
                      alt=""
                      style={{ width: "20px", transform: "translateY(1px)" }}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {showInvites && (
          <div id="modalodd">
            <div className="dashboard-transaction-modal-heading">
              <p>Pending invites</p>
            </div>
            <div
              className="dashboard-modal-warning"
              id="invites-text"
              style={{ color: "red" }}
            >
              <p>Review pending invitations</p>
            </div>
            <div className="invitation-container">
              <div className="invitation-flex-container">
                <div className="invitation-left">
                  <div className="invite-cirle">
                    <p>BA</p>
                  </div>
                  <div className="invite-text">
                    <p>Boul Aluko</p>
                    <p>bolualuko@qevolt.com</p>
                  </div>
                </div>
                <div className="invitation-right">
                  <button>Revoke</button>
                  <button>Resend</button>
                </div>
              </div>
              <div className="invitation-flex-container">
                <div className="invitation-left">
                  <div className="invite-cirle">
                    <p>BA</p>
                  </div>
                  <div className="invite-text">
                    <p>Boul Aluko</p>
                    <p>bolualuko@qevolt.com</p>
                  </div>
                </div>
                <div className="invitation-right">
                  <button>Revoke</button>
                  <button>Resend</button>
                </div>
              </div>
              <div className="invitation-flex-container">
                <div className="invitation-left">
                  <div className="invite-cirle">
                    <p>BA</p>
                  </div>
                  <div className="invite-text">
                    <p>Boul Aluko</p>
                    <p>bolualuko@qevolt.com</p>
                  </div>
                </div>
                <div className="invitation-right">
                  <button>Revoke</button>
                  <button style={{ background: "#4BB543" }}>Sent</button>
                </div>
              </div>
              <div className="invitation-flex-container">
                <div className="invitation-left">
                  <div className="invite-cirle">
                    <p>BA</p>
                  </div>
                  <div className="invite-text">
                    <p>Boul Aluko</p>
                    <p>bolualuko@qevolt.com</p>
                  </div>
                </div>
                <div className="invitation-right">
                  <button>Revoke</button>
                  <button>Resend</button>
                </div>
              </div>
            </div>

            <div className="dashboard-modal-buttons">
              <button onClick={() => removeInvites()}> Cancel</button>
            </div>
          </div>
        )}
        { reg === "true" && <>
         <div className="dashboard-organisations-navbar">
          <div className="dashboard-organisations-navbar-left">
            <p>Organisation</p>
            <p>Add and monitor outlets in your organisation</p>
          </div>

          {reg === "true" && (
            <div className="dashboard-organisation-navbar-right">
              <button onClick={invites} style={{ display: "none" }}>
                {" "}
                Invitaions{" "}
              </button>
              <button onClick={Assign}>
                <img src="/assets/add.svg" alt="" /> Add an outlet
              </button>
            </div>
          )}
        </div>

        <div className="dashboard-refunds-transactions">
          <div className="dashboard-refunds-transaction-header-flex">
            <p>Outlet</p>
            <p>Outlet Manager</p>
            <p>Amount Verified</p>
            <p>Transactions</p>
            <p>Members</p>
          </div>

          {!pending &&
            data &&
            data.map((val, idx) => (
              <div key={idx}>
                <Fade duration={250}>
                  <div
                    className="dashboard-refunds-fetch-flex"
                    onClick={() =>
                      navigateToOutlet(
                        val.outlet.secret_id,
                        val.outlet.public_id,
                        val.outlet.default,
                        val.outlet.name,
                        val.outlet.public_id,
                        val.outlet.virtual_account.account_number,
                        val.outlet.virtual_account.bank_name
                      )
                    }
                  >
                    <div className="dashboard-refunds-transactions-list">
                      <p>
                        {val.outlet.name}
                        {val.outlet.default === "True" && (
                          <span style={{ color: "#c4c4c4" }}> (You) </span>
                        )}
                      </p>
                    </div>
                    <div className="dashboard-refunds-transactions-list">
                      {/* <p>{val.role}</p> */}
                      <p>{val.outlet.name}</p>
                    </div>
                    {val.outlet.virtual_account !== null && (
                      <div className="dashboard-refunds-transactions-list">
                        <p>&#x20A6;{val.outlet.virtual_account.total}</p>
                      </div>
                    )}
                    {val.outlet.virtual_account === null && (
                      <div className="dashboard-refunds-transactions-list">
                        <p>null</p>
                      </div>
                    )}

                    <div className="dashboard-refunds-transactions-list">
                      <p>{val.outlet.total_transactions}</p>
                    </div>
                    <div className="dashboard-refunds-transactions-list">
                      <p>{val.outlet.total_transactions}</p>
                    </div>
                  </div>
                </Fade>
              </div>
            )).reverse()}
        </div>

        <div className="loa">
          {error && (
            <p className="error">there was an error getting your data</p>
          )}
          {pending && (
            <img
              src="/assets/roller.svg"
              alt="loading..."
              className="pending"
            ></img>
          )}
        </div>
        </>}

        {reg === "false" &&
          <>  
           <div className="dashboard-organisations-navbar">
          <div className="dashboard-organisations-navbar-left">
            <p>Organisation</p>
            <p>Add cashiers to your outlet</p>
          </div>

   
        </div>

        <div className="orgranisationId-line-container" style={{borderBottom: '1px solid #EFEFEF'}}>
        <div className="organisationId-line-items">
      
         <div className="organisation-line-box">
            <p  style={{color: '#8F00FF'}}>Members</p>
           <div className="organisationId-line"></div> 
          </div>
        </div>
      </div>
              <MembersId  roleposition={role} />
          </>
        }
        {showModal && (
          <div className="cover" onClick={() => removeitem()}></div>
        )}
        {showModalInvites && (
          <div className="cover" onClick={() => removeInvites()}></div>
        )}
        </>}
        {window.innerWidth < 900 && <>
           <OrganisationMobile />
        </>}
      </div>
    
  );
};

export default Organisation;

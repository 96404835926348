import React from "react";

const PurpleSmallLoader = () => {
  return (
    <div>
      <div className="loadingio-spinner-spinner-46245t52ydi">
        <div className="ldio-223qe3bmf4b">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default PurpleSmallLoader;

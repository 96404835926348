import { useState, useEffect, useRef } from "react";
import { Fade } from "react-reveal";

import axios from "axios";
import "./Transactions.css";
import { init } from "aos";

import Hamburger from "../Hamburger/Hamburger";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import DateComponent from "../DateComponent/DateComponent";
import TransactionsMobile from "./TransactionsMobile/TransactionsMobile";

const Transactions = () => {
  const stat = useRef();
  const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");
  const [term, setTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [arr, setArr] = useState([]);
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [initialSlice, setInitialSlice] = useState(0);
  const [finalSlice, setFinalSlice] = useState(15);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [search, setSearch] = useState(false);
  const [btnCheck, setBtnCheck] = useState(false);
  const [refundButton, setRefundButton] = useState(true);
  const [refundValue, setRefundValue] = useState("");

  const totalTransactions = newArr.length;

  const dashcontainer = document.querySelector(".dashboard-container");

  const addModal = (event) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.secret_id === event.secret_id;
      });
    });

    setShowModal(true);

    setShowTransaction(true);
    if (window.matchMedia("(max-width: 975px)").matches) {
      dashcontainer.style.cssText = `
       
       height: 100vh;
   
       `;
    }
  };

  const [pagRes, setPagRes] = useState([]);
  //  console.log(totalTransactions)

  const [to, setTo] = useState(0);
  const test = () => {
    if (totalTransactions > 15) {
      setTo(finalSlice);
    } else {
      setTo(totalTransactions);
    }
  };

  const [businessPending, setBusinessPending] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const { dispatch, token, businessData, business, api } = useAuthContext();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };



  const [paginationCount, setPaginationCount] = useState(2);
  const [paginationPrev, seetPaginationPrev] = useState(0);
  const [paginationFirst, setPaginationFirst] = useState(1);
  const [paginationSecond, setPaginationSecond] = useState(10);

  const paginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/transactions/${outlet_secret_id}?page=${paginationCount}`,
        config
      );
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
   
        setPaginationCount((prev) => prev + 1);
        seetPaginationPrev((prev) => prev + 1);
        setPaginationFirst((prev) => prev + 10);
        setPaginationSecond((prev) => prev + 10);
        
      }
    } catch (err) {}
  };

  const paginationPrevious = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/transactions/${outlet_secret_id}?page=${paginationPrev}`,
        config
      );

      if (nextpagination) {
        setPending(false);
        setPaginationCount((prev) => prev - 1);
        seetPaginationPrev((prev) => prev - 1);
        setPaginationFirst((prev) => prev - 10);
        setPaginationSecond((prev) => prev - 10);
        setData(nextpagination.data.results);
      }
    } catch (err) {}
  };

  useEffect(() => {
    //   getData()
    setPaginationCount(2);
    seetPaginationPrev(0);
    setPaginationFirst(1);
    setPaginationSecond(10);

    setSearchPagCount(2);
    setSearchPagPrev(0);
    setSearchPaginationFirst(1);
    setSearchPaginationSecond(10);
    business(
      data,
      setData,
      setPending,
      setError,
      setOutletRreshSecretId,
      setArr,
      setBusinessPending,
      setFilterState,
      setToday,
      setDropFilter,
      setPagRes
    );
  }, []);

  const removeitem = () => {
    if (data) {
      setArr(data);
    }
    setShowRefund(false);
    arr.splice(0, 0);
    setShowModal(false);
    setShowTransaction(false);
    if (window.matchMedia("(max-width: 975px)").matches) {
      dashcontainer.style.height = "90vh";
    }
  };

  //Search transactions

  const [query, setQuery] = useState("");
  const [searchState, setSearchState] = useState(false);
  const searchTransactions = async (event) => {
    event.preventDefault();
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    setSearchPagi(true);
    if (query === "") {
      setSearchPagi(false);
    }
    setSearchPagCount(2);
    setSearchPagPrev(0);
    setSearchPaginationFirst(1);
    setSearchPaginationSecond(10);
    setPaginationCount(2);
    try {
      const searchresponse = await axios.post(
        `${api}/main/search/trans/${outlet_secret_id}?query=${query} `,
        {},
        config
      );
      console.log(searchresponse);
      setData(searchresponse.data.results);
      setArr(searchresponse.data.results);
      setSearchResults(searchresponse.data);
      localStorage.setItem("count", searchresponse.data.count);
      if (searchresponse) {
        setSearchState(true);
        setPending(false);
       
        setPaginationCount(2);
      }
    } catch (err) {}
  };

  //search states for pagination
  const [searchResults, setSearchResults] = useState(null);
  const [searchPagi, setSearchPagi] = useState(false);
  const [searchPagCount, setSearchPagCount] = useState(2);
  const [searchPagPrev, setSearchPagPrev] = useState(0);
  const [searchPaginationFirst, setSearchPaginationFirst] = useState(1);
  const [searchPaginationSecond, setSearchPaginationSecond] = useState(10);

  const searchPaginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);

    try {
      const nextpagination = await axios.post(
        `${api}/main/search/trans/${outlet_secret_id}?page=${searchPagCount}&query=${query} `,
        {},
        config
      );

      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
     
        setSearchPagCount((prev) => prev + 1);
        setSearchPagPrev((prev) => prev + 1);
        setSearchPaginationFirst((prev) => prev + 10);
        setSearchPaginationSecond((prev) => prev + 10);
      }
    } catch (err) {}
  };

  const searchPaginationPrevious = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.post(
        `${api}/main/search/trans/${outlet_secret_id}?page=${searchPagPrev}&query=${query} `,
        {},
        config
      );

      if (nextpagination) {
        setPending(false);
        setSearchPagCount((prev) => prev - 1);
        setSearchPagPrev((prev) => prev - 1);
        setSearchPaginationFirst((prev) => prev - 10);
        setSearchPaginationSecond((prev) => prev - 10);
        setData(nextpagination.data.results);
      }
    } catch (err) {}
  };

  //FILTER TRANSACTIONS

  let now = new Date();
  const [filterCount, setFilterCount] = useState(""); 
  const [filterResults, setFilterResults] = useState(null);
  const [filterState, setFilterState] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const filterTransactions = async (start_date, end_date, change) => {
    setPaginationCount(2);
    setPending(true);
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    console.log(start_date);
    setToday(change);

    const form = { start_date, end_date };

    try {
      const response = await axios.post(
        `${api}/main/filter/trans/${outlet_secret_id}`,
        form,
        config
      );
      if (response?.data?.results) {
        console.log(response);
        setPending(false);
        localStorage.setItem("count", response.data.count);
        setData(response.data.results);
        setArr(response.data.results)
        setFilterResults(response.data);
        setFilterCount(response.data.count);
        setFilterState(true);
        setClearFilter(true);
        setPaginationCount(2);
        setError("");
        dispatch({ type: "FILTER_POP_UP", payload: false });
      }

      if (response?.data?.Error !== "" && !response?.data?.results) {
        setError("Date is incorrect please check again");
        setPending(false);
        dispatch({ type: "FILTER_POP_UP", payload: false });
      }
    } catch (err) {}
  };

  // filter input state

  const [dropFilter, setDropFilter] = useState(false);

  const [filterInput, setFilterInput] = useState("");
  const [today, setToday] = useState("Today");
  const [sDays, setSDays] = useState("Last 7 Days");
  const [fDays, setFDays] = useState("Last 14 Days");
  const [tDays, setTDays] = useState("Last 30 Days");

  const [Radio1, setRadio1] = useState(false);
  const [Radio2, setRadio2] = useState(false);
  const [Radio3, setRadio3] = useState(false);
  const [Radio4, setRadio4] = useState(false);

  const showRadio1 = () => {
    setRadio1(true);
    setRadio2(false);
    setRadio3(false);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio2 = () => {
    setRadio1(false);
    setRadio2(true);
    setRadio3(false);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio3 = () => {
    setRadio1(false);
    setRadio2(false);
    setRadio3(true);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio4 = () => {
    setRadio1(false);
    setRadio2(false);
    setRadio3(false);
    setRadio4(true);
    setDropFilter(false);
  };

  const filterNextPagination = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationCount}`,
        config
      );
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
   
        setPaginationCount((prev) => prev + 1);
        seetPaginationPrev((prev) => prev + 1);
        setPaginationFirst((prev) => prev + 10);
        setPaginationSecond((prev) => prev + 10);
     
      }
    } catch (err) {}
  };
  const filterPreviousPagination = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationCount}`,
        config
      );
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
        
        setPaginationCount((prev) => prev - 1);
        seetPaginationPrev((prev) => prev - 1);
        setPaginationFirst((prev) => prev - 10);
        setPaginationSecond((prev) => prev - 10);
        
      }
    } catch (err) {}
  };

  return (
    <div
      className="dashboard-transactions-container"
      style={{ height: window.innerHeight }}
    >
      {window.innerWidth > 980 && (
        <>
          {showTransaction && (
            <div id="modalodd">
              <div className="dashboard-transaction-modal-heading">
                <p>Transaction Details</p>
              </div>
              {arr &&
                arr.map((arr, idx) => (
                  <div className="dashboard-modal-flex-container" key={idx}>
                    <div className="dashboard-modal-flex">
                      <p>Date and Time</p>
                      <p>{new Date(arr.date_created).toDateString()}</p>
                    </div>
                    <div className="dashboard-modal-flex">
                      <p>Name</p>
                      <p>{arr.payer_account_name.toLocaleLowerCase()}</p>
                    </div>
                    <div className="dashboard-modal-flex">
                      <p>Amount</p>
                      <p> &#x20A6;{parseInt(arr.amount).toLocaleString()}</p>
                    </div>
                    <div className="dashboard-modal-flex">
                      <p>Bank</p>
                      <p>{arr.payer_bank_name}</p>
                    </div>

                    <div className="dashboard-modal-flex">
                      <p>Transaction ID</p>
                      <p>{arr.public_id}</p>
                    </div>
                  </div>
                ))}

              <div className="dashboard-modal-buttons">
                <button onClick={() => removeitem()}> Cancel</button>
              </div>
            </div>
          )}

          <div className="dashboard-transactions-navbar">
            <div className="dashboard-transactions-navbar-left">
              {!search && (
                <>
                  <p>Transactions</p>
                  <p>See all your transactions</p>
                </>
              )}
            </div>

            {!search && <Hamburger stat={stat} />}

            <div className="mobile-right">
              {!search && (
                <img
                  src="/assets/search.svg"
                  alt="search"
                  className="searchmobile"
                  onClick={() => setSearch(true)}
                />
              )}
              {search && (
                <div
                  className="mobilesearchdiv"
                  style={{ transform: "translate(15px , 0px )" }}
                >
                  <img
                    src="/assets/left.svg"
                    alt=""
                    onClick={() => {
                      setSearch(false);
                      setTerm("");
                    }}
                  />
                  <form action="" onSubmit={searchTransactions}>
                    <input
                      className="inputmobile"
                      type="text"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </form>
                </div>
              )}
            </div>
            <div style={{ display: "flex", gap: "30px" }}>
              {filterState && (
                <div
                  className="clear-filter"
                  onClick={() =>
                    business(
                      data,
                      setData,
                      setPending,
                      setError,
                      setOutletRreshSecretId,
                      setArr,
                      setBusinessPending,
                      setFilterState,
                      setToday,
                      setDropFilter,
                      setPagRes
                    )
                  }
                >
                  <p>Clear Filter</p>
                </div>
              )}
              <div className="filter-container-dropdown">
                {!pending && (
                  <div
                    className="filter-drop"
                    onClick={() => setDropFilter(!dropFilter)}
                  >
                    <input
                      type="text"
                      value={today}
                      onChange={(e) => setToday(e.target.value)}
                      style={{
                        color: "transparent",
                        textShadow: "0 0 0 black",
                        cursor: "pointer",
                      }}
                    />
                    <img src="/assets/dropfilter.svg" alt="" />
                  </div>
                )}
                {!pending && !search && (
                  <div
                    className="filter-drop-mobile"
                    onClick={() => setDropFilter(!dropFilter)}
                  >
                    <img src="/assets/filter-mobile.svg" alt="" />
                  </div>
                )}
                {pending && (
                  <div
                    className="filter-drop"
                    style={{ pointerEvents: "none" }}
                  >
                    <input
                      type="text"
                      value={today}
                      onChange={(e) => setToday(e.target.value)}
                      style={{
                        color: "transparent",
                        textShadow: "0 0 0 black",
                        cursor: "pointer",
                      }}
                    />
                    <img src="/assets/dropfilter.svg" alt="" />
                  </div>
                )}
                {dropFilter && (
                  <div className="filter-drop-value">
                    <div
                      className="filter-drop-container"
                      onClick={() => {
                        filterTransactions(
                          now.toISOString().substring(0, 10),
                          new Date(now.setDate(now.getDate() - 0))
                            .toISOString()
                            .substring(0, 10),
                          "Today"
                        );
                        showRadio1();
                      }}
                    >
                      <div
                        className="filter-radio"
                        style={
                          Radio1
                            ? { border: "1px solid #8F00FF" }
                            : { border: "1px solid #c4c4c4" }
                        }
                      >
                        <div
                          className="filter-radio-circle"
                          style={
                            Radio1
                              ? { background: "#8f00ff" }
                              : { background: "none" }
                          }
                        ></div>
                      </div>
                      <p>Today</p>
                    </div>
                    <div
                      className="filter-drop-container"
                      onClick={() => {
                        filterTransactions(
                          now.toISOString().substring(0, 10),
                          new Date(now.setDate(now.getDate() - 7))
                            .toISOString()
                            .substring(0, 10),
                          sDays
                        );
                        showRadio2();
                      }}
                    >
                      <div
                        className="filter-radio"
                        style={
                          Radio2
                            ? { border: "1px solid #8F00FF" }
                            : { border: "1px solid #c4c4c4" }
                        }
                      >
                        <div
                          className="filter-radio-circle"
                          style={
                            Radio2
                              ? { background: "#8f00ff" }
                              : { background: "none" }
                          }
                        ></div>
                      </div>
                      <p>{sDays}</p>
                    </div>
                    <div
                      className="filter-drop-container"
                      onClick={() => {
                        filterTransactions(
                          now.toISOString().substring(0, 10),
                          new Date(now.setDate(now.getDate() - 14))
                            .toISOString()
                            .substring(0, 10),
                          fDays
                        );
                        showRadio3();
                      }}
                    >
                      <div
                        className="filter-radio"
                        style={
                          Radio3
                            ? { border: "1px solid #8F00FF" }
                            : { border: "1px solid #c4c4c4" }
                        }
                      >
                        <div
                          className="filter-radio-circle"
                          style={
                            Radio3
                              ? { background: "#8f00ff" }
                              : { background: "none" }
                          }
                        ></div>
                      </div>
                      <p>{fDays}</p>
                    </div>
                    <div
                      className="filter-drop-container"
                      onClick={() => {
                        filterTransactions(
                          now.toISOString().substring(0, 10),
                          new Date(now.setDate(now.getDate() - 30))
                            .toISOString()
                            .substring(0, 10),
                          tDays
                        );
                        showRadio4();
                      }}
                    >
                      <div
                        className="filter-radio"
                        style={
                          Radio4
                            ? { border: "1px solid #8F00FF" }
                            : { border: "1px solid #c4c4c4" }
                        }
                      >
                        <div
                          className="filter-radio-circle"
                          style={
                            Radio4
                              ? { background: "#8f00ff" }
                              : { background: "none" }
                          }
                        ></div>
                      </div>
                      <p>{tDays}</p>
                    </div>
                  </div>
                )}
              </div>
              <form onSubmit={searchTransactions}>
                <div className="dashboard-transactions-navbar-right">
                  <img
                    src="/assets/search.svg"
                    alt=""
                    className="search"
                    style={{ top: "13px" }}
                  />

                  <input
                    type="text"
                    placeholder="Search a name"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            className="dashboard-transactions-transactions"
            style={{ height: "74vh" }}
          >
            <div className="dashboard-transactions-transaction-header-flex">
              <p>Date and Time</p>
              <p>Name</p>
              <p>Amount</p>
              <p>Bank</p>
              {/* <p>Account Number</p> */}
            </div>
            <div className="preload">
              {error && (
                <p className="error">There was an error getting your data</p>
              )}
              {pending && (
                <img
                  src="/assets/roller.svg"
                  alt="loading..."
                  className="pending"
                ></img>
              )}
            </div>
            <div className="transactions-scroll">
              {!pending &&
                data &&
                data.map((val, idx) => (
                  <div key={idx}>
                    <Fade duration={250}>
                      <div
                        className="dashboard-transactions-fetch-flex"
                        onClick={() => addModal(val)}
                      >
                        <div className="dashboard-transactions-transactions-list">
                          <DateComponent
                            date_created={val.date_created}
                            seen={val.seen}
                          />
                        </div>
                        <div className="dashboard-transactions-transactions-list">
                          <p>{val.payer_account_name.toLocaleLowerCase()}</p>
                        </div>
                        <div className="dashboard-transactions-transactions-list">
                          <p>&#x20A6;{parseInt(val.amount).toLocaleString()}</p>
                        </div>
                        <div className="dashboard-transactions-transactions-list">
                          <p>{val.payer_bank_name}</p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                ))}
            </div>
          </div>

          {/* start of normal pagination */}
          {!searchPagi && !filterState && (
            <div className="dashboard-transactions-pagination">
              {paginationPrev !== 0 && !pending && (
                <div
                  className="previous"
                  onClick={() => {
                    paginationPrevious();
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}
              {paginationPrev === 0 && !pending && (
                <div
                  className="previous"
                  style={{
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}

              {pending && (
                <div
                  className="previous"
                  style={{
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}
              {businessData && (
                <div className="dashboard-transactions-pagination-text">
                  <p>
                    Results: {paginationFirst} -{" "}
                    {paginationSecond > +businessData.total_transactions
                      ? `${businessData.total_transactions}`
                      : ` ${paginationSecond}`}{" "}
                    of {businessData.total_transactions}
                  </p>
                </div>
              )}

              {businessData &&
                paginationCount <= Math.ceil(pagRes.count / 10) &&
                !pending && (
                  <div
                    className="next"
                    onClick={() => {
                      paginationNext();
                    }}
                  >
                    <img
                      src="/assets/right-arrow.svg"
                      alt=""
                      style={{
                        filter:
                          finalSlice === businessData.total_transactions
                            ? "invert(100%)"
                            : "invert(0%)",
                      }}
                    />
                  </div>
                )}
              {pending && (
                <div className="next">
                  <img
                    src="/assets/right-arrow.svg"
                    alt=""
                    style={{
                      pointerEvents: "none",
                      cursor: "not-allowed",
                    }}
                  />
                </div>
              )}
              {businessData &&
                paginationCount > Math.ceil(pagRes.count / 10) &&
                !pending && (
                  <div className="next">
                    <img
                      src="/assets/right-arrow.svg"
                      alt=""
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    />
                  </div>
                )}
            </div>
          )}
          {/* end of normal pagination */}

          {/* start of filtered pagination */}

          {filterState && (
            <div className="dashboard-transactions-pagination">
              {searchPagPrev !== 0 && !pending && (
                <div
                  className="previous"
                  onClick={() => {
                    filterPreviousPagination();
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}
              {paginationPrev === 0 && !pending && (
                <div
                  className="previous"
                  style={{
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}

              {pending && (
                <div
                  className="previous"
                  style={{
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}
              {filterResults && (
                <div className="dashboard-transactions-pagination-text">
                  <p>
                    Results: {paginationFirst} -{" "}
                    {paginationSecond > +filterCount
                      ? `${filterCount}`
                      : ` ${paginationSecond}`}{" "}
                    of {filterCount}
                  </p>
                </div>
              )}

              {filterResults &&
                paginationCount <= Math.ceil(filterCount / 10) &&
                !pending && (
                  <div
                    className="next"
                    onClick={() => {
                      filterNextPagination();
                    }}
                  >
                    <img src="/assets/right-arrow.svg" alt="" style={{}} />
                  </div>
                )}
              {pending && (
                <div className="next">
                  <img
                    src="/assets/right-arrow.svg"
                    alt=""
                    style={{
                      pointerEvents: "none",
                      cursor: "not-allowed",
                    }}
                  />
                </div>
              )}
              {filterResults &&
                paginationCount > Math.ceil(filterCount / 10) &&
                !pending && (
                  <div className="next">
                    <img
                      src="/assets/right-arrow.svg"
                      alt=""
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    />
                  </div>
                )}
            </div>
          )}

          {/* end of filtered pagination */}

          {/* search pagination */}

          {searchPagi && (
            <div className="dashboard-transactions-pagination">
              {searchPagPrev !== 0 && !pending && (
                <div
                  className="previous"
                  onClick={() => {
                    searchPaginationPrevious();
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}
              {searchPagPrev === 0 && !pending && (
                <div
                  className="previous"
                  style={{
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}

              {pending && (
                <div
                  className="previous"
                  style={{
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" />
                </div>
              )}
              {searchResults && (
                <div className="dashboard-transactions-pagination-text">
                  <p>
                    Results: {searchPaginationFirst} -{" "}
                    {searchPaginationSecond > +searchResults.count
                      ? `${searchResults.count}`
                      : ` ${searchPaginationSecond}`}{" "}
                    of {searchResults.count}
                  </p>
                </div>
              )}

              {searchResults &&
                searchPagCount <= Math.ceil(searchResults.count / 10) &&
                !pending && (
                  <div
                    className="next"
                    onClick={() => {
                      searchPaginationNext();
                    }}
                  >
                    <img src="/assets/right-arrow.svg" alt="" style={{}} />
                  </div>
                )}
              {pending && (
                <div className="next">
                  <img
                    src="/assets/right-arrow.svg"
                    alt=""
                    style={{
                      pointerEvents: "none",
                      cursor: "not-allowed",
                    }}
                  />
                </div>
              )}
              {searchResults &&
                searchPagCount > Math.ceil(searchResults.count / 10) &&
                !pending && (
                  <div className="next">
                    <img
                      src="/assets/right-arrow.svg"
                      alt=""
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    />
                  </div>
                )}
            </div>
          )}

          {/* end of search pagination */}
          <div className="preload"></div>
          {showModal && (
            <div className="cover" onClick={() => removeitem()}></div>
          )}
        </>
      )}
      {window.innerWidth < 980 && (
        <TransactionsMobile
          data={data}
          pending={pending}
          addModal={addModal}
          arr={arr}
          removeitem={removeitem}
          query={query}
          setQuery={setQuery}
          searchTransactions={searchTransactions}
          sDays={sDays}
          tDays={tDays}
          showRadio2={showRadio2}
          showRadio4={showRadio4}
          filterTransactions={filterTransactions}
          Radio2={Radio2}
          Radio4={Radio4}
          setData={setData}
          setPending={setPending}
          setError={setError}
          error={error}
          setArr={setArr}
          clearFilter={clearFilter}
          setPaginationCount={setPaginationCount}
          paginationCount={paginationCount}
          setClearFilter={setClearFilter}
          showRadio3={showRadio3}
          searchState={searchState}
          setSearchState={setSearchState}
        />
      )}
    </div>
  );
};

export default Transactions;

import React, { useState, useRef, useCallback } from "react";
import { useAuthContext } from "../../../../Hooks/useAuthContext";
import PopUp from "../../PopUp/PopUp";
import DateComponent from "../../DateComponent/DateComponent";
import Hamburger from "../../Hamburger/Hamburger";
import "./TransactionsMobile.css";
import axios from "axios";
import OtpInput from "react-otp-input";
import { borderBottom } from "@mui/system";
import { useEffect } from "react";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import PurpleLoader from "../../../Loaders/PurpleLoader";
import GreyLoader from "../../../Loaders/GreyLoader";
const TransactionsMobile = ({
  error,
  data,
  pending,
  addModal,
  arr,
  removeitem,
  query,
  setQuery,
  searchTransactions,
  sDays,
  tDays,
  showRadio2,
  showRadio4,
  filterTransactions,
  Radio2,
  Radio4,
  setData,
  setPending,
  setError,
  setArr,
  clearFilter,
  setClearFilter,
  showRadio3,
  paginationCount,
  setPaginationCount,
  searchState,
  setSearchState,
}) => {
  const { businessData, pop, dispatch, transactionPop, filterPop, api, token } =
    useAuthContext();

  let now = new Date();

  const [search, setSearch] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // console.log(`20${startDate.substring(4 , 6)}-${startDate.substring(0 , 2)}-${startDate.substring(3,5)}`)

  // console.log(startDate)

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const getTransactions = async () => {
    setPending(true);
    setPaginationCount(2);
    setCustomShow(false);
    try {
      const response = await axios.get(
        `${api}/main/view/transactions/${localStorage.getItem(
          "outlet_secret_id"
        )}`,
        config
      );
      if (response) {
        setPending(false);
        setError(null);
        setData(response.data.results);
        setArr(response.data.results);
        setClearFilter(false);
        localStorage.setItem("count", response.data.count);
        setSearchState(false);
      }

      if (data) {
        setArr(data);
      }
    } catch (err) {
      setError("");
    }
  };

  const [panLink, setPanLink] = useState("");
  const [newData, setNewData] = useState(null);

  const count = localStorage.getItem("count");

  const [pendingP, setPendingP] = useState(false);
  const [customShow, setCustomShow] = useState(false);

  const paginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");

    setPendingP(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/transactions/${outlet_secret_id}?page=${paginationCount}`,
        config
      );

      if (nextpagination) {
        setPendingP(false);
        setSearchState(false);
        setData([...data, ...nextpagination.data.results]);
        setArr([...data, ...nextpagination.data.results]);
        setPaginationCount(paginationCount + 1);
      }
    } catch (err) {}
  };

  const filterNextPagination = async (start_date, end_date) => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");

    setPendingP(true);
    const form = { start_date, end_date };
    try {
      const nextpagination = await axios.post(
        `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationCount}`,
        form,
        config
      );

      if (nextpagination) {
        setPendingP(false);
        setData([...data, ...nextpagination.data.results]);
        setArr([...data, ...nextpagination.data.results]);
        setPaginationCount(paginationCount + 1);
      }
    } catch (err) {}
  };

  const searchPaginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPendingP(true);

    try {
      const nextpagination = await axios.post(
        `${api}/main/search/trans/${outlet_secret_id}?page=${paginationCount}&query=${query} `,
        {},
        config
      );

      if (nextpagination) {
        setPendingP(false);
        setData([...data, ...nextpagination.data.results]);
        setArr([...data, ...nextpagination.data.results]);
        setPaginationCount(paginationCount + 1);
      }
    } catch (err) {}
  };

  const [trigger, setTrigger] = useState(0);

  const onScroll = () => {
    Radio2 && data.length < +count
      ? filterNextPagination(dayAfterString1, dayBeforeDateString)
      : Radio4 && data?.length < +count
      ? filterNextPagination(dayAfterString, dayBeforeDateString)
      : !searchState && !customShow && data?.length < +count
      ? paginationNext()
      : searchState && !customShow && data?.length < +count
      ? searchPaginationNext()
      : customShow && data?.length < +count
      ? filterNextPagination(startModified, endModified)
      : console.log("");
  };

  //present
  const startinputDate = new Date(now.toISOString().substring(0, 10));
  const dayBeforeDate = new Date(
    startinputDate.getTime() + 24 * 60 * 60 * 1000
  );
  const dayBeforeDateString = dayBeforeDate.toISOString().slice(0, 10);

  //last 7
  const endinputDate1 = new Date(
    new Date(now.setDate(now.getDate() - 7)).toISOString().substring(0, 10)
  );
  const dayAfterDate1 = new Date(endinputDate1.getTime() - 24 * 60 * 60 * 1000);
  const dayAfterString1 = dayAfterDate1.toISOString().slice(0, 10);

  //last 30
  const endinputDate = new Date(
    new Date(now.setDate(now.getDate() - 30)).toISOString().substring(0, 10)
  );
  const dayAfterDate = new Date(endinputDate.getTime() - 24 * 60 * 60 * 1000);
  const dayAfterString = dayAfterDate.toISOString().slice(0, 10);

  const [startModified, setStartModified] = useState("");
  const [endModified, setEndModified] = useState("");

  let dateValue1 = `20${startDate?.substring(4, 6)}-${startDate?.substring(
    0,
    2
  )}-${startDate?.substring(2, 4)}`;

  const dateValue2 = `20${endDate?.substring(4, 6)}-${endDate?.substring(
    0,
    2
  )}-${endDate?.substring(2, 4)}`;

  useEffect(() => {
    if (startDate.length === 6) {
      const date = new Date(dateValue1);
      // Get the previous day's date by subtracting 1 from the current day
      const previousDay = new Date(date.getTime() - 24 * 60 * 60 * 1000);
      // Format the previous day's date as YYYY-DD-MM
      setStartModified(
        previousDay.getFullYear() +
          "-" +
          ("0" + (previousDay.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + previousDay.getDate()).slice(-2)
      );
    }

    //day before
    if (endDate.length === 6) {
      const date = new Date(dateValue2);
      // Get the next day's date by adding 1 to the current day
      const nextDay = new Date(date.getTime() + 24 * 60 * 60 * 1000);
      // Format the next day's date as YYYY-DD-MM
      setEndModified(
        nextDay.getFullYear() +
          "-" +
          ("0" + (nextDay.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + nextDay.getDate()).slice(-2)
      );
    }
  }, [startDate, endDate]);

  return (
    <div className="mobile-container-transactions">
      {error && error !== "" && <ErrorMessage message={error} />}
      {filterPop && (
        <PopUp
          style={{ height: "52vh" }}
          removeItem={removeitem}
          heading={"Filter"}
          sub={""}
        >
          {Radio2 || Radio4 || endDate !== "" || startDate !== "" ? (
            <div
              className="pop-body"
              style={{
                position: "absolute",
                right: "24px",
                top: "30px",
                zIndex: "50000",
              }}
              onClick={() => {
                getTransactions();
                setEndDate("");
                setStartDate("");
                showRadio3();
                dispatch({ type: "FILTER_POP_UP", payload: false });
              }}
            >
              <p style={{ color: "#8f00ff" }}>Clear</p>
            </div>
          ) : (
            <div
              className="pop-body"
              style={{
                position: "absolute",
                right: "24px",
                top: "30px",
                zIndex: "50000",
              }}
            >
              <p style={{ color: "#757575" }}>Clear</p>
            </div>
          )}
          <div
            className="pop-body"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "25px",
            }}
          >
            <p>Last 7 Days</p>
            <div
              className="filter-radio"
              style={
                Radio2
                  ? {
                      border: "1px solid #8F00FF",
                      transform: "translateY(11px)",
                    }
                  : {
                      border: "1px solid #c4c4c4",
                      transform: "translateY(11px)",
                    }
              }
              onClick={() => {
                filterTransactions(dayAfterString1, dayBeforeDateString, sDays);
                showRadio2();
                setStartDate("");
                setEndDate("");
                setCustomShow(false);
              }}
            >
              <div
                className="filter-radio-circle"
                style={
                  Radio2 ? { background: "#8f00ff" } : { background: "none" }
                }
              ></div>
            </div>
          </div>
          <div
            className="pop-body"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p>Last 30 Days</p>
            <div
              className="filter-radio"
              style={
                Radio4
                  ? {
                      border: "1px solid #8F00FF",
                      transform: "translateY(11px)",
                    }
                  : {
                      border: "1px solid #c4c4c4",
                      transform: "translateY(11px)",
                    }
              }
              onClick={() => {
                filterTransactions(dayAfterString, dayBeforeDateString, tDays);
                showRadio4();
                setStartDate("");
                setEndDate("");

                setCustomShow(false);
              }}
            >
              <div
                className="filter-radio-circle"
                style={
                  Radio4 ? { background: "#8f00ff" } : { background: "none" }
                }
              ></div>
            </div>
          </div>
          <div className="pop-body" style={{ marginBottom: "12px" }}>
            <p>Custom Date</p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="pop-start-end"
              style={{
                position: "relative",
                background: "#F8F8F8",
                padding: "16px 5px",
                borderRadius: "10px",
              }}
            >
              <OtpInput
                value={startDate}
                onChange={setStartDate}
                numInputs={6}
                separator={<span> </span>}
                inputStyle={{
                  width: "5vw",
                  padding: "0px",
                  background: "none",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#757575",
                  borderRadius: "0px ",
                }}
                isInputNum={true}
                placeholder="MMDDYY"
              />
              <div
                className="separa"
                style={{
                  display: "flex",
                  gap: "calc(10vw +  0px)",
                  position: "absolute",
                  left: "calc(10vw + 5px)",
                  transform: "translateY(-3px)",
                }}
              >
                <p style={{ color: "#757575" }}></p>
                <p style={{ color: "#757575" }}></p>
              </div>
            </div>

            <div
              className="pop-start-end"
              style={{
                position: "relative",
                background: "#F8F8F8",
                padding: "16px 5px",
                borderRadius: "10px",
              }}
            >
              <OtpInput
                value={endDate}
                onChange={setEndDate}
                numInputs={6}
                separator={<span> </span>}
                inputStyle={{
                  width: "5vw",
                  padding: "0px",
                  background: "none",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#757575",
                  borderRadius: "0px !important",
                  borderRadius: "0px ",
                }}
                placeholder="MMDDYY"
                isInputNum={true}
              />
              <div
                className="separa"
                style={{
                  display: "flex",
                  gap: "calc(10vw +  0px)",
                  position: "absolute",
                  left: "calc(10vw + 5px)",
                  transform: "translateY(-3px)",
                }}
              >
                <p style={{ color: "#757575" }}></p>
                <p style={{ color: "#757575" }}></p>
              </div>
            </div>
          </div>
          <div className="pop-body">
            {endDate.length !== 6 && startDate !== 6 && (
              <button>Apply Date</button>
            )}
            {/* {endDate.length === 6 && startDate !== 6 && <button>Apply filter</button>}
                      {endDate.length !== 6 && startDate === 6 && <button>Apply filter</button>} */}

            {endDate.length === 6 && startDate.length === 6 && (
              <button
                onClick={() => {
                  filterTransactions(startModified, endModified);
                  setCustomShow(true);
                }}
                style={{ background: "#8f00ff" }}
              >
                Apply filter
              </button>
            )}
          </div>
        </PopUp>
      )}
      {search && (
        <div className="mobile-transactions-top">
          <div className="m-transactions-top-left">
            <h1>Transactions</h1>
          </div>
          <div className="m-transactions-top-right">
            <img
              src="/assets/sm.svg"
              alt=""
              onClick={() => {
                setSearch(false);
                setCustomShow(false);
              }}
            />
            <img
              src="/assets/fm.svg"
              alt=""
              onClick={() => {
                dispatch({ type: "TRANSACTION_POP_UP", payload: false });
                dispatch({ type: "POP_UP", payload: false });
                dispatch({ type: "FILTER_POP_UP", payload: true });
              }}
            />
          </div>
        </div>
      )}

      {!search && (
        <form action="" onSubmit={searchTransactions}>
          <div className="mobile-search">
            <img src="/assets/si.svg" alt="" />
            <input
              type="text"
              placeholder="Search transactions"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <img
              src="/assets/nx.svg"
              alt=""
              onClick={() => {
                setSearch(true);
                getTransactions();
              }}
            />
          </div>
        </form>
      )}

      {transactionPop && (
        <PopUp
          style={{ height: "45vh" }}
          removeItem={removeitem}
          heading={"Transaction Details"}
          sub={""}
        >
          {arr.map((arr, idx) => (
            <>
              <div
                className="pop-body"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "13px",
                }}
              >
                <p>Bank</p>
                <h2>{arr.payer_bank_name}</h2>
              </div>
              <div
                className="pop-body"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "13px",
                }}
              >
                <p>Name</p>
                <h2>{arr.payer_account_name.toLowerCase()}</h2>
              </div>
              <div
                className="pop-body"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "13px",
                }}
              >
                <p>Amount</p>
                <h2>&#x20A6;{parseInt(arr.amount.toLocaleString())}</h2>
              </div>
              <div
                className="pop-body"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "13px",
                }}
              >
                <p>Date and Time</p>
                <h2>
                  {new Date(arr.date_created).toDateString().substring(4)} ,{" "}
                  {`${new Date(arr.date_created)
                    .toTimeString()
                    .substring(0, 2)} : ${new Date(arr.date_created)
                    .toTimeString()
                    .substring(3, 5)}  `}{" "}
                  {new Date(arr.date_created).toTimeString().substring(0, 2) >
                  12
                    ? "pm"
                    : "am"}
                </h2>
              </div>
              <div
                className="pop-body"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "13px",
                }}
              >
                <p>Transaction ID</p>
                <h2>{arr.public_id}</h2>
              </div>
            </>
          ))}
        </PopUp>
      )}
      <BottomScrollListener onBottom={onScroll} debounce={0}>
        {(scrollRef) => (
          <div
            className="transactions-mobile-table"
            // onScroll={() => onScroll()}
            ref={scrollRef}
          >
            {pending && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <PurpleLoader />
              </div>
            )}
            {data && data.length === 0 && !pending && (
              <div
                className="table-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img src="/assets/empty-home.svg" alt="" />
                <p className="empty-list-show">No transactions available</p>
              </div>
            )}
            {data &&
              !pending &&
              data.map((val, idx) => (
                <div className="table-container" key={idx}>
                  <div
                    className="table-flex"
                    onClick={() => {
                      addModal(val);
                      dispatch({ type: "TRANSACTION_POP_UP", payload: true });
                      dispatch({ type: "POP_UP", payload: false });
                      dispatch({ type: "FILTER_POP_UP", payload: false });
                    }}
                  >
                    <div className="left-table">
                      <div className="left-table-circle">
                        <p style={{ color: "#757575", fontSize: "20px" }}>
                          {val.payer_account_name
                            .toLocaleUpperCase()
                            .substring(0, 1)}
                        </p>
                      </div>
                      <div className="left-table-details">
                        <h3 style={{ marginBottom: "6px" }}>
                          {val.payer_account_name
                            .toLocaleLowerCase()
                            .substring(0, 15)}
                          ...
                          {val.seen === "False" ? (
                            <span
                              style={{
                                color: "white",
                                transform: "translateX(20px)",
                                backgroundColor: "#4BB543",
                                padding: "2px 6px",
                                borderRadius: "5px",
                              }}
                            >
                              New
                            </span>
                          ) : (
                            <></>
                          )}
                        </h3>
                        <DateComponent
                          date_created={val.date_created}
                          seen={val.seen}
                        />
                      </div>
                    </div>
                    <div className="right-table">
                      <p>
                        {" "}
                        <p>&#x20A6;{parseInt(val.amount).toLocaleString()}</p>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            {pendingP && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <GreyLoader />
              </div>
            )}
          </div>
        )}
      </BottomScrollListener>
      <Hamburger />
    </div>
  );
};

export default TransactionsMobile;

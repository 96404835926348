import React from "react";
import "./Loader.css";

const WhiteLoader = () => {
  return (
    <div>
      <div className="loadingio-spinner-spinner-x78lblppll">
        <div className="ldio-8f8kukmpldt">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default WhiteLoader;

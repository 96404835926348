import Hamburger from "../../Hamburger/Hamburger";
import PopUp from "../../PopUp/PopUp";
import { useAuthContext } from "../../../../Hooks/useAuthContext";
import { useEffect, useState } from "react";
import "./OrganisationMobile.css";
import axios from "axios";
import QevoltLabel from "../../../QevoltLabel/QevoltLabel";
import QevoltInput from "../../../QevoltInput/QevoltInput";
import QevoltButton from "../../../QevoltButton/QevoltButton";
import Success from "../../../Success/Success";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import WhiteLoader from "../../../Loaders/WhiteLoader";
import PurpleLoader from "../../../Loaders/PurpleLoader";
import RedLoader from "../../../Loaders/RedLoader";
const OrganisationMobile = () => {
  const userole = localStorage.getItem("role");
  const { businessData, pop, dispatch, filterPop, token, api } =
    useAuthContext();
  const [data, setData] = useState(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [arr, setArr] = useState([]);
  const outlet_id = localStorage.getItem("outlet_secret_id");

  const [invite, setInvite] = useState(false);

  const [managerCount, setManagerCount] = useState(0);
  const [bossCount, setBossCount] = useState(0);
  const [cashierCount, setCashierCount] = useState(0);

  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [frole, setFrole] = useState("");
  const [toggleRole, setToggleRole] = useState(false);

  const [bArr, setBarr] = useState([]);
  const [mArr, setMarr] = useState([]);
  const [cArr, setCarr] = useState([]);

  const showFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
  };
  const showSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
  };
  const showThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const getRoles = async () => {
    setPending(true);
    setMarr([]);
    setBarr([]);
    setCarr([]);
    try {
      const response = await axios.get(
        `${api}/main/view/roles/${outlet_id}`,
        config
      );
      if (response) {
        setPending(false);
        setError(null);
        setData(response.data.results);
        setArr(response.data.results);
        //mapping through roles and spreading specific roles to an array and getting the length of array
      }

      response.data.results.map((val) => {
        if (val.role === "BOSS") {
          setBarr((prev) => [...prev, val]);
        }
        if (val.role === "MAGR") {
          setMarr((prev) => [...prev, val]);
        }
        if (val.role === "STAF") {
          setCarr((prev) => [...prev, val]);
        }
      });
    } catch (err) {
      setPending(false);
      setError(err.message);
    }
  };
  const [pendingData, setPendingData] = useState(null);

  const [InvitesPending, setInvitesPending] = useState(false);
  const [invitesError, setInviteError] = useState("");
  const [invitesSuccess, setInvitesSuccess] = useState(null);
  const [RolesModal, setRolesModal] = useState(false);

  const showRolesModal = () => {
    setRolesModal(true);
  };

  const pendingInvites = async () => {
    setInvitesPending(true);
    try {
      const response = await axios.get(
        `${api}/main/view/pending/roles/${outlet_id}`,
        config
      );

      if (response) {
        setInvitesPending(false);
      }
      setPendingData(response.data.results);
    } catch (err) {
      setInviteError("There was an error getting your data");
    }
  };

  const toggleRoles = () => {
    setToggleRole(!toggleRole);
  };
  const rolearr = [
    {
      roleB: "MAGR",
      roleFr: "Outlet Manager",
    },

    {
      roleB: "STAF",
      roleFr: "Cashier",
    },
  ];
  const roleoptions = (value, value2) => () => {
    setToggleRole(false);
    setFrole(value);
    setRole(value2);
  };

  const roleoptionsalone = (value) => () => {
    setToggleRole(false);
    setRole(value);
  };
  const [outletRolePending, setOutletRolePending] = useState(false);
  const [outletRoleError, setOutletRoleError] = useState("");
  const [outletRoleData, setOutletRoleData] = useState(null);
  const createOutletRole = async () => {
    setOutletRolePending(true);
    setInvitesSuccess("");
    setOutletRoleError("");
    try {
      const response = await axios.post(
        api + "/main/create/roles",
        { outlet_secret_id: outlet_id, role, email },
        config
      );

      if (response) {
        setOutletRolePending(false);
        setOutletRoleData(response.data);
      }

      if (response.data.Error !== "") {
        setOutletRoleError(response.data.Error);
      }

      if (response.data.Success !== "") {
        setInvitesSuccess(response.data.Success);
        pendingInvites();
      }
    } catch (err) {
      setOutletRoleError(
        "There was an error sending your request , please check your internet connection"
      );
      setOutletRolePending(false);
    }
  };

  const [inputCheck, setInputCheck] = useState(false);

  const present = () => {
    email === "" && role === "" ? setInputCheck(false) : setInputCheck(true);
  };

  const [rolePending, setRolePending] = useState(false);

  const deleteRoles = async (role_secret_id) => {
    setRolePending(true);
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");

    const form = { outlet_secret_id, role_secret_id };
    try {
      const response = await axios.post(
        `${api}/main/delete/roles`,
        form,
        config
      );

      if (response) {
        setRolePending(false);
        getRoles();
        dispatch({ type: "FILTER_POP_UP", payload: false });
        setInvitesSuccess("Role deleted successfully");
        setOutletRoleError("");
      }
    } catch (err) {
      setRolePending(false);
      setOutletRoleError(
        "could not complete request at the momement try again"
      );
    }
  };
  const deleteRoleModal = (role_id) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.role_secret_id === role_id;
      });
    });

    dispatch({ type: "FILTER_POP_UP", payload: true });
  };

  const [resendPending, setResendPending] = useState(false);
  const [resendData, setResendData] = useState(null);
  const [resendSuccess, setResenSuccess] = useState("");
  const [resendError, setResendError] = useState(null);
  const [pendingCheck, setPendingCheck] = useState(null);
  const resendInvite = async (roleid) => {
    setRoleCheck(roleid);
    setPendingCheck(true);
    setPending(true);
    setResenSuccess("");
    setResendError("");
    try {
      const response = await axios.post(
        `${api}/main/resend/invite`,
        { roleid },
        config
      );

      if (response) {
        setResendData(response);
        setPending(false);
        setPendingCheck(null);
        setResenSuccess("Invite sent");
        setResendError(response.data.Error);
      }
    } catch (err) {
      setPending(false);
    }
  };
  const [roleCheck, setRoleCheck] = useState("");
  const revokeInvite = async (roleid) => {
    setPendingCheck(false);
    setRoleCheck(roleid);
    setResendPending(true);
    setResenSuccess("");
    setResendError("");

    try {
      const response = await axios.post(
        `${api}/main/revoke/invite`,
        { roleid },
        config
      );

      if (response) {
        setResendData(response);
        setResendPending(false);
        console.log(response);
        setResenSuccess("Invite has been revoked");
        setResendError(response.data.error?.null);
        pendingInvites();
        setPendingCheck(null);
      }
    } catch (err) {
      setResendError("Could not delete please try again");
      setResendPending(false);
    }
  };

  const check_role = localStorage.getItem("role");

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <>
      {!invite && (
        <div className="mobile-container-transactions">
          {pop && (
            <PopUp style={{ height: "60vh" }} heading={"Roles info"} sub={""}>
              <div className="pop-body">
                <h1>Admins</h1>
                <p>
                  By creating an outlet on Qevolt, you become the admin of the
                  organization, giving you the power to establish new outlets,
                  oversee actions and assign managers and cashiers to various
                  outlets. Note that only registered business admins can have
                  multiple outlets.
                </p>
              </div>
              <div className="pop-body">
                <h1>Managers</h1>
                <p>
                  Outlet Managers are responsible for managing the day-to-day
                  operations of a designated outlet. They can add cashiers and
                  update outlet information, but they do not have the ability to
                  create new outlets like Admins do.
                </p>
              </div>
              <div className="pop-body">
                <h1>Cashiers</h1>
                <p>
                  Cashiers can only view and verify bank transfers for their
                  assigned outlet, and cannot access an outlet's Payouts or
                  Organization page. They are only able to update personal
                  information under Settings.
                </p>
              </div>
            </PopUp>
          )}
          <div className="home-mobile-top">
            <div className="m-transactions-top-left">
              <h1>Organisation</h1>
            </div>
            <div
              className="business-heading-mobile"
              onClick={() => dispatch({ type: "POP_UP", payload: true })}
            >
              <p
                onClick={() => {
                  dispatch({ type: "POP_UP", payload: true });
                }}
              >
                {" "}
                <img src="/assets/inn.svg" alt="" /> info
              </p>
            </div>
          </div>
          <div className="mobile-navigation">
            <div
              className="mobile-tab"
              onClick={showFirst}
              style={{ borderBottom: first ? "1px solid #8f00ff" : "none" }}
            >
              <p>Admin </p>
              <div>
                {" "}
                <h2>{bArr.length}</h2>{" "}
              </div>
            </div>
            <div
              className="mobile-tab"
              onClick={showSecond}
              style={{ borderBottom: second ? "1px solid #8f00ff" : "none" }}
            >
              <p>Manager </p>
              <div>
                {" "}
                <h2>{mArr.length} </h2>{" "}
              </div>
            </div>
            <div
              className="mobile-tab"
              onClick={showThird}
              style={{ borderBottom: third ? "1px solid #8f00ff" : "none" }}
            >
              <p>Cashier</p>
              <div>
                {" "}
                <h2>{cArr.length}</h2>{" "}
              </div>
            </div>
          </div>
          <div
            className="transactions-mobile-table"
            style={{ marginTop: "93px" }}
          >
            {pending && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80%",
                }}
              >
                <PurpleLoader />
              </div>
            )}

            {first &&
              bArr.map((val) => (
                <div className="table-container">
                  <div className="table-flex">
                    <div className="left-table">
                      <div
                        className="name-circle"
                        style={{ width: "48px", height: "48px" }}
                      >
                        {val.role === "BOSS" && (
                          <p> {val.account.name.substring(0, 1)} </p>
                        )}
                      </div>
                      <div className="left-table-details">
                        {val.role === "BOSS" && (
                          <h3 style={{ marginBottom: "6px" }}>
                            {val.account.name}
                          </h3>
                        )}
                        {val.role === "BOSS" && <p>{val.account.email}</p>}
                      </div>
                    </div>
                    <div className="right-table"></div>
                  </div>
                </div>
              ))}

            {second &&
              mArr.map((val) => (
                <div className="table-container">
                  <div className="table-flex">
                    <div className="left-table">
                      <div
                        className="name-circle"
                        style={{ width: "48px", height: "48px" }}
                      >
                        <p> {val.account.name.substring(0, 1)} </p>
                      </div>
                      <div className="left-table-details">
                        <h3 style={{ marginBottom: "6px" }}>
                          {val.account.name}
                        </h3>
                        <p>{val.account.email}</p>
                      </div>
                    </div>
                    {check_role === "BOSS" && (
                      <div className="right-table">
                        {val.role === "MAGR" && (
                          <p
                            onClick={() => deleteRoleModal(val.role_secret_id)}
                          >
                            {" "}
                            <img src="/assets/bin.svg" alt="" />{" "}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}

            {third &&
              cArr.map((val) => (
                <div className="table-container">
                  <div className="table-flex">
                    <div className="left-table">
                      <div
                        className="name-circle"
                        style={{ width: "48px", height: "48px" }}
                      >
                        <p> {val.account.name.substring(0, 1)} </p>
                      </div>
                      <div className="left-table-details">
                        <h3 style={{ marginBottom: "6px" }}>
                          {val.account.name}
                        </h3>
                        <p>{val.account.email}</p>
                      </div>
                    </div>
                    <div className="right-table">
                      {val.role === "STAF" && (
                        <p onClick={() => deleteRoleModal(val.role_secret_id)}>
                          {" "}
                          <img src="/assets/bin.svg" alt="" />{" "}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div
            className="absolute-pop"
            onClick={() => {
              setInvite(true);
              pendingInvites();
            }}
          >
            <img src="/assets/pops.svg" alt="" />
          </div>
          <Hamburger />

          {filterPop && (
            <PopUp
              style={{ height: "30vh" }}
              heading={"Delete member"}
              sub={
                "You’re about to delete this member. You can’t undo this action"
              }
              getRoles={getRoles}
            >
              {arr.map((val) => (
                <div className="delte-container">
                  <div
                    className="pop-button"
                    style={{ transform: "translate(0px , 5px)" }}
                  >
                    <button>Cancel</button>
                    {!rolePending && (
                      <button
                        onClick={() => {
                          deleteRoles(val.role_secret_id);
                        }}
                      >
                        Delete
                      </button>
                    )}
                    {rolePending && (
                      <button>
                        {" "}
                        <RedLoader />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </PopUp>
          )}
          {invitesSuccess !== "" && invitesSuccess && (
            <Success message={`${invitesSuccess.substring(0, 27)} ${email}`} />
          )}
          {outletRoleError !== "" && outletRoleError && (
            <ErrorMessage message={outletRoleError} />
          )}
        </div>
      )}
      {invite && (
        <div style={{ height: "100vh", overflowY: "auto" }}>
          {outletRoleData && invitesSuccess !== "" && invitesSuccess && (
            <Success message={`${invitesSuccess.substring(0, 27)} ${email}`} />
          )}
          {outletRoleData && outletRoleError !== "" && outletRoleError && (
            <ErrorMessage message={outletRoleError} />
          )}
          {resendData && resendSuccess !== "" && resendSuccess && (
            <Success message={`${resendSuccess}`} />
          )}
          {resendData && resendError !== "" && resendError && (
            <ErrorMessage message={resendError} />
          )}
          {pop && (
            <PopUp style={{ height: "60vh" }} heading={"Roles info"} sub={""}>
              <div className="pop-body">
                <h1>Admins</h1>
                <p>
                  By creating an outlet on Qevolt, you become the admin of the
                  organization, giving you the power to establish new outlets,
                  oversee actions and assign managers and cashiers to various
                  outlets. Note that only registered business admins can have
                  multiple outlets.
                </p>
              </div>
              <div className="pop-body">
                <h1>Managers</h1>
                <p>
                  Outlet Managers are responsible for managing the day-to-day
                  operations of a designated outlet. They can add cashiers and
                  update outlet information, but they do not have the ability to
                  create new outlets like Admins do.
                </p>
              </div>
              <div className="pop-body">
                <h1>Cashiers</h1>
                <p>
                  Cashiers can only view and verify bank transfers for their
                  assigned outlet, and cannot access an outlet's Payouts or
                  Organization page. They are only able to update personal
                  information under Settings.
                </p>
              </div>
            </PopUp>
          )}

          <div className="mobile-container-transactions">
            <div className="home-mobile-top">
              <div className="m-transactions-top-left">
                <h1 style={{ fontSize: "18px" }}>
                  <img
                    src="/assets/arrow-left.svg"
                    alt=""
                    style={{
                      marginRight: "26px",
                      alignSelf: "center",
                      transform: "translateY(5px)",
                    }}
                    onClick={() => {
                      setInvite(false);
                      setInvitesSuccess("");
                      setResenSuccess("");
                      setResendError("");
                      setInviteError("");
                    }}
                  />{" "}
                  Add a member
                </h1>
              </div>
              <div
                className="business-heading-mobile"
                onClick={() => dispatch({ type: "POP_UP", payload: true })}
              >
                <p
                  onClick={() => {
                    dispatch({ type: "POP_UP", payload: true });
                  }}
                >
                  {" "}
                  <img src="/assets/inn.svg" alt="" /> info
                </p>
              </div>
            </div>
            <div className="invites-mid">
              <p className="invites-mid-intro">Pending invitations</p>
              <div
                className="transactions-mobile-table"
                style={{ height: "auto" }}
              >
                {pendingData &&
                  !InvitesPending &&
                  pendingData.map((val, idx) => (
                    <div className="table-container" key={idx}>
                      <div className="table-flex">
                        <div className="left-table">
                          <div
                            className="name-circle"
                            style={{ width: "48px", height: "48px" }}
                          >
                            {val.role === "MAGR" && <p> O </p>}
                            {val.role === "STAF" && <p> C </p>}
                          </div>
                          <div className="left-table-details">
                            {val.role === "MAGR" && (
                              <h3 style={{ marginBottom: "6px" }}>
                                Outlet manager
                              </h3>
                            )}
                            {val.role === "STAF" && (
                              <h3 style={{ marginBottom: "6px" }}>Cashier</h3>
                            )}
                            <p>{val.email}</p>
                          </div>
                        </div>
                        <div
                          className="right-table"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "72px",
                          }}
                        >
                          {pendingCheck === null && (
                            <>
                              <p
                                onClick={() => resendInvite(val.role_secret_id)}
                              >
                                {" "}
                                <img src="/assets/re.svg" alt="" />{" "}
                              </p>
                              <p
                                onClick={() => revokeInvite(val.role_secret_id)}
                              >
                                {" "}
                                <img src="/assets/bin.svg" alt="" />{" "}
                              </p>
                            </>
                          )}
                          {pendingCheck === false && (
                            <>
                              {!resendPending && (
                                <>
                                  {" "}
                                  <p>
                                    {" "}
                                    <img src="/assets/re.svg" alt="" />{" "}
                                  </p>
                                  <p
                                    onClick={() =>
                                      revokeInvite(val.role_secret_id)
                                    }
                                  >
                                    {" "}
                                    <img src="/assets/bin.svg" alt="" />{" "}
                                  </p>{" "}
                                </>
                              )}
                              {roleCheck === val.role_secret_id && (
                                <>
                                  {resendPending && (
                                    <>
                                      {" "}
                                      <p>
                                        {" "}
                                        <img src="/assets/re.svg" alt="" />{" "}
                                      </p>{" "}
                                      <p>
                                        <PurpleLoader />
                                      </p>{" "}
                                    </>
                                  )}
                                </>
                              )}
                              {roleCheck !== val.role_secret_id && (
                                <>
                                  {resendPending && (
                                    <>
                                      {" "}
                                      <p>
                                        {" "}
                                        <img src="/assets/re.svg" alt="" />{" "}
                                      </p>
                                      <p>
                                        {" "}
                                        <img
                                          src="/assets/bin.svg"
                                          alt=""
                                        />{" "}
                                      </p>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {pendingCheck === true && (
                            <>
                              {!pending && (
                                <>
                                  {" "}
                                  <p
                                    onClick={() =>
                                      resendInvite(val.role_secret_id)
                                    }
                                  >
                                    {" "}
                                    <img src="/assets/re.svg" alt="" />{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    <img src="/assets/bin.svg" alt="" />{" "}
                                  </p>{" "}
                                </>
                              )}
                              {roleCheck === val.role_secret_id && (
                                <>
                                  {pending && (
                                    <>
                                      <p>
                                        {" "}
                                        <img
                                          src="/assets/re.svg"
                                          alt=""
                                          className="spin"
                                        />{" "}
                                      </p>
                                      <p>
                                        <img src="/assets/bin.svg" alt="" />
                                      </p>{" "}
                                    </>
                                  )}
                                </>
                              )}
                              {roleCheck !== val.role_secret_id && (
                                <>
                                  {pending && (
                                    <>
                                      {" "}
                                      <p>
                                        {" "}
                                        <img src="/assets/re.svg" alt="" />{" "}
                                      </p>
                                      <p>
                                        {" "}
                                        <img
                                          src="/assets/bin.svg"
                                          alt=""
                                        />{" "}
                                      </p>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                {InvitesPending && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <PurpleLoader />
                  </div>
                )}
              </div>
            </div>
            <div className="invites-form">
              <p className="invites-mid-intro">New invitation</p>

              <QevoltLabel text={"Email address"} />
              <QevoltInput
                name={"email"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setInvitesSuccess("");
                  setOutletRoleError("");
                }}
                onKeyup={() => present()}
              />
              <QevoltLabel text={"Role"} />
              <input
                name="role"
                className="q-input"
                value={frole}
                onClick={() => toggleRoles()}
                onChange={(e) => {
                  setFrole(e.target.value);
                  setInvitesSuccess("");
                  setOutletRoleError("");
                }}
                onKeyup={() => present()}
                style={{
                  color: "transparent",
                  textShadow: "0 0 0 black",
                  caretColor: "transparent",
                }}
                placeholder="Select"
                readonly={"readonly"}
              />
              {toggleRole && userole === "BOSS" && (
                <ul
                  className="role-drop"
                  style={{ width: "100%", transform: "translateY(-5px)" }}
                >
                  {rolearr.map((val, idx) => (
                    <li
                      key={idx}
                      onClick={roleoptions(val.roleFr, val.roleB)}
                      style={{ cursor: "pointer", color: "black" }}
                    >
                      {val.roleFr}
                    </li>
                  ))}
                </ul>
              )}
              {toggleRole && userole === "MAGR" && (
                <ul
                  className="role-drop"
                  style={{ width: "100%", transform: "translateY(-5px)" }}
                >
                  <li
                    onClick={roleoptionsalone("STAF")}
                    style={{ cursor: "pointer", color: "black" }}
                  >
                    Cashier
                  </li>
                </ul>
              )}

              {inputCheck && !outletRolePending && (
                <QevoltButton
                  style={{ background: "#8f00ff", color: "white" }}
                  text={"Send invite"}
                  onClick={() => createOutletRole()}
                />
              )}
              {!inputCheck && !outletRolePending && (
                <QevoltButton
                  text={"Send Invite"}
                  style={{ background: "#d9d9d9" }}
                />
              )}
              {outletRolePending && (
                <button className="q-button" style={{ background: "#d9d9d9" }}>
                  <WhiteLoader />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrganisationMobile;

import React from 'react'
import './QevoltLabel.css'
const QevoltLabel = ({text , style }) => {
  return (

        <label className='q-label' style={style}>{text}</label>
    
  )
}

export default QevoltLabel
import axios from "axios";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { Fade } from "react-reveal";

import { CopyToClipboard } from "react-copy-to-clipboard";

import "./Home.css";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import AOS from "aos";
import "aos/dist/aos.css";
import Hamburger from "../Hamburger/Hamburger";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import DateComponent from "../DateComponent/DateComponent";
import HomeMobile from "./HomeMobile/HomeMobile";

const Home = () => {
  const roll = useRef();

  useEffect(() => {
    AOS.init();
  }, []);

  const refreshPage = useRef();

  const [showTransaction, setShowTransaction] = useState(false);
  const [data, setData] = useState(null);
  const [term, setTerm] = useState("");
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [bank, setBank] = useState("3140791773");
  const [showRefund, setShowRefund] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [arr, setArr] = useState([]);
  const [refundButton, setRefundButton] = useState(true);
  const [refundValue, setRefundValue] = useState("");
  const [newt, setNewt] = useState("");
  const [refundsMobile, setRefundsMobile] = useState(false);
  const [rollerPending, setRollerPending] = useState(false);
  const [btnCheck, setBtnCheck] = useState(false);
  const [search, setSearch] = useState(false);
  const [fullName, setFullName] = useState(false);
  const [tranDate, setTranDate] = useState("");

  const getTransactions = async () => {
    setPending(true);
    try {
      const response = await axios.get(
        `${api}/main/view/transactions/${localStorage.getItem(
          "outlet_secret_id"
        )}`,
        config
      );
      if (response) {
        setPending(false);
        setError(null);
        setData(response.data.results);
        setArr(response.data.results);
      }

      if (data) {
        setArr(data);
      }
    } catch (err) {
      setError("");
    }
  };

  const navigate = useNavigate();

  const copy = useRef();

  const dashcontainer = document.querySelector(".dashboard-container");
  const cov = document.querySelector(".cover");
  const mod = document.querySelector("#modalodd");
  const addModal = (event) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.secret_id === event.secret_id;
      });
    });

    setShowModal(true);

    setShowTransaction(true);

    if (window.matchMedia("(max-width: 975px)").matches) {
      dashcontainer.style.cssText = `height: 100vh;`;
    }
  };

  const business_id = localStorage.getItem("business_secret_id");
  const [Odata, setOData] = useState(null);
  const [Opending, setOPending] = useState(false);
  const [Oerror, setOError] = useState("O");
  const getOutletList = async () => {
    setOPending(true);
    try {
      const response = await axios.get(
        `${api}/main/all/outlet/${business_id}`,
        config
      );
      if (response) {
        setOPending(false);
        setOError(null);
        setOData(response.data.results);
     
      }
    } catch (err) {
      setOPending(false);
      setOError(err.message);
    }
  };

  // GETTING ALL BUSINESSES
  const [businessPending, setBusinessPending] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const { dispatch, token, businessData, secret_id, api, business } =
    useAuthContext();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");

  //
  const removeitem = () => {
    if (data) {
      setArr(data);
    }
    setShowRefund(false);
    arr.splice(0, 0);
    setShowModal(false);
    setShowTransaction(false);
    if (window.matchMedia("(max-width: 975px)").matches) {
      dashcontainer.style.height = "90vh";
    }
  };

  const removeItemMobile = () => {
    if (data) {
      setArr(data);
    }
    setRefundsMobile(false);
    arr.splice(0, 0);
    setShowModal(false);
    setShowTransaction(false);
  };

  const refundmax = () => {
    arr.map((val) => {
      if (parseInt(refundValue) < parseInt(val.amount)) {
        setRefundButton(true);
      }
      if (parseInt(refundValue) === parseInt(val.amount)) {
        setRefundButton(true);
      }
      if (parseInt(refundValue) > parseInt(val.amount)) {
        setRefundButton(false);
        setBtnCheck(true);
      }
    });

    if (parseInt(refundValue) === "") {
      setRefundButton(false);
    }
  };

  const refunds = () => {
    setShowRefund(true);
    setShowTransaction(false);
  };

  const refundsMob = () => {
    setRefundsMobile(true);
    setShowTransaction(false);
  };

  const getWhiteData = async () => {
    const outlet_id = localStorage.getItem("outlet_secret_id");
    setRollerPending(true);
    roll.current.classList.add("roll");
    try {
      const response = await axios.get(
        `${api}/main/view/transactions/${outlet_id}`,
        config
      );
      if (response) {
        setRollerPending(false);
        setError(null);
        roll.current.classList.remove("roll");
        setData(response.data.results);
        setArr(response.data.results);
      }

      if (data) {
        setArr(data);
      }
    } catch (err) {
      roll.current.classList.remove("roll");
      setRollerPending(false);
      setError(err.message);
    }
  };
  const [virtualpending, setVirtualPending] = useState(false);
  const [virtualResponse, setVirtualResponse] = useState(null);

  const getVirtualAccount = async () => {
    setVirtualPending(true);
    try {
      const yes = { outlet_secret_id: secret_id };
      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        yes,
        config
      );
      const businessresponse = await axios.get(
        api + "/main/all/business",
        config
      );

      if (businessresponse) {
        dispatch({
          type: "ADMIN",
          payload: businessresponse.data.results[0].admin,
        });
        dispatch({
          type: "BUSINESS_DATA",
          payload: businessresponse.data.results[0],
        });
      }

      setVirtualPending(false);
      setVirtualResponse(virtualaccount);
    } catch (err) {
      setVirtualPending(false);
    }
  };
  const [filterState, setFilterState] = useState(false);
  const [today, setToday] = useState("Today");
  const [dropFilter, setDropFilter] = useState(false);
  const [pagRes, setPagRes] = useState([]);

  useEffect(() => {
    business(
      data,
      setData,
      setPending,
      setError,
      setOutletRreshSecretId,
      setArr,
      setBusinessPending,
      setFilterState,
      setToday,
      setDropFilter,
      setPagRes
    );
    dispatch({ type: "FILTER_POP_UP", payload: false });
    dispatch({ type: "POP_UP", payload: false });
    dispatch({ type: "TRANSACTIONS_POP_UP", payload: false });
  }, []);

  const removeCopy = () => {
    setTimeout(() => {
      copy.current.classList.add("fade-out");
    }, 1500);
    setTimeout(() => {
      setCopied(false);
      copy.current.classList.remove("fade-out");
    }, 3000);
  };

  const showcopied = () => {
    setCopied(true);
  };



  return (
    <>
      <div
        className="dashboard-home-container"
        style={{ height: window.innerHeight }}
      >
        {
          <>
            {" "}
            <HomeMobile
              data={data}
              pending={pending}
              addModal={addModal}
              arr={arr}
              removeitem={removeitem}
              getOutletList={getOutletList}
              Odata={Odata}
              Opending={Opending}
              Oerror={Oerror}
              getTransactions={getTransactions}
              getWhiteData={getWhiteData}
              rollerPending={rollerPending}
              roll={roll}
              getVirtualAccount={getVirtualAccount}
              virtualpending={virtualpending}
            />{" "}
            {rollerPending && (
              <div className="coverrefresh">
                <div className="refreshbar">REFRESHINGGGG</div>
              </div>
            )}
          </>
        }
      </div>
    </>
  );
};

export default Home;

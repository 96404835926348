import React from "react";
import { useRef, useState, useEffect } from "react";
import "./Forgot.css";
import { NavLink, Link, useNavigate, useSearchParams } from "react-router-dom";
import { Fade } from "react-reveal";
import axios from "axios";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import Success from "../../Success/Success";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import WhiteLoader from "../../Loaders/WhiteLoader";
const Forgot = () => {
  const [pageUrl, setPageUrl] = useSearchParams();

  const navigate = useNavigate();
  const { api } = useAuthContext();
  const emaill = useRef();
  const [checkForgot, setCheckForgot] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem('mail_login'));
  const [resetPending, setResetPending] = useState(false);
  const [resetSuccess, setResetSuccess] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetData, setResetData] = useState(null);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const reset = async (e) => {
    setResetPending(true);
    setResetError("");
    setResetSuccess("");
    e.preventDefault();
    try {
      const response = await axios.post(
        `${api}/accounts/auth/password/reset/`,
        { email: email.toLowerCase() },
        config
      );
      if (response) {
        setResetSuccess("Check your email for a link to reset password");
        setResetError(response.data.Error);
        setResetData(response.data);
        setResetPending(false);
      }
      console.log(response);
    } catch (err) {
      if (err.code === 'ERR_NETWORK') {
        setResetError('Internet connection lost or disconnected');
        setResetPending(false)
      }  
      else if (err.code === 'ECONNABORTED') {
        setResetError('Request timeout , check internet connection');
        setResetPending(false)
      } else {
        console.error(err);
        setResetPending(false)
      }
      
    }
  };

  const filled = () => {
    if (!emailError && emaill.current.value !== "") {
      setCheckForgot(true);
    } else {
      setCheckForgot(false);
    }
  };

  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emailKeyUp = () => {
    if (emailRegEx.test(emaill.current.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if (emaill.current.value === "") {
      setEmailError(false);
    }
  };

  useEffect(() => {
    filled();
  }, []);

  const handleForgot = (e) => {
    e.preventDefault();
  };

  return (
    <Fade duration={250}>
      <div className="forgot-container">
        {resetData && resetSuccess !== "" && resetSuccess && (
          <Success message={resetSuccess} />
        )}
        {resetError !== "" && resetError && (
          <ErrorMessage message={resetError} />
        )}
        {/* <div className="mobile-nav">
          <img src="/assets/Qevolt-logo-1.svg" alt="" />
          <NavLink to="/account/">Log in</NavLink>
        </div> */}
        <div className="forgot-main-text">Forgot Password</div>
        <div className="forgot-subtext">
          A link will be sent to your email address to reset password
        </div>
        <form className="forgot-form" onSubmit={reset}>
          <div className="email-address-area">
            <label>
              <div className="name-label">Email address</div>
              <input
                className="inputboxSignup"
                type="email"
                name="email"
                value={email}
                required
                placeholder="johndoe@mail.com"
                ref={emaill}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                style={{
                  outlineColor: emailError ? "red" : "#8F00FF",
                }}
              />
              {emailError && (
                <p className="emailError">Please enter a valid email address</p>
              )}
            </label>
          </div>
          {!resetPending && email !== "" && (
            <button
              type="submit"
              value="Next"
              className="SignUp-button"
              style={{
                backgroundColor: "#8F00FF",
                color: "#fff",
                cursor: "pointer",

                cursor: "pointer",
              }}
            >
              Send link
            </button>
          )}
          {!resetPending && email === "" && (
            <button
              type="submit"
              value="Next"
              className="SignUp-button"
              style={{
                backgroundColor: "#d4d4d4",
                color: "#fff",
                cursor: "pointer",

                cursor: "pointer",
              }}
            >
              Send link
            </button>
          )}

          {resetPending && (
            <button
              type="submit"
              value="Next"
              className="SignUp-button"
              style={{
                backgroundColor: "#8F00FF",
                color: "#fff",
                cursor: "pointer",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            >
              <WhiteLoader />
            </button>
          )}
        </form>
        <div className="forgot-to-login ">
          <Link to="/account/login" className="app-purple">
            <div>Return to Login</div>
          </Link>
        </div>
        <div className="forgot-to-signup">
          Don’t have an account?{" "}
          <Link to="/account/signup" className="app-purple">
            Sign Up
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export default Forgot;

import { Fade } from "react-reveal";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Payouts.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import Success from "../../Success/Success";
import DateComponent from "../DateComponent/DateComponent";
import { QevoltData } from "../../QevoltData/QevoltData";
import PayoutsMobile from "./PayoutsMobile/PayoutsMobile";
const Payouts = () => {
  const { token, businessData, api , dispatch } = useAuthContext();
  
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [arr, setArr] = useState([]);
  const [showModal, setShowModal] = useState(false);
 
 
  const { livecode } = QevoltData();

  

  const addModal = (payVal) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.ref === payVal.ref;
      });
    });
  };

 

 

  const removeitem = () => {
    setShowModal(false);
    setPayoutError("");
    setPayoutMessage(null);
    if (data) {
      setArr(data);
    }
  };

 
  const outlet_secret_id = localStorage.getItem("outlet_secret_id");

  const url = `${api}/main/view/withdraw/${outlet_secret_id}`;

 

  const [payoutMessage, setPayoutMessage] = useState(null);
  const [payoutError, setPayoutError] = useState("");

  
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [payoutsData, setPayoutsData] = useState(null);

  const getData = async () => {
    setPending(true);
    try {
      const response = await axios.get(url, config);
      if (response) {
        setPending(false);
        setError(null);
        localStorage.setItem("count", response.data.count);
        setSearchState(false)
      }
      console.log(response);
      setData(response.data.results);
      setArr(response.data.results);
      setPagRes(response.data);
      setPayoutsData(response.data);

      if (data) {
        setArr(data);
      }
    } catch (err) {
      setPending(false);
      setError(err.message);
    }
  };

  useEffect(() => {
    setPaginationCount(2);
    seetPaginationPrev(0);
    setPaginationFirst(1);
    setPaginationSecond(10);
    getData();
  }, [url]);

  const [pagRes, setPagRes] = useState([]);


  const [paginationCount, setPaginationCount] = useState(2);
  const [paginationPrev, seetPaginationPrev] = useState(0);
  const [paginationFirst, setPaginationFirst] = useState(1);
  const [paginationSecond, setPaginationSecond] = useState(10);

  
  const paginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/withdraw/${outlet_secret_id}?page=${paginationCount}`,
        config
      );
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
       
        setPaginationCount((prev) => prev + 1);
        seetPaginationPrev((prev) => prev + 1);
        setPaginationFirst((prev) => prev + 10);
        setPaginationSecond((prev) => prev + 10);
       
      }
    } catch (err) {}
  };

  const paginationPrevious = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/withdraw/${outlet_secret_id}?page=${paginationPrev}`,
        config
      );

      if (nextpagination) {
        setPending(false);
        setPaginationCount((prev) => prev - 1);
        seetPaginationPrev((prev) => prev - 1);
        setPaginationFirst((prev) => prev - 10);
        setPaginationSecond((prev) => prev - 10);
        setData(nextpagination.data.results);
      }
    } catch (err) {}
  };

  const [dropFilter, setDropFilter] = useState(false);
  const [today, setToday] = useState("Today");
  const [sDays, setSDays] = useState("Last 7 Days");
 
  const [tDays, setTDays] = useState("Last 30 Days");
  const [query, setQuery] = useState("");

  const [Radio1, setRadio1] = useState(false);
  const [Radio2, setRadio2] = useState(false);
  const [Radio3, setRadio3] = useState(false);
  const [Radio4, setRadio4] = useState(false);

  // const showRadio1 = () => {
  //   setRadio1(true);
  //   setRadio2(false);
  //   setRadio3(false);
  //   setRadio4(false);
  //   setDropFilter(false);
  // };
  const showRadio2 = () => {
    setRadio1(false);
    setRadio2(true);
    setRadio3(false);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio3 = () => {
    setRadio1(false);
    setRadio2(false);
    setRadio3(true);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio4 = () => {
    setRadio1(false);
    setRadio2(false);
    setRadio3(false);
    setRadio4(true);
    setDropFilter(false);
  };


  const filterTransactions = async ( start_date, end_date, change) => {
    setPending(true);
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setToday(change);
    const form = { start_date, end_date };

    try {
      const response = await axios.post(
        `${api}/main/filter/withdraw/${outlet_secret_id}`,
        form,
        config
      );
      if (response?.data?.results) {
        console.log(response);
        setPending(false);
        localStorage.setItem("count", response.data.count);
        setData(response.data.results);
        setArr(response.data.results)
        setPaginationCount(2);
        setError("");
        dispatch({ type: "FILTER_POP_UP", payload: false });
      }
    } catch (err) {}
  };



  //search payouts
  const [searchState, setSearchState] = useState(false);
  const searchPayouts = async (event) => {
    event.preventDefault();
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
   
    if (query === "") {

    }
  
    try {
      const searchresponse = await axios.post(
        `${api}/main/search/withdraw/${outlet_secret_id}?query=${query} `,
        {},
        config
      );
      console.log(searchresponse);
      setData(searchresponse.data.results);
      setArr(searchresponse.data.results);
      localStorage.setItem("count", searchresponse.data.count);
      if (searchresponse) {
        setSearchState(true);
        setPending(false);
       
      }
    } catch (err) {}
  };
  return (
    <div
      className="dashboard-home-container"
      style={{ height: window.innerHeight }}
    >
      {window.innerWidth > 980 && (
        <>
          {payoutMessage !== null && <Success message={payoutMessage} />}
          {payoutError !== "" && <ErrorMessage message={payoutError} />}

         
          <div className="dashboard-payouts-navbar">
            <div className="dashboard-payouts-navbar-left">
              <p>Payouts</p>
              <p>We settle your account immediately</p>
            </div>
            {/* <div className="dashboard-payouts-navbar-right">
            <button onClick={Withdraw}>Withdraw</button>
          </div> */}
          </div>
          <div
            className="dashboard-transactions-transactions"
            style={{ height: "75vh" }}
          >
            <div className="dashboard-payouts-transaction-header-flex">
              <p>Date and Time</p>
              <p>Amount</p>
              <p>Status</p>
              <p>Bank</p>
            </div>
            <div className="preload">
              {error && (
                <p className="error">There was an error getting your data </p>
              )}
              {pending && (
                <img
                  src="/assets/roller.svg"
                  alt="loading..."
                  className="pending"
                />
              )}
            </div>
            {!pending &&
              data &&
              data.map((val, idx) => (
                <Fade duration={250}>
                  <div className="dashboard-payouts-fetch-flex" key={idx}>
                    <div className="dashboard-payouts-transactions-list">
                      <DateComponent
                        date_created={val.date_created}
                        seen={val.seen}
                      />
                    </div>
                    <div className="dashboard-payouts-transactions-list">
                      <p>&#x20A6;{parseInt(val.amount).toLocaleString()}</p>
                    </div>
                    <div className="dashboard-payouts-transactions-list">
                      <p>
                        <h4
                          className="status"
                          style={
                            val.status === true
                              ? {
                                  color: "#4BB543",
                                  background: "#eefcf3",
                                  width: "50px",
                                }
                              : val.status === false
                              ? {
                                  color: "#757575",
                                  background: "#f8f8f8",
                                  width: "62px",
                                }
                              : {
                                  color: "#757575",
                                  background: "#f8f8f8",
                                  width: "80px",
                                }
                          }
                        >
                          {val.status === null && "Pending"}
                          {val.status === true && "Paid"}
                          {val.status === false && "Failed"}
                        </h4>
                      </p>
                    </div>
                    <div className="dashboard-payouts-transactions-list">
                      {livecode.map((va, idx) => (
                        <>
                          {va.bankCode === val.pay_to_bank_code && (
                            <p>{va.bankName}</p>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </Fade>
              ))}
          </div>

          <div className="dashboard-transactions-pagination">
            {paginationPrev !== 0 && !pending && (
              <div
                className="previous"
                onClick={() => {
                  paginationPrevious();
                }}
              >
                <img src="/assets/right-arrow.svg" alt="" />
              </div>
            )}
            {paginationPrev === 0 && !pending && (
              <div
                className="previous"
                style={{
                  pointerEvents: "none",
                }}
              >
                <img src="/assets/right-arrow.svg" alt="" />
              </div>
            )}

            {pending && (
              <div
                className="previous"
                style={{
                  pointerEvents: "none",
                }}
              >
                <img src="/assets/right-arrow.svg" alt="" />
              </div>
            )}
            {payoutsData && (
              <div className="dashboard-transactions-pagination-text">
                <p>
                  Results: {paginationFirst} -{" "}
                  {paginationSecond > +payoutsData.count
                    ? `${payoutsData.count}`
                    : ` ${paginationSecond}`}{" "}
                  of {payoutsData.count}
                </p>
              </div>
            )}

            {businessData &&
              paginationCount <= Math.ceil(pagRes.count / 10) &&
              !pending && (
                <div
                  className="next"
                  onClick={() => {
                    paginationNext();
                  }}
                >
                  <img src="/assets/right-arrow.svg" alt="" style={{}} />
                </div>
              )}
            {pending && (
              <div className="next">
                <img
                  src="/assets/right-arrow.svg"
                  alt=""
                  style={{
                    pointerEvents: "none",
                  }}
                />
              </div>
            )}
            {businessData &&
              paginationCount > Math.ceil(pagRes.count / 10) &&
              !pending && (
                <div className="next">
                  <img
                    src="/assets/right-arrow.svg"
                    alt=""
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
              )}
          </div>
          {showModal && (
            <div className="cover" onClick={() => removeitem()}></div>
          )}
        </>
      )}
      {window.innerWidth < 980 && (
        <PayoutsMobile
          data={data}
          pending={pending}
          addModal={addModal}
          arr={arr}
          setArr={setArr}
          removeitem={removeitem}
          query={query}
          setQuery={setQuery}
          sDays={sDays}
          tDays={tDays}
          showRadio2={showRadio2}
          showRadio4={showRadio4}
          filterTransactions={filterTransactions}
          showRadio3={showRadio3}
          Radio2={Radio2}
          Radio4={Radio4}
          setData={setData}
          searchPayouts={searchPayouts}
          getData={getData}
          paginationCount={paginationCount}
          setPaginationCount={setPaginationCount}
          searchState={searchState}
          setSearchState={setSearchState}
        />
      )}
    </div>
  );
};

export default Payouts;

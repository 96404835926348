import React from "react";

const RedLoader = () => {
  return (
    <div>
      <div class="loadingio-spinner-spinner-yef5dlqvhr">
        <div className="ldio-j1upor2783i">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default RedLoader;

import React from "react";
import "./OnBoardingSidebar.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";
const OnBoardingSidebar = () => {
  const {businessData} = useAuthContext()
  return (
    <div className="OnBoardingSidebar-container">
      <div className="OnBoarding-logo">
        <div className="product-logo">
          <img src="/assets/Qevolt-logo-1.svg" alt="" />
          
        </div>
      </div>
      <div className="product-transactions">
        <div className="product-transactions-image">
          <img src="/assets/receipt-image.svg" alt="" />
        </div>
        <div className="product-transactions-text">
          Transactions verified
          <div className="product-transactions-count">72</div>
        </div>
      </div>

      <div className="Sidebar-text">
        You’re a few clicks away from accepting transfers from your customers.
      </div>
    </div>
  );
};

export default OnBoardingSidebar;

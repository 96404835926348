import axios from "axios"
import { useState } from "react"

export const QevoltPost = () => {

  const [form,setForm ] = useState({
    id: Math.random(),
    name: '',
    account: '',
    dateandtime: '',
    transactionid: '',
    amount: '',
    bank: ''
  })

  const {name,account,dateandtime,transactionid,amount,bank} = form
 
  const onChange =(e)=>{
    setForm((prev)=>({
      ...prev,[e.target.name]: e.target.value
    }))
  } 

  
  const url =' http://localhost:3000/accounts'

  const submit = async (e)=>{
    e.preventDefault()
    await axios.post(url,form)
    setForm({
      id: ` ${Math.random()}`,
      name: '',
      account: '',
      dateandtime: '',
      transactionid: '',
      amount: '',
      bank: ''
    })
  }

  return (
    <div>
      <form onSubmit={submit}>
        <input type="text" placeholder="name" value={name}  onChange={onChange} name="name"   />
        <input type="text" placeholder="account" value={account} onChange={onChange} name="account" />
        <input type="text" placeholder="date and time" value={dateandtime} onChange={onChange} name="dateandtime"   />
        <input type="text"  placeholder="transaction id" value={transactionid} onChange={onChange} name="transactionid"  />
        <input type="text" placeholder="amount"  onChange={onChange} value={amount} name="amount" />
        <input type="text" placeholder="bank" onChange={onChange}  value={bank} name="bank" />
        <button type="submit">submit</button>
      </form>
    </div>
  
  )
  }
export default QevoltPost
import { useState, useEffect, useRef } from "react";
import "./Payouts.css";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../../../Hooks/useAuthContext";
import axios from "axios";
import Success from "../../../../Success/Success";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import {QevoltData} from '../../../../QevoltData/QevoltData'

const Payouts = () => {
  const bank = useRef();
  const accNum = useRef();
  const { dispatch, token, businessData, secret_id, api } = useAuthContext();
  // console.log(businessData);
  const {livecode} =QevoltData()
  
  const [accNumber, setAccNumber] = useState('');
  const [num , setNum] = useState('')
  const [username , setUsername] = useState('') 
  const [checkPayouts, setCheckPayouts] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [bank_code , setBankCode] = useState('')
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [erase,setErase] = useState(false)
  const [arr, setArr] = useState([]);
  const [data, setData] = useState(null);
  const [erasePending, setErasePending] = useState(false)

  const onOptionClicked = (value , value2)  => () => {
      setBankN(value)
      setBankCode(value2)
      setShowBank(false)
      setCheckPayouts(true)
      setErase(true)
      
  }

    // GETTING ALL BUSINESSES
    const [businessPending, setBusinessPending] = useState(false);
    const [businessError, setBusinessError] = useState("");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
  
    const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");
  const getBusiness = async () => {
    setPending(true);
    setBusinessPending(true);
    console.log("GETTING BUINESSES")
    try {
      const businessresponse = await axios.get(
        api + "/main/all/business",
        config
      );

      if (businessresponse) {
        dispatch({
          type: "SECRET_ID",
          payload: businessresponse.data.results[0].outlet.secret_id,
        });

        dispatch({
          type: "BUSINESS_DATA",
          payload: businessresponse.data.results[0],
        });
        localStorage.setItem(
          "business_secret_id",
          businessresponse.data.results[0].secret_id
        );
        localStorage.setItem(
          "outlet_secret_id",
          businessresponse.data.results[0].outlet.secret_id
        );
        setOutletRreshSecretId(
          businessresponse.data.results[0].outlet.secret_id
        );
       reset()
       setErasePending(false)
       setCheckPayouts(false)
       setErase(false)
      }

     
    } catch (err) {

    }
  };

  


  const [accountPending , setAccountPending] = useState(false)
  const [accountError , setAccountError] = useState('')
  const [accountSuccess , setAccountSuccess] = useState('')
  const [account_number , setAccountNumber] = useState('')
  const [account_name , setAccountName] = useState('')
  const [updatePayoutSuccess, setUpdatePayoutSuccess] = useState("");
  const [updatePayoutError, setUpdatePayoutError] = useState("");

  const getAccountName = async ()=>{
    setAccountPending(true)
    setAccountError('')
    setAccountSuccess('')
 
    try{

    
    const form = {bank_code , account_number: accNumber}
    if(bank_code !== '' && accNumber !== ''){
    const accountName = await axios.post(api+'/main/verify/bank_account' , form , config)
   
    console.log(accountName)
    if(accountName){
      setUsername(accountName.data.Account_name)
      setAccountError(accountName.data.Error)
      setAccountSuccess(accountName.data.Success)
        setAccountPending(false)
        console.log(accountName)
    }
 
}
}
catch(err){
setAccountPending(false)
}
   
}



  const checkacc = () =>{
    livecode.map((val)=>
       val.bankCode === businessData.bank ?
        setBankN(val.bankName): null
      )
  }

  const reset = () =>{
    setAccNumber(businessData.account_number )
    setUsername(businessData.account_name)
    livecode.map((val)=>
       val.bankCode === businessData.bank ?
        setBankN(val.bankName): null
      )
    setBusinessPending(false)
    }

  const [showBank , setShowBank] = useState(false)
  const [bankN , setBankN] = useState('')
  const toggleBank = () =>{
      setShowBank(!showBank)
  }




  useEffect(()=>{
    checkacc()
    reset()
  },[])
  const filled = () => {
    if (accNum.current.value.length === 10) {
      setCheckPayouts(true);
    } else {
      setCheckPayouts(false);
    }
  };


  // UPDATE PAYOUTS

 

  const updatePayouts = async () =>{
    const business_secret_id = localStorage.getItem('business_secret_id');

    const form = {bank_code , account_number: accNumber , account_name: username , business_secret_id}
    console.log(form)
    try{
      const response = await axios.post(`${api}/main/update/business`, form , config)
    if(response){
      setSaveChanges(false)
      setUpdatePayoutError(response.data.Error)
      setAccountSuccess(response.data.Success);

    }
    
    console.log(response)
    }catch(err){
      setSaveChanges(false)
      setAccountError("Something Went Wrong");
    }

    
    
  } 





  // const showChangesLoader = () => {
  //   setSaveChanges(true);
  //   setTimeout(() => {
  //     setSaveChanges(false);
  //   }, 3000);
  // };


  

  return (
    <Fade duration={250}>
      <div className="setting-payouts-container">
        {accountSuccess !== "" && !updatePayoutError && (
          <Success message={accountSuccess}/>
        )}
        {updatePayoutError !== '' && !accountSuccess && (
          <ErrorMessage message={updatePayoutError}/>
        )}

        <div className="settings-payouts-warning">
          <p>
            For us to be able to confirm your account, the name on your bank
            account must match the name you provided as the owner of your
            business.
          </p>
        </div>
        <form action="" className="setting-payouts-form">
          {/* <label>
            <div className="label">Bank</div>

            
           <input type="text"  className="settingsInputBox" value={accNumber}   />
            
          </label> */}
          <div className="form-group payouts-form-group">
              <label className="top-label">Bank</label>
              <img
                src="/assets/dropb.svg"
                alt="sv"
                className="label-img"
                onClick={() => toggleBank()}
              />
              <input
                type="text"
                className = "payouts-drop"
                value={bankN}
                name="bankN"
                onChange={(e) =>{ setBankN(e.target.value) ; filled();}}
               required
                onClick={() => toggleBank()}
                onKeyPress={(e) => e.preventDefault()}
                style={{
                  color: "transparent",
                  textShadow: "0 0 0 black",
                  cursor: "pointer",
                }}
              />
              {showBank && (
                <ul>
                  {livecode.map((val, idx) => (
                    <li
                      key={idx}
                      onClick={onOptionClicked(val.bankName, val.bankCode)}
                      style={{ cursor: "pointer" }}
                    >
                      {val.bankName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="accNum-container">

            <div className="accNum-input-area">
             <label>
          


          <div className="label">Account Number</div>
          
          <input
            className="settingsInputBox"
            type="number"
            name="account_number"
            ref={accNum}
            value={accNumber}
            onChange={(e) => {
              setAccNumber(e.target.value);
              filled();
              setErase(true)
              
            }}

            onKeyUp={() => {
        
           
                accNumber.length === 10
                ? getAccountName()
                : console.log("")
              }}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
            }}
            maxlength={10}
            // onKeyDown = {()=> filled()}
          />
          
        </label> 
            </div>
            <div className="accNum-loader">
              
              {accountPending && <div className="getAccountLoader"><img src="/assets/roller.svg" style={{width: '30px'}} className='gettingAcc' /></div>}
   {!accountPending && <></>}
            </div>
            </div>
          
          
          

          <label>
            <div className="label">Account Name</div>
            <input
              className="settingsInputBox"
              type="text"
              name="fullName"
              value={username}
              style={{
                color: "transparent",
                textShadow: "0 0 0 black",
                cursor: "not-allowed",
            
              }}
              disabled
              // onChange={(e)=>setFullNamee(e.target.value)}
              // onKeyDown = {()=> filled()}
            />
          </label>
        </form>
        <div className="payouts-buttons">
         {!saveChanges && (
          <button
            className="payouts-save-button"
            style={{
              backgroundColor: checkPayouts && !accountPending && !accountError && username ? "#8F00FF " : "#f8f8f8",
              color: checkPayouts && !accountPending && !accountError && username ? "white" : "#757575",
              pointerEvents: checkPayouts && !accountPending && !accountError && username ? "all" : "none",
              cursor: checkPayouts && !accountPending && !accountError && username  ? "pointer" : "not-allowed",
            }}
            onClick={() => {updatePayouts(); setSaveChanges(true); setErase(false)}}
          >
            Save Changes
          </button>
          
        )} 
        {saveChanges && (
          <button className="profile-loading-save-button">
            <img src="/assets/spinload.svg" alt="" />
          </button>
        )}
        {erase && !erasePending && (
           <button
          className = "erase-btn"
          onClick = {() => {getBusiness(); setErasePending(true); setCheckPayouts(false) }}>
          Erase Changes
        </button>
        )}
        {erasePending && (
           <button
          className = "erase-btn-loading">
          <img src="/assets/spinload.svg" alt="" />
        </button>
        )}
        </div>
        
        

        
        


        
      </div>
    </Fade>
  );
};

export default Payouts;

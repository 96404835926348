import { useState, useEffect, useRef } from "react";
import "./profile.css";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../../../Hooks/useAuthContext";

const Profile = () => {
  const { businessData } = useAuthContext();

  const name = useRef();
 
  const email = useRef();


  const [emailError, setEmailError] = useState(false);



  const fullNamee = `${ businessData && businessData.created_by.fullname}`
  const [eemail, setEemail] = useState(`${ businessData && businessData.created_by.email}`);




  const [checkProfile, setCheckProfile] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);

  const filled = () => {
    if (fullNamee !== "" && eemail !== "" && emailError !== false) {
      setCheckProfile(true);
    } else {
      setCheckProfile(false);
    }
  };

  const showChangesLoader = () => {
    setSaveChanges(true);
    setTimeout(() => {
      setSaveChanges(false);
    }, 3000);
  };

  // const filled = () => {
  //   if (
  //     name.current.value !== "" &&
  //     email.current.value !== "" &&
  //     password.current.value !== "" &&
  //     !emailError &&
  //     !passwordError
  //   ) {
  //     setCheckSignUp(true);
  //   } else {
  //     setCheckSignUp(false);
  //   }
  // };

  const emailRegEx =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emailKeyUp = () => {
    if (emailRegEx.test(email.current.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    // if (email.current.value === "") {
    //   setEmailError(false);
    // }
  };

  // const emailChange = (e) => {
  //   setEemail(e.target.value);
  //   emailKeyUp();
  //   filled();
  // };

  useEffect(() => {});

  return (
    <Fade duration={250}>
      <div className="setting-profile-container">
        <form className="setting-profile-form" action="">
          <label>
            <div className="label">Full Name</div>
            <input
              className="settingsInputBox"
              type="text"
              name="fullName"
              ref={name}
              style={{
                color: "transparent",
                textShadow: "0 0 0 black",
                cursor: "not-allowed",
            
              }}
              disabled
              value={fullNamee}
              // onChange={(e) => setFullNamee(e.target.value)}
              onKeyDown={() => filled()}
            />
          </label>
          <label>
            <div className="label">Email</div>
            <input
              className="settingsInputBox"
              type="text"
              name="email"
              value={eemail}
              ref={email}
              // onChange={(e) => {
              //   setEemail(e.target.value);
              //   filled();
              //   emailKeyUp();
              // }}
              onChange={(e)=>setEemail(e.target.value)}

              onKeyUp={emailKeyUp}
              onKeyDown={filled}

            />
          </label>
          {emailError && (
            <p className="emailError settingEmailError">
              Please enter a valid email address
            </p>
          )}
        </form>
        {!saveChanges && (
          <button
            className="profile-save-button"
            style={{
              backgroundColor: checkProfile ? "#8F00FF " : "#f8f8f8",
              color: checkProfile ? "white" : "#757575",
              pointerEvents: checkProfile ? "all" : "none",
              cursor: checkProfile ? "pointer" : "not-allowed",
            }}
            onClick={() => showChangesLoader()}
          >
            Save Changes
          </button>
        )}
        {saveChanges && (
          <button className="profile-loading-save-button">
            <img src="/assets/settingsLoader.svg" alt="" />
          </button>
        )}
      </div>
    </Fade>
  );
};

export default Profile;



export const QevoltData = () =>{

     const livecode = [
        {bankCode:"090267",bankName:"Kuda."},
        {bankCode:"000014",bankName:"Access Bank"},
        {bankCode:"000005",bankName:"Access Bank PLC (Diamond)"},
        {bankCode:"090134",bankName:"ACCION MFB"},
        {bankCode:"090188",bankName:"Baines Credit MFB"},
        {bankCode:"090148",bankName:"Bowen MFB"},
        {bankCode:"100026",bankName:"Carbon"},
        {bankCode:"100005",bankName:"Cellulant"},
        {bankCode:"000009",bankName:"Citi Bank"},
        {bankCode:"100032",bankName:"Contec Global"},
        {bankCode:"060001",bankName:"Coronation"},
        {bankCode:"090156",bankName:"e-BARCs MFB"},
        {bankCode:"000010",bankName:"Ecobank Bank"},
        {bankCode:"100008",bankName:"Ecobank Xpress Account"},
        {bankCode:"090097",bankName:"Ekondo MFB"},
        {bankCode:"000003",bankName:"FCMB"},
        {bankCode:"000007",bankName:"Fidelity Bank"},
        {bankCode:"000016",bankName:"First Bank of Nigeria"},
        {bankCode:"110002",bankName:"Flutterwave Technology solutions Limited"},
        {bankCode:"100022",bankName:"GoMoney"},
        {bankCode:"000013",bankName:"GTBank Plc"},
        {bankCode:"000020",bankName:"Heritage"},
        {bankCode:"090175",bankName:"HighStreet MFB"},
        {bankCode:"000006",bankName:"JAIZ Bank"},
        {bankCode:"090003",bankName:"JubileeLife"},
        {bankCode:"090191",bankName:"KCMB MFB"},
        {bankCode:"000002",bankName:"Keystone Bank"},
        {bankCode:"090171",bankName:"Mainstreet MFB"},
        {bankCode:"100002",bankName:"Paga"},
        {bankCode:"100033",bankName:"PALMPAY"},
        {bankCode:"100003",bankName:"Parkway-ReadyCash"},
        {bankCode:"110001",bankName:"PayAttitude Online"},
        {bankCode:"100004",bankName:"Paycom(OPay)"},
        {bankCode:"000008",bankName:"POLARIS BANK"},
        {bankCode:"000023",bankName:"Providus Bank "},
        {bankCode:"000024",bankName:"Rand Merchant Bank"},
        {bankCode:"000012",bankName:"StanbicIBTC Bank"},
        {bankCode:"100007",bankName:"StanbicMobileMoney"},
        {bankCode:"000021",bankName:"StandardChartered"},
        {bankCode:"000001",bankName:"Sterling Bank"},
        {bankCode:"000022",bankName:"SUNTRUST BANK"},
        {bankCode:"000018",bankName:"Union Bank"},
        {bankCode:"000004",bankName:"United Bank for Africa"},
        {bankCode:"000011",bankName:"Unity Bank"},
        {bankCode:"090110",bankName:"VFD MFB"},
        {bankCode:"000017",bankName:"Wema Bank"},
        {bankCode:"000015",bankName:"ZENITH BANK PLC"},
        {bankCode:"100025",bankName:"Zinternet - KongaPay"}
        
    
      ]


    const testCodes = [
        {bankcode:"999129",bankname:"Kudimoney(Kudabank)"},
        {bankcode:"999044",bankname:"Access Bank"},
        {bankcode:"999001",bankname:"ADH"},
        {bankcode:"999104",bankname:"BOSAK"},
        {bankcode:"999023",bankname:"Citi Bank"},
        {bankcode:"999052",bankname:"Covenant MFB"},
        {bankcode:"999063",bankname:"DIAMOND BANK"},
        {bankcode:"999050",bankname:"ECOBANK"},
        {bankcode:"999214",bankname:"FCMB"},
        {bankcode:"999003",bankname:"FETS"},
        {bankcode:"999070",bankname:"Fidelity"},
        {bankcode:"999011",bankname:"First Bank"},
        {bankcode:"999058",bankname:"GTBank"},
        {bankcode:"999301",bankname:"JAIZ"},
        {bankcode:"999082",bankname:"Keystone Bank"},
        {bankcode:"999107",bankname:"Mutual Benefits"},
        {bankcode:"999999",bankname:"NIBSS"},
        {bankcode:"999105",bankname:"NOVA"},
        {bankcode:"999078",bankname:"NOW NOW"},
        {bankcode:"999002",bankname:"NPF"},
        {bankcode:"999009",bankname:"PagaTech"},
        {bankcode:"999015",bankname:"Parallex MFB"},
        {bankcode:"999076",bankname:"Skye Bank"},
        {bankcode:"999221",bankname:"Stanbic Ibtc"},
        {bankcode:"999232",bankname:"Sterling Bank"},
        {bankcode:"999004",bankname:"Teasy"},
        {bankcode:"999018",bankname:"Trustbond"},
        {bankcode:"999033",bankname:"UBA"},
        {bankcode:"999215",bankname:"UNITY BANK"},
        {bankcode:"999116",bankname:"VFD MFB"},
        {bankcode:"999035",bankname:"Wema Bank"},
        {bankcode:"999057",bankname:"Zenith Bank"}

    ]

    return {livecode , testCodes}

}
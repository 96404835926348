import React from "react";
import "./logoutModal.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LogoutModal = () => {
  const { dispatch, api, token } = useAuthContext();
  const navigate = useNavigate();
  const logoutUrl = api + "/accounts/auth/logout/";
  const logout = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: "BUSINESS_DATA", payload: null });
      const response = await axios.post(logoutUrl, config);
  
      if (response) {
        localStorage.removeItem("user_token");
        localStorage.removeItem("verified");
        localStorage.removeItem("business");
        localStorage.removeItem("business_secret_id");
        navigate("/account");
      }
    } catch (err) {
     
    }
  };

  const removeOutModal = () => {
    dispatch({ type: "LOGOUT_MODAL", payload: false });
  };

  return (
    <div className="logoutModal-container">
      <div className="logoutModal-header">Logout</div>
      <div className="logoutModal-body">
        <div className="logoutModal-question">
          Are you sure you want to logout?
        </div>
        <div className="logoutModal-buttons">
          <button className="logoutModal-cancel" onClick={removeOutModal}>
            <p>Cancel</p>
          </button>
          <button
            className="logoutModal-logout"
            onClick={() => {
              logout();
              removeOutModal();
            }}
          >
            <p>Logout</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;

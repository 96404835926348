import { AuthContext } from "../components/Context/AuthContext";
import { useContext } from "react";

export const useAuthContext = () =>{
    const context = useContext(AuthContext)

    if(!context){
        throw new Error('use context can only be used around wrapped components')
    }

    return context
}
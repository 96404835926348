import "./App.css";
import { Dashboard, OnBoarding } from "./pages/index";
import { BrowserRouter as Router, useSearchParams } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  Forgot,
  OTP,
  Reset,
  Login,
  SignUp,
} from "./components/onBoarding-components";
import {
  Home,
  Transactions,
  Payouts,
  Organisation,
  Settings,
} from "./components/Dashboard-components";
import QevoltPost from "./QevoltPost";
import { useAuthContext } from "./Hooks/useAuthContext";
import { useEffect, useState } from "react";
import OrganisationId from "./components/Dashboard-components/OrganisationId/OrganisationId";
import Cookies from "js-cookie";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { RingProgress } from "@mantine/core";
import Orgatest from "./components/OrgaTest/Orgatest";
import Invite from "./components/onBoarding-components/Invite/Invite";
import Success from "./components/Success/Success";
import { Fade } from "react-reveal";
import { useTransitionValue } from "react-transition-value";
import { images } from "./images";
import Hamburger from "./components/Dashboard-components/Hamburger/Hamburger";
export function App() {
  const [imageCache, setImageCache] = useState([]);
  console.log()
  useEffect(() => {
    const cacheImages = async () => {
    
      const cache = await Promise.all(
        images.map((image) => {
          return new Promise((resolve) => {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", image, true);
            xhr.responseType = "blob";
            xhr.onload = () => {
              const url = URL.createObjectURL(xhr.response);
              resolve(url);
            };
            xhr.send();
          });
        })
      );
      setImageCache(cache);
    };
    cacheImages()    
  }, []);

  console.log(imageCache);

  const { email, token, dispatch } = useAuthContext();

  const b = localStorage.getItem("business");
  const v = localStorage.getItem("verified");
  const t = localStorage.getItem("user_token");

  useEffect(() => {
    dispatch({ type: "LOGIN", payload: localStorage.getItem("user_token") });
  }, [dispatch]);

  const [loading, setLoading] = useState(true);

  const role = localStorage.getItem("role");

  const container = document.querySelector(".center");

  const [preLoader, setPreloader] = useState(true);
  const [cln, setCln] = useState("preLoader heartbeat");

  const [screen1, setScreen1] = useState(true);
  const [screen2, setScreen2] = useState(false);
  const [screen3, setScreen3] = useState(false);
  const [screen4, setScreen4] = useState(false);
  const [dot1, setDot1] = useState("control-dot active-dot");
  const [dot2, setDot2] = useState("control-dot ");
  const [dot3, setDot3] = useState("control-dot ");
  const [dot4, setDot4] = useState("control-dot ");
  const [newUser, setNewUser] = useState(true);
  const [newScreen, setNewScreen] = useState("new-user-screen");
  const [circleValue, setCircleValue] = useState(30);
  const [transitionValue, setTransitionValue] = useTransitionValue(30);

  useEffect(() => {
    setTimeout(() => {
      setCln("preLoader puff-out-center");
    }, 2100);

    setTimeout(() => {
      setPreloader(false);
    }, 2350);
  }, []);

  // const [pageUrl, setPageUrl] = useSearchParams();
  useEffect(() => {
    if (localStorage.getItem("screenshow")) {
      localStorage.setItem("screenshow", "true");
      if (localStorage.getItem("screenshow") == "true") {
        setNewUser(false);
      } else {
        setNewUser(true);
      }
    } else {
      localStorage.setItem("screenshow", "false");
    }
  }, []);

  const nextNewUserInfo = () => {
    if (screen1) {
      setScreen1(false);
      setScreen2(true);
      setDot1("control-dot");
      setDot2("control-dot active-dot");
      setCircleValue(50);
      setTransitionValue(50);
    }
    if (screen2) {
      setScreen2(false);
      setScreen3(true);
      setDot2("control-dot");
      setDot3("control-dot active-dot");
      setCircleValue(80);
      setTransitionValue(80);
    }
    if (screen3) {
      setScreen3(false);
      setScreen4(true);
      setDot3("control-dot");
      setDot4("control-dot active-dot");
      setCircleValue(100);
      setTransitionValue(100);
    }
    if (screen4) {
      setScreen3(false);
      setScreen1(false);

      localStorage.setItem("screenshow", "true");
      // setNewScreen(false)
      setNewScreen("new-user-screen-fade-out");
      setNewUser(false);
    }
  };

  const skipIntro = () => {
    localStorage.setItem("screenshow", "true");
    setNewUser(false);
  };

  if (container) {
    setTimeout(() => {
      container.style.display = "none";
      setLoading(false);
    }, 3500);
  }

  let params = new URLSearchParams(window.location.search);

  const arrayPath = window.location.pathname
    .split("/")
    .filter((arr) => arr !== "");

  useEffect(() => {
    window.innerWidth > 975 && arrayPath[1] === "resetpassword"
      ? window.location.replace(
          `https://app.qevolt.com/account/resetpassword?uid=${params.get(
            "uid"
          )}&token=${params.get("token")}`
        )
      : window.innerWidth > 975 && arrayPath[1] === "invite"
      ? window.location.replace(
          `https://app.qevolt.com/account/invite?invite=${params.get(
            "invite"
          )}&rid=${params.get("rid")}&email=${params.get("email")}`
        )
      : window.innerWidth > 975
      ? window.location.replace("https://app.qevolt.com")
      : console.log("");
  }, []);

  // if(!newScreen){
  //     container.style.display = "none"

  // }
  return (
    <>
      {preLoader && (
        <div className="preloaderContainer">
          <div className={cln}>
            <img src="/assets/qevolt-solo.svg" alt="" />
          </div>
        </div>
      )}

      {!preLoader && (
        <div>
          {newUser && (
            <div className={newScreen}>
              <div className="new-user-screen-skip-btn" onClick={skipIntro}>
                Skip
              </div>
              <div className="new-user-screen-gif">
                {screen1 && (
                  <>
                    <img src="./assets/on i.gif" alt="" />
                  </>
                )}
                {screen2 && (
                  <>
                    <img src="./assets/on ii.gif" alt="" />
                  </>
                )}
                {screen3 && (
                  <>
                    <img src="./assets/on iii.gif" alt="" />
                  </>
                )}
                {screen4 && (
                  <>
                    <img src="./assets/on iv.gif" alt="" />
                  </>
                )}
              </div>

              <div className="new-user-screen-info">
                <div className="new-user-screen-info-text">
                  <div className="new-user-screen-info-mainText">
                    {screen1 && (
                      <Fade duration={400}>
                        The best way to accept bank transfers
                      </Fade>
                    )}
                    {screen2 && <Fade duration={400}>Safety</Fade>}
                    {screen3 && <Fade duration={400}>Organisation</Fade>}
                    {screen4 && <Fade duration={400}>Instant Settlement</Fade>}
                  </div>
                  <div className="new-user-screen-info-subText">
                    {screen1 && (
                      <Fade duration={400}>
                        Qevolt helps your business verify bank transfers in less
                        than a minute
                      </Fade>
                    )}
                    {screen2 && (
                      <Fade duration={400}>
                        Avoid fraudulent or reversed transactions from customers
                        with your Qevolt account
                      </Fade>
                    )}
                    {screen3 && (
                      <Fade duration={400}>
                        Create outlets, assign managers and cashiers to control
                        outlets you own
                      </Fade>
                    )}
                    {screen4 && (
                      <Fade duration={400}>
                        Your preferred account is settled immediately funds
                        reflect on Qevolt
                      </Fade>
                    )}
                  </div>

                  <div className="new-user-screen-info-controls">
                    <div className="new-user-screen-dots">
                      <div className={dot1}></div>
                      <div className={dot2}></div>
                      <div className={dot3}></div>
                      <div className={dot4}></div>
                    </div>

                    <RingProgress
                      size={70}
                      thickness={1.5}
                      label={
                        <div
                          className="new-user-screen-next-btn"
                          onClick={nextNewUserInfo}
                        >
                          <ChevronRightRoundedIcon
                            className="nexticon"
                            sx={{ fontSize: "1.8rem" }}
                          />
                        </div>
                      }
                      sections={[
                        { value: transitionValue, color: "#8F00FF" },

                        // screen2 && ({ value: 50, color: '#8F00FF' })
                        // screen3 && ({ value: 80, color: '#8F00FF' })
                        // screen4 && ({ value: 100, color: '#8F00FF' })

                        // { value: 15, color: '#8F00FF' },
                        // { value: 45, color: '#8F00FF' },
                        // { value: 45, color: '#8F00FF' }
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <Router>
           
            <Routes>
              <Route path="/post" element={<QevoltPost />} />
              <Route path="*" element={<Navigate to="/account" />} />

              <Route path="/f" element={<OnBoarding />} />
              <Route path="/account" element={<OnBoarding />}>
                <Route path="/account" element={<Login />} />
                <Route path="/account/signup" element={<SignUp />} />
                <Route path="/account/resetpassword" element={<Reset />} />
                <Route
                  path="/account/loginotp"
                  element={email ? <OTP /> : <Navigate to="/account" />}
                />
                {/* <Route path='/account/verifyemail' element={<VerifyEmail />} /> */}
                <Route path="/account/forgotpassword" element={<Forgot />} />
                <Route path="/account/invite" element={<Invite />} />
                <Route path="/account/*" element={<Navigate to="/account" />} />
              </Route>
              <Route
                path="/organisation"
                element={
                  t && v === "true" && b === "false" ? (
                    <Orgatest />
                  ) : (
                    <Navigate to="/account" />
                  )
                }
              />
              <Route
                exact
                path="/dashboard"
                element={t ? <Dashboard /> : <Navigate to="/account" />}
              >
                <Route path="/dashboard/home" element={<Home />} />
                <Route
                  path="/dashboard/transactions"
                  element={<Transactions />}
                />
                {role === "BOSS" && (
                  <Route path="/dashboard/payouts" element={<Payouts />} />
                )}
                {role === "MAGR" && (
                  <Route path="/dashboard/payouts" element={<Payouts />} />
                )}
                {role === "BOSS" && (
                  <Route
                    path="/dashboard/organisation"
                    element={<Organisation />}
                  />
                )}
                {role === "MAGR" && (
                  <Route
                    path="/dashboard/organisation"
                    element={<Organisation />}
                  />
                )}
                {role === "BOSS" && (
                  <Route
                    path="/dashboard/organisation/:id"
                    element={<OrganisationId />}
                  />
                )}
                {role === "MAGR" && (
                  <Route
                    path="/dashboard/organisation/:id"
                    element={<OrganisationId />}
                  />
                )}

                {/* <Route  path='/dashboard/refunds' element={<Refunds />} /> */}
                <Route
                  path="/dashboard/settings"
                  element={<Settings images={imageCache} />}
                />
                <Route
                  path="/dashboard"
                  element={
                    token ? (
                      <Navigate to="/dashboard/home" />
                    ) : (
                      <Navigate to="/account" />
                    )
                  }
                />
                <Route
                  path="/dashboard/*"
                  element={
                    token ? (
                      <Navigate to="/dashboard/home" />
                    ) : (
                      <Navigate to="/account" />
                    )
                  }
                />
              </Route>

              <Route path="/success" element={<Success />} />
            </Routes>
          </Router>
        </div>
      )}
    </>
  );
}

import React from "react";

import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, NavLink, useSearchParams } from "react-router-dom";
import { Fade } from "react-reveal";
import axios from "axios";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import WhiteLoader from "../../Loaders/WhiteLoader";
const Invite = () => {
  const [pageUrl, setPageUrl] = useSearchParams();
  pageUrl.get("invite");

  console.log(pageUrl.get("invite"));

  const { dispatch, username, api } = useAuthContext();
  const [firstName,setFirstName] = useState('')
  const [lastName,setLastName] = useState('')
  //THE "SHOW" BUTTON
  const togglePass = () => {
    setPass(!pass);
    if (!pass) {
      setPassType("password");
      setShow("Show");
    }
    if (pass) {
      setPassType("text");
      setShow("Hide");
    }
  };
  const toggleConfirmPass = () => {
    setConfirmPass(!confirmPass);
    if (!confirmPass) {
      setConfirmPassType("password");
      setConfirmShow("Show");
    }
    if (confirmPass) {
      setConfirmPassType("text");
      setConfirmShow("Hide");
    }
  };

  //STATES
  const [pass, setPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [passType, setPassType] = useState("password");
  const [confirmPassType, setConfirmPassType] = useState("password");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [checkSignUp, setCheckSignUp] = useState(false);
  const [show, setShow] = useState("Show");
  const [confirmShow, setConfirmShow] = useState("Show");

  //FORM VALUES
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState(pageUrl.get("email"));
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [referralCode, setReferralCode] = useState('');
  const referral = useRef();
  //SIGN UP FORM SUBMIT
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");
  const form = {
    fullname: firstName + " " + lastName,
    email,
    password,
    password2,
    invite: pageUrl.get("invite") === "true" ? true : true,
    full_name: firstName,
    last_name: lastName,
  };
  const url = api + "/accounts/register";

  const submitSignup = async (e) => {
    e.preventDefault();
    setPending(true);
    try {
      const response = await axios.post(url, form);

      if (Object.keys(response.data).length > 1) {
        dispatch({ type: "SIGNUP", payload: response.data.fullname });
        dispatch({ type: "SIGNUP_EMAIL", payload: response.data.email });

        console.log(response.data);

        const loginUser = await axios.post(api + "/accounts/auth/login/", {
          username: email,
          password,
        });

        if (loginUser) {
          dispatch({
            type: "SIGNUP_EMAIL",
            payload: loginUser.data.user.email,
          });
        }

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${loginUser.data.access_token}`,
          },
        };

        const acceptInvite = await axios.get(
          `${api}/main/accept/invite?roleid=${pageUrl.get(
            "rid"
          )}&email=${pageUrl.get("email")}`,
          config
        );

        const verified = await axios.get(
          `${api}/accounts/check_user/${loginUser.data.user.email}`
        );
        localStorage.setItem("verified", verified.data.email_verified);
        localStorage.setItem("business", verified.data.business);
        dispatch({
          type: "VERIFIED",
          payload: localStorage.getItem("verified"),
        });
        dispatch({
          type: "BUSINESS",
          payload: localStorage.getItem("business"),
        });

        if (
          verified.data.email_verified === true &&
          verified.data.business === true
        ) {
          setPending(false);
          localStorage.setItem("user_token", loginUser.data.access_token);
          const user = localStorage.getItem("user_token");
          dispatch({ type: "LOGIN", payload: user });
          navigate("/dashboard");
        }
      } else if (Object.keys(response.data).length === 1) {
        setError(response.data.email.join(""));
        console.log(error);
        setPending(false);
      }
    } catch (err) {
      if (err.code === 'ERR_NETWORK') {
        setError('Internet connection lost or disconnected');
        setPending(false)
      }  
      else if (err.code === 'ECONNABORTED') {
        setError('Request timeout , check internet connection');
        setPending(false)
      } else {
        console.error(err);
        setPending(false)
      }
    }
  };

  //CHECKING FOR EMPTY INPUTS
  const filled = () => {
    if (
      name.current.value !== "" &&
      emaill.current.value !== "" &&
      passwordd.current.value !== "" &&
      confirmPassword.current.value !== "" &&
      !emailError &&
      !passwordError &&
      passwordd.current.value === confirmPassword.current.value
    ) {
      setCheckSignUp(true);
    } else {
      setCheckSignUp(false);
    }
  };

  //FUNCTION TO HANDLE SIGNUP BUTTON CLICK
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    navigate("/account/loginotp");
    console.log("signup");
  };

  useEffect(() => {
    filled();
  }, []);

  //VALIDATION

  const name = useRef();
  const emaill = useRef();
  const passwordd = useRef();
  const confirmPassword = useRef();

  const emailRegEx =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emailKeyUp = () => {
    if (emailRegEx.test(emaill.current.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    // if (email.current.value === "") {
    //   setEmailError(false);
    // }
  };

  const passwordKeyUp = () => {
    if (passwordd.current.value.length < 8) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (passwordd.current.value === "") {
      setPasswordError(false);
    }
  };
  const confirmPasswordKeyUp = () => {
    if (confirmPassword.current.value === passwordd.current.value) {
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    }

    if (confirmPassword.current.value === "") {
      setConfirmPasswordError(false);
    }
  };

  return (
    <>
    <Fade duration={250}>
      <div className="SignUp-container">


      { error !== "" && error && (
      <ErrorMessage message={error} />
    )}

        <div className="static-app-top">
          <div className="static-app-textarea-top">
            <div className="static-app-maintext">Join Qevolt</div>
            <div className="static-app-subtext">Create an account</div>
          </div>
          
        </div>

          
              {/* <div className="SignUp-main-text">Create an account</div>
        <div className="SignUp-subtext">Let’s get you started</div> */}


      

      
       
        <form className="SignUp-form" onSubmit={submitSignup}>

          <label>
            <div className="name-label">First name</div>
            <input
              value={firstName}
              className="inputboxSignup"
              type="text"
              name="firstName"
              placeholder="John"
              ref={name}
              onKeyUp={filled}
              onChange={(e)=>setFirstName(e.target.value)}   required
            />
          </label>
          <label>
            <div className="name-label">Last name</div>
            <input
              value={lastName}
              className="inputboxSignup"
              type="text"
              name="lastName"
              placeholder="Doe"
              ref={name}
              onKeyUp={filled}
              onChange={(e)=>setLastName(e.target.value)}   required
            />
          </label>
          <label>
            <div className="label-sign">
              <div className="label-sign-left">
                Email address
              </div>
              <div className="label-sign-right">
                      {emailError && (
                    <p className="app-passError">Please enter a valid email address</p>
                  )}
              </div>
              
              </div>
            <input
            value={email}
              className="inputboxSignup"
              type="email"
              name="email"
              placeholder="johndoe@mail.com"
              ref={emaill}
              onKeyUp={() => {
                emailKeyUp();
                filled();
              }}
              disabled
              style={{
                background: emailError ? "#FFF1F1" : "", borderColor: emailError ? "#FF3737" : ""
              }}  required
            />
          </label>
          
          <div className="SignUp-password-area">
            <label>
              <div className="label-sign">
                <div className="label-sign-left">
                  Password
                </div>
                <div className="label-sign-right">
                  {passwordError && (
                  <p className="app-passError">
                    Password must be min. of 8 characters
                  </p>
                )}
                </div>
                
                <div className="ShowPass-sign" onClick={togglePass}>
                  {show}
                </div>
              </div>
              <input
              value={password}
                className="passwordBox"
                type={passType}
                name="password"
                placeholder="Min. of 8 characters"
                ref={passwordd}
                onKeyUp={() => {
                  passwordKeyUp();
                  confirmPasswordKeyUp();
                  filled();
                }}
                onChange={(e)=>setPassword(e.target.value)}
                style={{
                  background: passwordError ? "#FFF1F1" : "", borderColor: passwordError ? "#FF3737" : ""
                }}  required
              />
              
            </label>
            <label>
              <div className="label-sign">
                <div className="label-sign-left">
                  Confirm Password
                </div>
                <div className="label-sign-right">
                    {confirmPasswordError && (
                    <p className="app-passError">Password does not match</p>
                  )}
                </div>
                
                
                {/* <div className="ShowPass-sign" onClick={toggleConfirmPass}>
                  {confirmShow}
                </div> */}
              </div>
              <input
                value={password2}
                className="passwordBox"
                type={confirmPassType}
                name="password2"
                placeholder="Min. of 8 characters"
                ref={confirmPassword}
                onKeyUp={() => {
                  passwordKeyUp();
                  confirmPasswordKeyUp();
                  filled();
                }}
                onChange={(e)=>setPassword2(e.target.value)}
                style={{
                  background: confirmPasswordError ? "#FFF1F1" : "", borderColor: confirmPasswordError ? "#FF3737" : ""
                }}  required
              />
              
            </label>
            <label>
            <div className="name-label">Referral code  <span className = "greyed-out">{" "}(optional)</span></div>
            <input
              value={referralCode}
              className="inputboxSignup last-onb-input"
              type="text"
              name="referralCode"
              placeholder=""
              ref={referral}
              // onKeyUp={filled}
              onChange={(e)=>setReferralCode(e.target.value)}   
              // required
            />
          </label>
            
          </div>
       
          <div className="policy">
            By registering, you agree to our{" "}
            <a href="/account" target="_blank" className="purple">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/account" target="_blank" className="purple">
              Privacy Policy
            </a>
          </div>



          <div className="static-app-bottom">
            <div className="static-app-bottom-container">
             {!pending && checkSignUp  && <button
            type="submit"
            value="Next"
            className="SignUp-button"
            style={{
              backgroundColor: "#8F00FF",
              color: "#fff",
              cursor: "pointer",
            
              cursor:  "pointer",
            }}
          
         
          >
            Sign up
          </button> }
          {!pending && !checkSignUp  && <button
            type="submit"
            value="Next"
            className="SignUp-button"
            style={{
              backgroundColor: "#D9D9D9",
              color:  "#FFF",
              cursor: "default",
             
              cursor:  "not-allowed"
            }}
           disabled ='true'
         
          >
            Sign up
          </button> }



          {pending && <button
            type="submit"
            value="Next"
            className="SignUp-button"
            style={{
              backgroundColor:  "#8F00FF" ,
              color:  "#fff" ,
              cursor: "pointer",
              pointerEvents:  "none" ,
              cursor:  "pointer" 
            }}
         
          >
           <WhiteLoader />
          </button> }

          <div className="app-signup-bottomtext">
            <div className="app-signup-tapl">
              By registering, you agree to our <a href ="https://qevolt.com/terms" target = "_blank" className = "app-purple">Terms of Use</a> and <a href = "https://qevolt.com/policy" target = "_blank" className = "app-purple">Privacy Policy</a>
            </div>
            <div className="app-signup-toLogin">
              Have an account? <Link to="/account" className="app-purple">
            Login
          </Link>
            </div>
          </div> 

          </div>

          
          </div>
            

          
        </form>

        <div className="SignUpToLogin">
          Have an account?
          <Link to="/account" className="purple">
            Login
          </Link>
        </div>
      </div>
    </Fade>
  </>
  );
};

export default Invite;

import {useState} from 'react'
import { useAuthContext } from '../../../Hooks/useAuthContext';
import axios from 'axios';

const DeleteMemberModal = () => {

    const [data, setData] = useState(null);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const outlet_id = localStorage.getItem("outlet_secret_id");

    const { dispatch, api, token } = useAuthContext();

    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };


      const getData = async () => {
        setPending(true);
        try {
          const response = await axios.get(
            `${api}/main/view/roles/${outlet_id}`,
            config
          );
          if (response) {
            setPending(false);
            setError(null);
            setData(response.data.results);
            console.log(data)
          }
        } catch (err) {
          setPending(false);
          setError(err.message);
        }
      };


    const deleteRoles = async (role_secret_id)=>{
        const outlet_secret_id = localStorage.getItem('outlet_secret_id')
    
        const form = {outlet_secret_id , role_secret_id}
        const response = await axios.post(`${api}/main/delete/roles` , form , config)
        console.log(response) 
        if(response){
          getData()
        }
      }



  return (
    <div className="delete-modal-container">
       <div className="delete-modal-header">
        Delete role
       </div>
    </div>
  )
}

export default DeleteMemberModal
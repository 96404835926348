import React from "react";
import Sidebar from "../../components/Dashboard-components/Sidebar/Sidebar";
import "./Dashboard.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import LogoutModal from "../../components/Dashboard-components/LogoutModal/LogoutModal";
import LogoutCover from "../../components/Dashboard-components/LogoutCover/LogoutCover";
import DeleteMemberModal from "../../components/Dashboard-components/DeleteMemberModal/DeleteMemberModal";

function Dashboard() {
  const navigate = useNavigate();

  const [businessPending, setBusinessPending] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const { dispatch, token, businessData, secret_id, api, outModal, deleteModal } =
    useAuthContext();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");


  const getBusiness = async () => {
    setBusinessPending(true);
    try {
      const businessresponse = await axios.get(
        api + "/main/all/business",
        config
      );
      console.log(businessresponse)
      console.log(businessresponse.data.results[0].outlet.virtual_account)
      console.log(businessresponse.data.results[0].outlet.secret_id)
      if ( businessresponse && businessresponse.data.results[0].outlet.virtual_account === null) {
        const virtualaccount = await axios.post(
          api + "/main/create/virtual_account",
          {
            outlet_secret_id: businessresponse.data.results[0].outlet.secret_id
          },
          config
        );

        if (virtualaccount) {
          const businessresponse = await axios.get(
            api + "/main/all/business",
            config
          );

          dispatch({
            type: "BUSINESS_DATA",
            payload: businessresponse.data.results[0],
          });
          if (businessresponse) {
            dispatch({
              type: "SECRET_ID",
              payload: businessresponse.data.results[0].outlet.secret_id,
            });
         

            localStorage.setItem(
              "business_secret_id",
              businessresponse.data.results[0].secret_id
            );
            localStorage.setItem(
              "outlet_secret_id",
              businessresponse.data.results[0].outlet.secret_id
            );
            localStorage.setItem(
              "role",
              businessresponse.data.results[0].outlet.role
            );
            localStorage.setItem(
              "reg",
              businessresponse.data.results[0].registered
            );
            localStorage.setItem("outlet_name" , businessresponse.data.results[0].name)
            setOutletRreshSecretId(
              businessresponse.data.results[0].outlet.secret_id
            );
            setBusinessPending(false);
          }
        }
      }

      dispatch({
        type: "BUSINESS_DATA",
        payload: businessresponse.data.results[0],
      });
     
      if (businessresponse) {
        dispatch({
          type: "SECRET_ID",
          payload: businessresponse.data.results[0].outlet.secret_id,
        });
      

        localStorage.setItem(
          "business_secret_id",
          businessresponse.data.results[0].secret_id
        );
        localStorage.setItem(
          "outlet_secret_id",
          businessresponse.data.results[0].outlet.secret_id
        );
        localStorage.setItem(
          "role",
          businessresponse.data.results[0].outlet.role
        );
        localStorage.setItem(
          "reg",
          businessresponse.data.results[0].registered
        );
        localStorage.setItem(
          "account_code",
          businessresponse.data.results[0].bank
        );
        localStorage.setItem(
          "account_number",
          businessresponse.data.results[0].account_number
        );
        localStorage.setItem(
          "account_name",
          businessresponse.data.results[0].account_name
        );
        localStorage.setItem(
          "account_phone" ,
          businessresponse.data.results[0].phone
        )
        localStorage.setItem("outlet_name" , businessresponse.data.results[0].name)
        setOutletRreshSecretId(
          businessresponse.data.results[0].outlet.secret_id
        );
        setBusinessPending(false);
        
      }

     
    } catch (err) {
      console.log(err)
    }
  };

  const checkuserr = async () => {
    const mail = localStorage.getItem("mail");
    const checkuser = await axios.get(`${api}/accounts/check_user/${mail}`);
    localStorage.setItem("verified", checkuser.data.email_verified);
    localStorage.setItem("business", checkuser.data.business);

    dispatch({ type: "VERIFIED", payload: localStorage.getItem("verified") });
    dispatch({ type: "BUSINESS", payload: localStorage.getItem("business") });

    if (checkuser.data.business === true) {
      navigate("/dashboard");
    }
  
  };

  useEffect(() => {
    getBusiness();
    checkuserr();
   
    dispatch({ type: "LOGIN", payload: localStorage.getItem("user_token") });
  }, []);

  // const expireToken=()=>{
  //    setTimeout(()=>{
  //         localStorage.removeItem('user_token')
  //         navigate('/account')
  //    }, '1800000')
  // }

  useEffect(() => {
    // expireToken()
  }, []);

  

  return (
    <>
      {businessPending ? (
        <div className="pending-container">
          <div class="center">
              <div class="animation">
                <img src="/assets/loader.svg" alt="" style={{width: '40px'}} />
              
              </div>
          </div>
        </div>
      ) : (
        <div className="dashboard-container">
          <Sidebar />

          {outModal && <LogoutModal />}
          {outModal && <LogoutCover />}
          {deleteModal && <DeleteMemberModal/>}

          <div className="dashboard-outlet">
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;

import React from 'react'

const QevoltButton = ({ text , style , onClick}) => {
  return (
     <button
        className='q-button'
        style={style}
        onClick={onClick}
     >
        {text}
     </button>
  )
}

export default QevoltButton
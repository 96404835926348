import React from "react";
import "./OTP.css";
import { useState, useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Fade } from "react-reveal";
import axios from "axios";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import Success from "../../Success/Success";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import OtpInput from "react-otp-input";
import WhiteLoader from "../../Loaders/WhiteLoader";

const OTP = () => {
  const navigate = useNavigate();

  const { email, token, dispatch, business, verifiedemail, api } =
    useAuthContext();

  const [checkOTP, setCheckOTP] = useState(false);
  const [otp, setOtp] = useState("");

  const otpp = useRef();

  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);
  const [success, setSuccess] = useState("");
  const form = { email, otp };

  const submitOtp = async (e) => {
    e.preventDefault();
    setPending(true);
    setError("");
    setOtpSuccess("");
    try {
      const response = await axios.post(api + "/accounts/verify/email/", form);

      if (Object.keys(response.data).length > 1) {
      }
      if (Object.keys(response.data).length === 1) {
        setError(response.data.error);
        setPending(false);
        setData(response);
      }

      const verified = await axios.get(`${api}/accounts/check_user/${email}`);
      localStorage.setItem("verified", verified.data.email_verified);
      localStorage.setItem("business", verified.data.business);
      dispatch({ type: "VERIFIED", payload: localStorage.getItem("verified") });
      dispatch({ type: "BUSINESS", payload: localStorage.getItem("business") });

      if (verified.data.email_verified === true) {
        setPending(false);
        setSuccess("OTP verfied . Redirecting to login");
        setTimeout(() => {
          navigate("/dashboard");
        }, 4000);
      }
    } catch (err) {
      console.log(err.code)
      if (err.code === "ERR_NETWORK") {
        setError("Internet connection lost or disconnected");
        setPending(false);
      } else if (err.code === "ECONNABORTED") {
        setError("Request timeout , check internet connection");
        setPending(false);
      } else {
        setPending(false);
        setError("OTP invalid . Request a new one");
      }
    }
  };

  //resend otp vairables

  const [otpPending, setOtpPending] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState("");
  const array = otp.split("");
  console.log(array);
  const [otpError, setOtpError] = useState("");
  const [otpData, setOtpData] = useState(null);
  const resendOtp = async () => {
    setError("");
    const email = localStorage.getItem("mail");
    setOtpPending(true);
    setOtpSuccess("");
    try {
      const response = await axios.post(`${api}/accounts/resend/otp/`, {
        email,
      });
      if (response) {
        setOtpPending(false);
        setOtpSuccess(response.data.success);
        setOtpError(response.data.error);
        setOtpData(response);
      }
    } catch (err) {

    }
  };

  const filled = () => {
    if (otpp.current.value.length < 5) {
      setCheckOTP(false);
    } else {
      setCheckOTP(true);
    }
  };

  console.log(error);
  return (
    <>
      <Fade duration={250}>
        <div className="otp-container">
          {!otpPending && otpData && otpSuccess !== "" && otpSuccess && (
            <Success message={"OTP has been sent to your email address"} />
          )}
          {success !== "" && success && <Success message={success} />}
          {!otpPending && otpData && otpError !== "" && otpError && (
            <ErrorMessage message={otpError} />
          )}
          {error !== "" && error && <ErrorMessage message={error} />}
          {otpPending && <Success message={"Requesting OTP..."} />}

          <div className="otp-main-text">Verify email</div>
          <div className="otp-subtext">We’ve sent an OTP to your email</div>
          <form className="Login-form" onSubmit={submitOtp}>
            <label>
              {window.innerWidth >= 768 && (
                <div className="email-label">OTP</div>
              )}
              {window.innerWidth >= 768 && (
                <input
                  className="inputBoxOtp"
                  type="number"
                  name="otp"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  maxlength={5}
                  value={otp}
                  placeholder="5 digit code"
                  ref={otpp}
                  onKeyUp={filled}
                  onChange={(e) => setOtp(e.target.value)}
                />
              )}
              <div className="otp-parent">
                {window.innerWidth < 768 && (
                  <>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      isInputNum={true}
                      numInputs={5}
                      separator={<span style={{ width: "2.5vw" }}></span>}
                      focusStyle={{ border: "1px solid #8F00FF" }}
                      inputStyle={{
                        width: "16vw",
                        height: "49px",
                        background: "#F7EDFF",
                        borderRadius: "8px",
                        border: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    />
                  </>
                )}
              </div>
            </label>

            {!otpPending && (
              <div className="LoginToForgot">
                Didn't get the code?{" "}
                <Link className="purple" onClick={() => resendOtp()}>
                  Resend OTP
                </Link>
              </div>
            )}
            {otpPending && (
              <div className="LoginToForgot">
                Didn't get the code?{" "}
                <Link className="purple" style={{ cursor: "not-allowed" }}>
                  Resend OTP
                </Link>
              </div>
            )}

            {!pending && array.length === 5 && (
              <button
                type="submit"
                value="Next"
                className="SignUp-button otp-button"
                style={{
                  backgroundColor: "#8F00FF",
                  color: "#fff",
                  cursor: "pointer",
                  cursor: "pointer",
                }}
              >
                Verify
              </button>
            )}
            {!pending && !checkOTP && array.length !== 5 && (
              <button
                type="submit"
                value="Next"
                className="SignUp-button otp-button"
                style={{
                  backgroundColor: "#d9d9d9",
                  color: "#fff",
                  cursor: "default",

                  cursor: "not-allowed",
                }}
                disabled="true"
              >
                Verify
              </button>
            )}

            {pending && (
              <button
                type="submit"
                value="Login"
                className="otp-button"
                style={{
                  backgroundColor: "#8F00FF",
                  color: "#fff",

                  cursor: "pointer",
                  pointerEvents: "all",
                }}
              >
                <WhiteLoader />
              </button>
            )}

            <p
              style={{ marginTop: "52px", fontSize: "14px", color: "#757575" }}
            >
              {" "}
              <img
                src="/assets/spam.svg"
                alt=""
                style={{ transform: "translateY(5px)" }}
              />{" "}
              Check your spam folder if missing from inbox
            </p>
          </form>
          <div className="otpToSignUp">
            Have an account?{" "}
            <Link to="/account/login" className="app-purple">
              Login
            </Link>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default OTP;

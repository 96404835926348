import React from 'react'

const QevoltWarning = ({text}) => {
  return (
    <div className='q-message'>
        <p>{text} </p>
    </div>
  )
}

export default QevoltWarning
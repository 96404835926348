import React from "react";
import Hamburger from "../../Hamburger/Hamburger";
import { useState } from "react";
import "./SettingsMobile.css";
import QevoltLabel from "../../../QevoltLabel/QevoltLabel";
import QevoltInput from "../../../QevoltInput/QevoltInput";
import QevoltButton from "../../../QevoltButton/QevoltButton";
import QevoltWarning from "../../../QevoltWarning/QevoltWarning";
import { useAuthContext } from "../../../../Hooks/useAuthContext";
import PopUp from "../../PopUp/PopUp";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Success from "../../../Success/Success";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { QevoltData } from "../../../QevoltData/QevoltData";
import { useEffect } from "react";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import { Linking } from "react-native";
import WhiteLoader from "../../../Loaders/WhiteLoader";
import PurpleLoader from "../../../Loaders/PurpleLoader";
import RedLoader from "../../../Loaders/RedLoader";
// import InAppBrowser from "react-native-inappbrowser-reborn";
import PreloadImage from "react-preload-image";
import PurpleSmallLoader from "../../../Loaders/PurpleSmallLoader";
import { Fade } from "react-reveal";

const SettingsMobile = ({ images }) => {
  const openUrl = (url) => {
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          setError(`Can't handle url: ${url}`);
        } else {
          return Linking.openURL(url);
        }
      })
      .catch((err) => console.error("An error occurred: ", err));
  };

  // const handleOpenLink = async (url) => {
  //   try {
  //     const isAvailable = await InAppBrowser.isAvailable();
  //     if (isAvailable) {
  //       await InAppBrowser.open(url);
  //     } else {
  //       await Linking.openURL(url);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  const navigate = useNavigate();
  const { pop, dispatch, transactionPop, token, api, filterPop, businessData } =
    useAuthContext();
  const logoutUrl = api + "/accounts/auth/logout/";
  const [pending, setPending] = useState();
  const [password, setPassword] = useState("");
  const [cac, setCac] = useState(null);
  const [bank_code, setBankCode] = useState("");
  const { livecode } = QevoltData();
  const [account_number, setAccountNumber] = useState(
    `${localStorage.getItem("account_number")}`
  );
  const [account_name, setAccountName] = useState(
    `${localStorage.getItem("account_name")}`
  );
  const checkacc = () => {
    livecode.map((val) =>
      val.bankCode === localStorage.getItem("account_code")
        ? setBankN(val.bankName)
        : null
    );
  };

  useEffect(() => {
    checkacc();
  }, []);
  const upload = () => {
    document.querySelector(".upload").click();
  };
  const logout = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setPending(true);

    try {
      dispatch({ type: "BUSINESS_DATA", payload: null });
      const response = await axios.post(logoutUrl, config);
      if (response) {
        dispatch({ type: "LOGIN", payload: null });
        setPending(false);
        navigate("/account");
        localStorage.removeItem("user_token");
        localStorage.removeItem("verified");
        localStorage.removeItem("business");
        localStorage.removeItem("business_secret_id");
        localStorage.removeItem("outlet_secret_id");
        localStorage.removeItem("role");
        localStorage.removeItem("fullname");
        localStorage.removeItem("reg");
        localStorage.removeItem("mail");
        dispatch({ type: "TRANSACTIONS_POP_UP", payload: false });
      }
    } catch (err) {
      setPending(false);
      navigate("/account");
    }
  };
  const [switchb, setSwitchb] = useState(false);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);

  const [amount, setAmount] = useState("");
  const [fee, setFee] = useState("");
  const [pay, setPay] = useState("");

  const feeCalculator = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setPending(true);
    const outlet_id = localStorage.getItem("outlet_secret_id");
    const form = { amount, outlet_id };
    try {
      const response = await axios.post(`${api}/main/calculator`, form, config);
      if (response) {
        setFee(response.data.charges);
        setPay(response.data.payout_amount);
        setPending(false);
        console.log(response);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const deleteOutlet = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setSuccess("");
    setError("");
    setPending(true);
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    try {
      const response = await axios.post(
        `${api}/main/delete/outlet/${outlet_secret_id}`,
        { password },
        config
      );
      if (response && response.data.Success !== "") {
        setSuccess(
          "Outlet deleted successfully please return to homepage to select another outlet"
        );
        setPending(false);
      }
      if (response && response.data.Error !== "") {
        setError(response.data.Error);
        setPending(false);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };

  const uploadCAC = (e) => {
    if (e.target.files[0]) {
      setCac(e.target.files[0]);
    }
  };

  const [phone, setPhone] = useState(localStorage.getItem("account_phone"));
  const [address, setAddress] = useState("");

  const updateOutlet = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setSuccess("");
    setError("");
    setPending(true);
    const business_id = localStorage.getItem("business_secret_id");
    try {
      const response = await axios.post(
        `${api}/main/upgrade/business`,
        { pkone: phone, address, cac, business_id },
        config
      );
      if (response && response.data.Success !== "") {
        setSuccess(
          "Business Graded Successfully please log in again to add outlets"
        );
        setPending(false);
      }
      if (response && response.Error !== "") {
        setError(response.data.Error);
        setPending(false);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };
  const updateOutletReg = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setSuccess("");
    setError("");
    setPending(true);
    const business_id = localStorage.getItem("business_secret_id");
    try {
      const response = await axios.post(
        `${api}/main/upgrade/business`,
        { phone, address, business_id },
        config
      );
      if (response && response.data.Success !== "") {
        setSuccess(
          "Business Graded Successfully please log in again to add outlets"
        );
        setPending(false);
      }
      if (response && response.Error !== "") {
        setError(response.data.Error);
        setPending(false);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };

  const access = localStorage.getItem("access");
  const reg = localStorage.getItem("reg");

  console.log(cac);

  const [showBank, setShowBank] = useState(false);
  const [bankN, setBankN] = useState("");
  const toggleBank = () => {
    setShowBank(!showBank);
  };
  const onOptionClicked = (value, value2) => () => {
    setBankN(value);
    setBankCode(value2);
    setShowBank(false);
    setAccountName("");
    setAccountNumber("");
    setAccountPending(false);
  };

  const [accountPending, setAccountPending] = useState(false);
  const [accountError, setAccountError] = useState("");
  const getAccountName = async () => {
    setAccountPending(true);
    setAccountError("");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
    };
    try {
      const form = { bank_code, account_number };
      if (bank_code !== "" && account_number !== "") {
        const accountName = await axios.post(
          api + "/main/verify/bank_account",
          form,
          config
        );

        if (accountName) {
          setAccountName(accountName.data.Account_name);
          setAccountError(accountName.data.Error);
          setAccountPending(false);
        }
      }
    } catch (err) {
      setAccountPending(false);
    }
  };

  const updatePayouts = async (event) => {
    event.preventDefault();
    const business_secret_id = localStorage.getItem("business_secret_id");
    setPending(true);
    const form = {
      bank_code,
      account_number,
      account_name,
      business_id: business_secret_id,
      password,
    };
    console.log(form);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.post(
        `${api}/main/update/business`,
        form,
        config
      );
      if (response) {
        setPending(false);
        setError(response.data.Error);
        setSuccess("Payout account updated");
        console.log(response);
      }

      console.log(response);
    } catch (err) {
      setPending(false);
      setError("Something Went Wrong");
    }
  };

  const [confirm_password, setConfirmPassword] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [passwordPending, setPasswordPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const ChangePassword = async (event) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setPasswordPending(true);
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    const form = { confirm_password, password, old_password };
    try {
      setPasswordPending(true);
      const response = await axios.post(
        `${api}/accounts/set_password`,
        form,
        config
      );

      if (response) {
        setConfirmPassword("");
        setPassword("");
        setErrorMessage(response.data.error);
        setSuccessMessage("Password updated");
        setPasswordPending(false);
        console.log(response);
      }
    } catch (err) {}
  };

  const fullname = localStorage.getItem("fullname");
  const mail = localStorage.getItem("mail");
  const outlet_null_name = localStorage.getItem("outlet_name");
  const outlet_Registered_name = JSON.parse(
    localStorage.getItem("OutletDetails")
  );

  const role = localStorage.getItem("role");

  const [passType, setPassType] = useState("password");
  const [pass, setPass] = useState(false);
  const [passType1, setPassType1] = useState("password");
  const [pass1, setPass1] = useState(false);
  const [passType2, setPassType2] = useState("password");
  const [pass2, setPass2] = useState(false);
  const togglePass = () => {
    setPass(!pass);
    if (!pass) {
      setPassType("password");
    }
    if (pass) {
      setPassType("text");
    }
  };
  const togglePass1 = () => {
    setPass1(!pass1);
    if (!pass1) {
      setPassType1("password");
    }
    if (pass1) {
      setPassType1("text");
    }
  };
  const togglePass2 = () => {
    setPass2(!pass2);
    if (!pass2) {
      setPassType2("password");
    }
    if (pass2) {
      setPassType2("text");
    }
  };

  console.log(images);

  return (
    <div
      className="mobile-container-transactions"
      style={{
        height: sixth ? "100%" : "80%",
        overflowY: "auto",
        paddingBottom: "50px",
      }}
    >
      {!first &&
        !second &&
        !third &&
        !fourth &&
        !fifth &&
        !sixth &&
        !switchb && (
          <>
            {error !== "" && error && <ErrorMessage message={`${error}`} />}
            <div className="home-mobile-top">
              <div className="m-transactions-top-left">
                <h1>More</h1>
              </div>
              {/* <div
                className="m-transactions-top-right"
                onClick={() => setSwitchb(true)}
              >
                Switch business
              </div> */}
            </div>
            <div className="settings-tabs">
              <div className="tabs-flex" onClick={() => setFirst(true)}>
                <div className="tabs-flex-left">
                  <img src={images[94]} alt="" />
                  <p>Profile</p>
                </div>
                <div className="tabs-flex-right">
                  <img src="/assets/sn.svg" alt="" />
                </div>
              </div>
              {role === "BOSS" && (
                <>
                  <div className="tabs-flex" onClick={() => setSecond(true)}>
                    <div className="tabs-flex-left">
                      <img src={images[95]} alt="" />
                      <p>Outlet information</p>
                    </div>
                    <div className="tabs-flex-right">
                      <img src="/assets/sn.svg" alt="" />
                    </div>
                  </div>
                  <div className="tabs-flex" onClick={() => setThird(true)}>
                    <div className="tabs-flex-left">
                      <img src={images[96]} alt="" />
                      <p>Payout</p>
                    </div>
                    <div className="tabs-flex-right">
                      <img src="/assets/sn.svg" alt="" />
                    </div>
                  </div>
                </>
              )}
              {role === "STAF" && (
                <>
                  <div className="tabs-flex">
                    <div className="tabs-flex-left">
                      <img src={images[54]} alt="" />
                      <p style={{ color: "#d9d9d9" }}>Outlet information</p>
                    </div>
                    <div className="tabs-flex-right">
                      <img src="/assets/sn.svg" alt="" />
                    </div>
                  </div>
                  <div className="tabs-flex">
                    <div className="tabs-flex-left">
                      <img src={images[55]} alt="" />
                      <p style={{ color: "#d9d9d9" }}>Payout</p>
                    </div>
                    <div className="tabs-flex-right">
                      <img src="/assets/sn.svg" alt="" />
                    </div>
                  </div>
                </>
              )}
              {role === "MAGR" && (
                <>
                  <div className="tabs-flex">
                    <div className="tabs-flex-left">
                      <img src={images[54]} alt="" />
                      <p style={{ color: "#d9d9d9" }}>Outlet information</p>
                    </div>
                    <div className="tabs-flex-right">
                      <img src="/assets/sn.svg" alt="" />
                    </div>
                  </div>
                  <div className="tabs-flex">
                    <div className="tabs-flex-left">
                      <img src={images[55]} alt="" />
                      <p style={{ color: "#d9d9d9" }}>Payout</p>
                    </div>
                    <div className="tabs-flex-right">
                      <img src="/assets/sn.svg" alt="" />
                    </div>
                  </div>
                </>
              )}
              <div className="tabs-flex" onClick={() => setFourth(true)}>
                <div className="tabs-flex-left">
                  <img src={images[97]} alt="" />
                  <p>Security</p>
                </div>
                <div className="tabs-flex-right">
                  <img src="/assets/sn.svg" alt="" />
                </div>
              </div>
              <div className="tabs-flex">
                <div
                  className="tabs-flex-left"
                  onClick={() => {
                    dispatch({ type: "POP_UP", payload: true });
                    dispatch({ type: "TRANSACTION_POP_UP", payload: false });
                  }}
                >
                  <img src={images[98]} alt="" />
                  <p>Support</p>
                </div>
              </div>
              <div className="tabs-flex" onClick={() => setFifth(true)}>
                <div className="tabs-flex-left">
                  <img src={images[22]} alt="" />
                  <p>Fee calculator</p>
                </div>
              </div>
              <div
                className="tabs-flex"
                onClick={() => {
                  openUrl("https://qevolt.com/policy");
                }}
              >
                <div className="tabs-flex-left">
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      background: "#f8f8f8",
                      borderRadius: "50px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <img
                      src={images[101]}
                      alt=""
                      style={{ width: "19px", height: "19px" }}
                    />
                  </div>
                  <p>Privacy policy</p>
                </div>
              </div>
              {/* <div className="tabs-flex" onClick={()=>{ dispatch({type:'POP_UP' , payload: true}) ; dispatch({type: 'TRANSACTION_POP_UP' , payload: false})}}>
            <div className="tabs-flex-left">
                <img src="/assets/x6.svg" alt="" />
                <p style={{color: '#FF3737'}}>Delete account</p>
            </div>  
        
          </div> */}
              <div
                className="tabs-flex"
                onClick={() => {
                  dispatch({ type: "POP_UP", payload: false });
                  dispatch({ type: "TRANSACTION_POP_UP", payload: true });
                }}
              >
                <div className="tabs-flex-left">
                  <img src={images[100]} alt="" />
                  <p style={{ color: "#FF3737" }}>Logout</p>
                </div>
              </div>
            </div>
            <Hamburger />
          </>
        )}
      {switchb && (
        <Fade duration={200}>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "24px" }}>Select an organisation</h1>
            </div>
            <div className="m-transactions-top-right">
              <img
                src="/assets/switch-x.svg"
                alt=""
                style={{
                  alignSelf: "center",
                }}
                onClick={() => {
                  setSwitchb(false);
                }}
              />
            </div>
          </div>
          <div className="switch-org-btn">Switch</div>
        </Fade>
      )}
      {first && (
        <>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src={images[2]}
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => {
                    setFirst(false);
                    setSuccess("");
                  }}
                />{" "}
                Profile
              </h1>
            </div>
          </div>

          <form action="">
            <QevoltLabel text={"Full name"} />
            <QevoltInput
              value={fullname}
              style={{ color: "#c4c4c4" }}
              readonly={"readonly"}
            />
            <QevoltLabel text={"Email address"} />
            <QevoltInput
              value={mail}
              style={{ color: "#c4c4c4" }}
              readonly={"readonly"}
            />
            {/* {!pending && confirm_password !== '' &&  <button className='q-button' type="submit">Update profile</button>} */}
            <button
              className="q-button"
              type="submit"
              style={{ background: "#D9D9D9", fontWeight: "400" }}
            >
              Update profile
            </button>
            {/* {pending && <button className='q-button' disabled>
                                  <img src="/assets/spinner.svg" alt="" style={{width: '40px' , transform: 'translateY(3px)'}} />
                              </button>} */}
          </form>
        </>
      )}
      {second && (
        <>
          {success !== "" && success && <Success message={`${success}`} />}
          {error !== "" && error && <ErrorMessage message={`${error}`} />}
          {reg === "false" && (
            <>
              <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
                <div className="m-transactions-top-left">
                  <h1 style={{ fontSize: "18px" }}>
                    <img
                      src={images[2]}
                      alt=""
                      style={{
                        marginRight: "26px",
                        alignSelf: "center",
                        transform: "translateY(5px)",
                      }}
                      onClick={() => {
                        setSecond(false);
                        setSuccess("");
                      }}
                    />{" "}
                    Outlet information
                  </h1>
                </div>
              </div>
              <QevoltWarning text="Upload CAC certificate to upgrade to a registered business" />
              <div>
                <form action="" onSubmit={updateOutlet}>
                  <QevoltLabel text={"Business name"} />
                  {businessData && (
                    <QevoltInput
                      value={outlet_null_name}
                      style={{ color: "#c4c4c4" }}
                    />
                  )}
                  {!businessData && (
                    <QevoltInput
                      value={outlet_Registered_name.name}
                      style={{ color: "#c4c4c4" }}
                    />
                  )}
                  <QevoltLabel text={"Business phone number"} />
                  <QevoltInput
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name={"phone"}
                  />
                  <QevoltLabel text={"Business Address"} />
                  <QevoltInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name={"address"}
                  />
                  <QevoltLabel text={"CAC certificate"} />

                  <div className="form-group" onClick={upload}>
                    {
                      <input
                        type="file"
                        onChange={uploadCAC}
                        name="Upload"
                        className="upload"
                        accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        style={{ display: "none" }}
                        required
                      ></input>
                    }
                    <div
                      className="kyc-dash-box"
                      style={{
                        backgroundImage: "none",
                        width: "100%",
                        border: "1px dashed #d9d9d9",
                        margin: "6px 0px 24px 0px",
                        height: "48px",
                        alignItems: "flex-start",
                        paddingLeft: "16px",
                        borderRadius: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#757575",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Upload file
                      </p>
                    </div>
                  </div>
                  {cac && (
                    <div
                      className=""
                      style={{
                        transform: "translateY(-15px)",
                        color: "green",
                        fontSize: "14px",
                      }}
                    >
                      Succesfully uploaded
                    </div>
                  )}

                  {!pending && address !== "" && (
                    <QevoltButton text={"Update Outlet"} />
                  )}
                  {!pending && address === "" && (
                    <QevoltButton
                      text={"Update Outlet"}
                      style={{ background: "rgb(217, 217, 217)" }}
                    />
                  )}
                  {pending && (
                    <button className="q-button" disabled>
                      <WhiteLoader />
                    </button>
                  )}
                </form>
              </div>
            </>
          )}
          {reg === "true" && (
            <>
              <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
                <div className="m-transactions-top-left">
                  <h1 style={{ fontSize: "18px" }}>
                    <img
                      src={images[2]}
                      alt=""
                      style={{
                        marginRight: "26px",
                        alignSelf: "center",
                        transform: "translateY(5px)",
                      }}
                      onClick={() => {
                        setSecond(false);
                        setSuccess("");
                        setPassword("");
                      }}
                    />{" "}
                    Outlet information
                  </h1>
                </div>
              </div>
              {/* <QevoltWarning text="Only business phone number and address can be edited on this form. Reach out to support to fully update outlet" /> */}
              <div>
                <form action="" onSubmit={updateOutletReg}>
                  <QevoltLabel
                    text={"Business name"}
                    style={{ color: "#d4d4d4" }}
                  />
                  {businessData && (
                    <QevoltInput
                      value={outlet_null_name}
                      style={{ color: "#c4c4c4" }}
                    />
                  )}
                  {!businessData && (
                    <QevoltInput
                      value={outlet_Registered_name.name}
                      style={{ color: "#c4c4c4" }}
                    />
                  )}
                  <QevoltLabel text={"Business phone number"} />
                  <QevoltInput
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name={"phone"}
                  />
                  <QevoltLabel text={"Business Address"} />
                  <QevoltInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name={"address"}
                  />
                  <QevoltLabel text={"CAC certificate"} />
                  <div className="form-group">
                    {/* {  <input type= "file" onChange={uploadCAC} name="Upload" className='upload' accept = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{display:'none'}} required></input>} */}
                    <div
                      className="kyc-dash-box"
                      style={{
                        backgroundImage: "none",
                        width: "100%",
                        border: "1px dashed #d9d9d9",
                        margin: "6px 0px 24px 0px",
                        height: "48px",
                        alignItems: "flex-start",
                        paddingLeft: "16px",
                        borderRadius: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#757575",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Certificate in place{" "}
                      </p>
                    </div>
                  </div>
                  {!pending && address !== "" && (
                    <QevoltButton text={"Update Outlet"} />
                  )}
                  {pending && (
                    <button className="q-button" disabled>
                      <WhiteLoader />
                    </button>
                  )}
                  {!pending && address === "" && (
                    <button
                      className="q-button"
                      disabled
                      style={{ background: "#d9d9d9" }}
                    >
                      Update Outlet
                    </button>
                  )}
                </form>
              </div>
              {access === "False" && (
                <div
                  className="delete-outlet"
                  style={{ display: "flex", gap: "14.4px", marginTop: "30px" }}
                  onClick={() => {
                    dispatch({ type: "FILTER_POP_UP", payload: true });
                  }}
                >
                  <img src="/assets/bin.svg" alt="" />
                  <p
                    style={{
                      color: "#FF3737",
                      alignSelf: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Delete outlet
                  </p>
                </div>
              )}
              {filterPop && (
                <PopUp
                  style={{ height: "40vh" }}
                  heading={"Delete outlet"}
                  sub={"Are you sure you want to delete this outlet?"}
                >
                  <div className="delte-container">
                    <QevoltLabel text={"Password"} />
                    <input
                      className="q-input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      type={passType}
                    />
                    <div
                      className="ShowPassword"
                      onClick={togglePass}
                      style={{
                        position: "absolute",
                        right: "calc(10% + 10px)",
                        transform: "translateY(-57px)",
                      }}
                    >
                      {passType === "password" && (
                        <img
                          src="/assets/eye-closed.svg"
                          alt=""
                          style={{ width: "20px" }}
                        />
                      )}
                      {passType === "text" && (
                        <img
                          src="/assets/eye-open.png"
                          alt=""
                          style={{ width: "20px" }}
                        />
                      )}
                    </div>
                    <div className="pop-button">
                      <button>Cancel</button>
                      {!pending && (
                        <button
                          onClick={() => {
                            deleteOutlet();
                          }}
                        >
                          Delete
                        </button>
                      )}
                      {pending && (
                        <button>
                          {" "}
                          <RedLoader />{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </PopUp>
              )}
            </>
          )}
        </>
      )}
      {third && (
        <>
          {success !== "" && success && <Success message={`${success}`} />}
          {error !== "" && error && <ErrorMessage message={error} />}
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src={images[2]}
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => {
                    setThird(false);
                    setSuccess("");
                    setAccountName(localStorage.getItem("account_name"));
                    setAccountNumber(localStorage.getItem("account_number"));
                    checkacc();
                  }}
                />{" "}
                Payout account
              </h1>
            </div>
          </div>

          <div>
            <form action="" onSubmit={updatePayouts}>
              <div className="form-group">
                <label
                  className="kyc-label"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#757575",
                  }}
                >
                  {" "}
                  Bank{" "}
                </label>
                <img
                  src="/assets/dropb.svg"
                  alt="sv"
                  className="label-img"
                  onClick={() => toggleBank()}
                />
                <input
                  type="text"
                  value={bankN}
                  name="bank_code"
                  onChange={(e) => {
                    setBankN(e.target.value);
                  }}
                  required
                  placeholder="Select"
                  onClick={() => toggleBank()}
                  onKeyPress={(e) => e.preventDefault()}
                  style={{
                    color: "transparent",
                    textShadow: "0 0 0 black",
                    cursor: "pointer",
                    margin: "6px 0px 24px 0px",
                  }}
                  readonly="readonly"
                  className="q-input"
                />
                {showBank && (
                  <ul>
                    {livecode.map((val, idx) => (
                      <li
                        key={idx}
                        onClick={onOptionClicked(val.bankName, val.bankCode)}
                        style={{ cursor: "pointer" }}
                      >
                        {val.bankName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="form-group">
                <label
                  className="kyc-label"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#757575",
                  }}
                >
                  Account number{" "}
                </label>
                <input
                  type="number"
                  value={account_number}
                  name="account_number"
                  onChange={(e) => setAccountNumber(e.target.value)}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  required
                  maxlength={10}
                  onKeyUp={() => {
                    account_number.length === 10
                      ? getAccountName()
                      : console.log("");
                  }}
                  className="q-input"
                />
                {accountError !== "" && (
                  <p
                    style={{
                      fontSize: "13px",
                      color: "red",
                      transform: "translateY(-20px)",
                    }}
                  >
                    {accountError}
                  </p>
                )}
                {accountPending && <PurpleSmallLoader />}
                {!accountPending && <></>}
              </div>

              <QevoltLabel text={"Account name"} />
              <QevoltInput value={account_name} name="account_name" />
              <QevoltLabel text={"Password"} />
              <input
                value={password}
                className="q-input"
                type={passType}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
              />
              <div
                className="ShowPassword"
                onClick={togglePass}
                style={{
                  position: "absolute",
                  right: "calc(10% + 10px)",
                  transform: "translateY(-57px)",
                }}
              >
                {passType === "password" && (
                  <img
                    src="/assets/eye-closed.svg"
                    alt=""
                    style={{ width: "20px" }}
                  />
                )}
                {passType === "text" && (
                  <img
                    src="/assets/eye-open.png"
                    alt=""
                    style={{ width: "20px" }}
                  />
                )}
              </div>
              {!pending && password === "" && account_name === "" && (
                <button
                  className="q-button"
                  type="submit"
                  style={{ fontWeight: "400", background: "#D9D9D9" }}
                >
                  Update payout account
                </button>
              )}
              {!pending && password === "" && account_name !== "" && (
                <button
                  className="q-button"
                  type="submit"
                  style={{ fontWeight: "400", background: "#D9D9D9" }}
                >
                  Update payout account
                </button>
              )}
              {!pending && password !== "" && account_name === "" && (
                <button
                  className="q-button"
                  type="submit"
                  style={{ fontWeight: "400", background: "#D9D9D9" }}
                >
                  Update payout account
                </button>
              )}
              {!pending && password !== "" && account_name !== "" && (
                <button
                  className="q-button"
                  type="submit"
                  style={{ fontWeight: "400" }}
                >
                  Update payout account
                </button>
              )}
              {pending && (
                <button className="q-button" disabled>
                  <WhiteLoader />
                </button>
              )}
            </form>
          </div>
        </>
      )}
      {fourth && (
        <>
          {successMessage !== "" && successMessage && (
            <Success message={`${successMessage}`} />
          )}
          {errorMessage !== "" && errorMessage && (
            <ErrorMessage message={errorMessage} />
          )}

          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src={images[2]}
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => {
                    setFourth(false);
                    setSuccess("");
                    setPassword("");
                  }}
                />{" "}
                Security
              </h1>
            </div>
          </div>
          <form action="" onSubmit={ChangePassword}>
            <QevoltLabel text={"Old password"} />
            <input
              className="q-input"
              type={passType}
              value={old_password}
              onChange={(e) => setOldPassword(e.target.value)}
              name="old_password"
            />
            <div
              className="ShowPassword"
              onClick={togglePass}
              style={{
                position: "absolute",
                right: "calc(10% + 10px)",
                transform: "translateY(-57px)",
              }}
            >
              {passType === "password" && (
                <img
                  src="/assets/eye-closed.svg"
                  alt=""
                  style={{ width: "20px" }}
                />
              )}
              {passType === "text" && (
                <img
                  src="/assets/eye-open.png"
                  alt=""
                  style={{ width: "20px" }}
                />
              )}
            </div>
            <QevoltLabel text={"New password"} />
            <input
              className="q-input"
              type={passType1}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name={"password"}
            />
            <div
              className="ShowPassword"
              onClick={togglePass1}
              style={{
                position: "absolute",
                right: "calc(10% + 10px)",
                transform: "translateY(-57px)",
              }}
            >
              {passType1 === "password" && (
                <img
                  src="/assets/eye-closed.svg"
                  alt=""
                  style={{ width: "20px" }}
                />
              )}
              {passType1 === "text" && (
                <img
                  src="/assets/eye-open.png"
                  alt=""
                  style={{ width: "20px" }}
                />
              )}
            </div>
            <QevoltLabel text={"Confirm password"} />
            <input
              className="q-input"
              type={passType2}
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
              name={"confirm_password"}
            />
            <div
              className="ShowPassword"
              onClick={togglePass2}
              style={{
                position: "absolute",
                right: "calc(10% + 10px)",
                transform: "translateY(-57px)",
              }}
            >
              {passType2 === "password" && (
                <img
                  src="/assets/eye-closed.svg"
                  alt=""
                  style={{ width: "20px" }}
                />
              )}
              {passType2 === "text" && (
                <img
                  src="/assets/eye-open.png"
                  alt=""
                  style={{ width: "20px" }}
                />
              )}
            </div>
            {!passwordPending && confirm_password !== "" && (
              <button
                className="q-button"
                type="submit"
                style={{ fontWeight: "400" }}
              >
                Update password
              </button>
            )}
            {!passwordPending && confirm_password === "" && (
              <button
                className="q-button"
                type="submit"
                style={{ background: "#D9D9D9", fontWeight: "400" }}
              >
                Update password
              </button>
            )}
            {passwordPending && (
              <button className="q-button" disabled>
                <WhiteLoader />
              </button>
            )}
          </form>
        </>
      )}
      {fifth && (
        <>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src={images[2]}
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => {
                    setFifth(false);
                    setSuccess("");
                  }}
                />{" "}
                Fee calculator
              </h1>
            </div>
          </div>

          <QevoltLabel text={"You receieve"} />
          <QevoltInput
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            name={"amount"}
            style={{ paddingLeft: "25px" }}
            inputMode={"numeric"}
            type={"number"}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
          <p
            style={{
              position: "absolute",
              transform: "translate(10px , -57px)",
            }}
          >
            ₦
          </p>
          <QevoltLabel text={"Qevolt takes"} />
          <input
            type="text"
            className="q-input"
            value={fee}
            disabled
            style={{ paddingLeft: "25px" }}
          />
          <p
            style={{
              position: "absolute",
              transform: "translate(10px , -57px)",
            }}
          >
            ₦
          </p>
          <QevoltLabel text={"We settle into your account"} />
          <input
            type="text"
            className="q-input"
            value={pay}
            disabled
            style={{ paddingLeft: "25px" }}
          />
          <p
            style={{
              position: "absolute",
              transform: "translate(10px , -57px)",
            }}
          >
            ₦
          </p>
          {!pending && amount !== "" && (
            <QevoltButton text={"Calculate"} onClick={() => feeCalculator()} />
          )}
          {!pending && amount === "" && (
            <QevoltButton
              text={"Calculate"}
              style={{ background: "rgb(217, 217, 217)" }}
              onClick={() => feeCalculator()}
            />
          )}
          {pending && (
            <div>
              <button className="q-button">
                <WhiteLoader />
              </button>
            </div>
          )}
        </>
      )}

      {transactionPop && (
        <PopUp
          style={{ height: "30vh" }}
          heading={"Log out"}
          sub={"Are you sure you want to log out?"}
        >
          <div className="delte-container">
            <div className="pop-button">
              <button>Cancel</button>
              {!pending && (
                <button
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </button>
              )}
              {pending && (
                <button>
                  <RedLoader />
                </button>
              )}
            </div>
          </div>
        </PopUp>
      )}

      {pop && (
        <PopUp
          style={{ height: "50vh" }}
          heading={"Reach out to us"}
          sub={"We’re available from 9AM - 5PM every weekday"}
        >
          <div className="pop-body"></div>
          <div
            className="pop-body"
            style={{ display: "flex", flexDirection: "column", gap: "35px" }}
          >
            <p>
              {" "}
              <img src="/assets/cd.svg" alt="" /> Live chat
            </p>
            <p>
              {" "}
              <img src="/assets/c.svg" alt="" /> Call 01-513-5115
            </p>
            <p>
              {" "}
              <img src="/assets/m.svg" alt="" /> Email support@qevolt.com
            </p>
          </div>
        </PopUp>
      )}
    </div>
  );
};

export default SettingsMobile;

import React from "react";

const PurpleLoader = () => {
  return (
    <div>
      <div className="loadingio-spinner-spinner-a1zv0ovh0f5">
        <div class="ldio-vkul51amp79">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default PurpleLoader;

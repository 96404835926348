import "./Registered.css";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useEffect } from "react";
import { QevoltData } from "../../QevoltData/QevoltData";
import QevoltLabel from "../../QevoltLabel/QevoltLabel";
import PurpleLoader from "../../Loaders/PurpleLoader";
import PurpleSmallLoader from "../../Loaders/PurpleSmallLoader";
const Registered = () => {
  const { token, dispatch, api } = useAuthContext();

  const [business_phonenumber, setBussinessPhonenumber] = useState("");
  const [bvn, setBvn] = useState("");
  const [bank_code, setBankCode] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");
  const [referrence, setReferrence] = useState("");
  const [cac, setCac] = useState(null);
  const [address, setAddress] = useState("");

  const [pending1, setPending1] = useState(false);
  const [pending2, setPending2] = useState(false);
  const [pending3, setPending3] = useState(false);

  const { livecode } = QevoltData();

  const upload = () => {
    document.querySelector(".upload").click();
  };

  const [fullname, setFullName] = useState("");

  const form = {
    business_name: fullname,
    business_phonenumber,
    cac,
    address,
    bank_code,
    account_number,
    account_name,
    registered: "True",
    referrence,
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [bodyPreload, setBodyPreload] = useState(false);
  const [pending4, setPending4] = useState(false);
  const checkuserr = async () => {
    const mail = localStorage.getItem("mail");
    const checkuser = await axios.get(`${api}/accounts/check_user/${mail}`);
    localStorage.setItem("verified", checkuser.data.email_verified);
    localStorage.setItem("business", checkuser.data.business);

    dispatch({ type: "VERIFIED", payload: localStorage.getItem("verified") });
    dispatch({ type: "BUSINESS", payload: localStorage.getItem("business") });

    if (checkuser.data.business === true) {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    checkuserr();
  }, []);

  const [accountPending, setAccountPending] = useState(false);
  const [accountError, setAccountError] = useState("");
  const getAccountName = async () => {
    setAccountPending(true);
    setAccountError("");
    try {
      const form = { bank_code , account_number };
      if (bank_code !== "" && account_number !== "") {
        const accountName = await axios.post(
          api + "/main/verify/bank_account",
          form,
          config
        );
     console.log(accountName)
        if (accountName?.data?.Account_name) {
          setAccountName(accountName?.data?.Account_name);
          setAccountPending(false);
          setAccountError("")
        }
        if (accountName?.data?.Error) {       
          setAccountError(accountName.data.Error);
          setAccountPending(false);
          setAccountName("")
        }
      }
    } catch (err) {
      setAccountPending(false);
    }
  };
  const [submitted , setSubmitted] = useState(false)
  const getData = async (e) => {
    e.preventDefault();

    setPending1(true);
    setBodyPreload(true);

    try {
      const response = await axios.post(
        api + "/main/create/business",
        form,
        config
      );

      if (response) {
        setPending1(false);
        setPending2(true);
      }

      const outletForm = {
        business_secret_id: response.data.secret_id,
        outlet_name: response.data.name,
      };
      const secretresponse = await axios.post(
        api + "/main/create/outlet",
        outletForm,
        config
      );

      dispatch({ type: "SECRET_ID", payload: secretresponse.data.secret_id });
      if (secretresponse) {
        setPending2(false);
        setPending3(true);
      }

      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: secretresponse.data.secret_id },
        config
      );

      const mail = localStorage.getItem("mail");
      const checkuser = await axios.get(`${api}/accounts/check_user/${mail}`);

      if (
        checkuser.data.email_verified === true &&
        checkuser.data.business === true
      ) {
        navigate("/dashboard");
      }
    } catch (err) {}
  };

  const [showBank, setShowBank] = useState(false);
  const [bankN, setBankN] = useState("");
  const toggleBank = () => {
    setShowBank(!showBank);
  };
  const onOptionClicked = (value, value2) => () => {
    setBankN(value);
    setBankCode(value2);
    setShowBank(false);
  };

  const [type1, setType1] = useState(true);
  const [type2, setType2] = useState(false);

  const showType1 = () => {
    setType1(true);
    setType2(false);
  };

  const showType2 = () => {
    setType1(false);
    setType2(true);
  };

  const openModalDiff = () => {
    const kyc = document.querySelector(".kyc");
    setDiff(true);
  };

  const [cacPre, setCacPre] = useState();

  const uploadCAC = (e) => {
    if (e.target.files[0]) {
      setCac(e.target.files[0]);
    }
  };

  const [diff, setDiff] = useState(false);
  const [whyBv, setWhyBv] = useState(false);
  const [need, setNeed] = useState(false);

  const [checkFilled, setCheckFilled] = useState(false);
  const filled = () => {
    if (
      business_phonenumber !== "" &&
      address !== "" &&
      account_number !== "" &&
      account_number?.length === 10 &&  
      account_name !== "" &&
      accountError === "" &&
      !accountPending &&
      cac 
    ) {
      setCheckFilled(true);
      setSubmitted(false)
    } else {
      setCheckFilled(false);
      setSubmitted(true)
    }
  };

  useEffect(() => {
    filled();
  }, [
    accountPending, 
    accountError,
    account_number,
    account_name,
    business_phonenumber,
    address,
    cac 
  ]);

  return (
    <div className="">
      {diff && (
        <div className="modal" style={{ width: "324px", height: "428px" }}>
          <div className="business-heading2">
            <h2>Business types</h2>
          </div>
          <div className="business-body">
            <p>
              The ‘unregistered’ option is for business owners who do not have
              legal documentation or licenses to operate.{" "}
            </p>

            <p>
              The ‘registered’ option is for business owners who have legal
              documentation or licenses to operate.{" "}
            </p>

            <p>
              *Unregistered businesses won’t be able to add outlets to their
              organisation
            </p>
          </div>

          <button className="business-btn" onClick={() => setDiff(false)}>
            Cancel
          </button>
        </div>
      )}
      {need && (
        <div className="modal" style={{ width: "324px", height: "291px" }}>
          <div className="business-heading2">
            <h2>Business name</h2>
          </div>
          <div className="business-body">
            <p>
              Your business name is the same as your full name because you don’t
              have legal documentation displaying business name or company
              registration
            </p>
          </div>

          <button className="business-btn" onClick={() => setNeed(false)}>
            Cancel
          </button>
        </div>
      )}
      {whyBv && (
        <div className="modal" style={{ width: "324px", height: "270px" }}>
          <div className="business-heading2">
            <h2>BVN</h2>
          </div>
          <div className="business-body">
            <p>
              We collect BVN for user identity verification in order to prevent
              fraudulent activities and ensure financial safety.{" "}
            </p>
          </div>

          <button className="business-btn" onClick={() => setWhyBv(false)}>
            Cancel
          </button>
        </div>
      )}

      <form onSubmit={getData} className="business-form">
        <div className="form-group">
          <div className="label-flex">
            <div className="" style={{ display: "flex" }}>
              <QevoltLabel text={"Business name"} />{" "}
            </div>
            {/* <span onClick={()=>setNeed(true)}>why can't i edit this?</span> */}
          </div>
          <input
            className="q-input"
            type="text"
            value={fullname}
            name="business_name"
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <div className="label-flex">
            <div className="" style={{ display: "flex" }}>
              <QevoltLabel text={"Business address"} />{" "}
            </div>
          </div>
          <input
            type="text"
            value={address}
            name="business_name"
            className="q-input"
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="form-group">
          <div className="" style={{ display: "flex" }}>
            <QevoltLabel text={"Business phone number"} />{" "}
          </div>
          <input
            className="q-input"
            type="text"
            value={business_phonenumber}
            name="business_phonenumber"
            onChange={(e) => {
              setBussinessPhonenumber(e.target.value);
            }}
            required
          />
        </div>

        <div className="form-group" onClick={upload}>
          <div className="" style={{ display: "flex" }}>
            <QevoltLabel text={"CAC cetificate"} />{" "}
          </div>
          {
            <input
              type="file"
              onChange={uploadCAC}
              name="Upload"
              className="upload"
              accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: "none" }}
              required
            ></input>
          }
          <div
            className="kyc-dash-box"
            style={{
              backgroundImage: "none",
              width: "100%",
              border: "1px dashed #d9d9d9",
              margin: "6px 0px 24px 0px",
              height: "48px",
              alignItems: "flex-start",
              paddingLeft: "16px",
              borderRadius: "10px",
            }}
          >
            <p
              style={{ fontSize: "14px", color: "#757575", fontWeight: "600" }}
            >
              {" "}
              Upload file
            </p>
          </div>
        </div>
        {cac && (
          <div
            className=""
            style={{ transform: "translateY(-15px)", color: "green" }}
          >
            Succesfully uploaded
          </div>
        )}

        <div className="form-group">
          <div className="" style={{ display: "flex" }}>
            <QevoltLabel text={"Select bank"} />{" "}
          </div>
          <img
            src="/assets/dropb.svg"
            alt="sv"
            className="label-img"
            onClick={() => toggleBank()}
          />
          <input
            type="text"
            value={bankN}
            name="bankN"
            placeholder="Select"
            className="q-input"
            onChange={(e) => {
              setBankN(e.target.value);
            }}
            required
            onClick={() => toggleBank()}
            onKeyPress={(e) => e.preventDefault()}
            style={{
              color: "transparent",
              textShadow: "0 0 0 black",
              cursor: "pointer",
            }}
            readonly="readonly"
          />
          {showBank && (
            <ul>
              {livecode.map((val, idx) => (
                <li
                  key={idx}
                  onClick={onOptionClicked(val.bankName, val.bankCode)}
                  style={{ cursor: "pointer" }}
                >
                  {val.bankName}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="form-group">
          <div className="" style={{ display: "flex" }}>
            <QevoltLabel text={"Account number"} />{" "}
          </div>
          <input
            type="number"
            className="q-input"
            value={account_number}
            name="account_number"
            onChange={(e) => setAccountNumber(e.target.value)}
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
            }}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            required
            maxlength={10}
            onKeyUp={() => {
              account_number.length === 10 ? getAccountName() : console.log("");
            }}
          />
          {accountError !== "" && (
            <p
              style={{
                fontSize: "13px",
                color: "red",
                transform: "translateY(-20px)",
              }}
            >
              {accountError}
            </p>
          )}
          {accountPending && (
            <PurpleSmallLoader />
          )}
          {!accountPending && <></>}
        </div>

        <div className="form-group">
          <div className="" style={{ display: "flex" }}>
            <QevoltLabel text={"Account name"} />{" "}
          </div>

          <input
            type="text"
            value={account_name}
            name="account_name"
            className="q-input"
            required
            readonly="readonly"
            style={{ border: "1px solid #d9d9d9", color: "#c4c4c4" }}
          />
        </div>
        <div className="form-group">
          <label className="q-label">
            Referal code <span style={{ color: "#a5a3a3" }}> (optional) </span>{" "}
          </label>

          <input
            className="q-input"
            value={referrence}
            type="text"
            name="referrence"
            onChange={(e) => setReferrence(e.target.value)}
          />
        </div>

        <button
                type="submit"
                value="Next"
                className="kyc-submit-button"
                disabled={submitted}
                style={{
                  backgroundColor: checkFilled ? "#8F00FF" : "#d9d9d9",
                  color: "#fff",
                  cursor: checkFilled ? "pointer" : "not-allowed",
                  marginBottom: "30%",
                }}
              >
                Create outlet
              </button>

        {/* {!checkFilled && (
              <button
                type="submit"
                value="Next"
                className="kyc-submit-button"
                style={{
                  backgroundColor: "#F8F8F8",
                  color: "#757575",
                  cursor: "default",

                  cursor: "not-allowed",
                }}
                disabled="true"
              >
                Create outlet
              </button>
            )} */}
      </form>

      {error !== "" && (
        <>
          {" "}
          <p>{error}</p>{" "}
          <button onClick={() => navigate("/account")}>Go back to login</button>{" "}
        </>
      )}
      {bodyPreload && (
        <div className="business-preload">
          <div className="preload-body">
            <PurpleLoader />
            {pending1 && <p> Creating an organisation </p>}
            {pending2 && <p>Creating an Outlet</p>}
            {pending3 && <p>Creating a virtual account</p>}
          </div>
        </div>
      )}

      {diff && <div className="cover" onClick={() => setDiff(false)}></div>}
      {whyBv && <div className="cover" onClick={() => setWhyBv(false)}></div>}
      {need && <div className="cover" onClick={() => setNeed(false)}></div>}
    </div>
  );
};

export default Registered;
